import { IUserProfileTab } from "../../../interfaces/IUserProfile";
import { t } from "i18next";
import { format } from "date-fns";
import { DeliwiButton } from "components/DeliwiButton";
import { IconAddSmall } from "../../Icons/IconAddSmall";
import { IconEdit } from "../../Icons/IconEdit";
import { ReadMoreWeb } from "react-shorten";
import textWithLineBreaks from "functions/TextWithLineBreaks";
import { IconPersonAccounts } from "../../Icons/IconPersonAccounts";
import { IconHatGraduation } from "../../Icons/IconHatGraduation";
import { IconLinkedIn } from "../../Icons/IconLinkedIn";

export const TabEducationTrainings = (props: IUserProfileTab) => {
  const shadowUserProfile = props.userProfileData;
  const canEdit = props.canEdit;
  const displayMode = props.displayMode;

  return (
    <>
      {displayMode !== "preview" && (
        <div className="user-profile-tab--heading--container mb-40 ml-24">
          <IconHatGraduation />
          <h2 className="user-profile-tab--heading text-style--Title2 text-style--semibold">{t("EducationTrainings")}</h2>
        </div>
      )}
      <div className="field-group--container">
        <div className="field-group--header">
          <h3 className="text-style--Subtitle1 text-color--brand10">{t("Education")}</h3>
          {canEdit && (
            <div className="side-by-side-buttons--wrapper">
              {!shadowUserProfile.LinkedInDataRetrievalConsent && <IconLinkedIn className="icon--linkedin" />}
              <DeliwiButton
                className="button-style--outline button-size--medium"
                value="education"
                onClick={() => {
                  props.setFieldGroupToEdit("education");
                  props.showPopup();
                }}
              >
                <IconEdit />
                {t("Edit")}
              </DeliwiButton>
            </div>
          )}
        </div>
        {!!shadowUserProfile?.Education && shadowUserProfile.Education.length > 0 ? (
          <>
            {shadowUserProfile?.Education?.map((education, index) => {
              const sinceDate = new Date(education.Since);
              const sinceDateFormatted = format(sinceDate, "LLL yyyy");
              const untilDate = !!education.Until && new Date(education.Until);
              const untilDateFormatted = !!untilDate ? format(untilDate, "LLL yyyy") : "Present";
              return (
                <div className="field-group--item item-listing" key={index}>
                  <div className="field-group--item--wrapper">
                    <span className="text-style--field-display-label">{education.Organization}</span>
                  </div>

                  <div className="field-group--item--wrapper">
                    <span className="text-style--Body1 fw-600">{education.Title}</span>
                  </div>

                  <div className="field-group--item--wrapper">
                    <span className="text-style--Body2">
                      {sinceDateFormatted} - {untilDateFormatted}
                    </span>
                  </div>

                  {!!education?.Description && (
                    <div className="field-group--item--wrapper item-description">
                      <div className="long-text--container">
                        <div className="text-style--Body2">
                          <ReadMoreWeb truncate={400} showMoreText={t("ReadMore")} showLessText={t("ReadLess")} className="read-more-btn">
                            {textWithLineBreaks(education.Description)}
                          </ReadMoreWeb>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </>
        ) : displayMode !== "preview" && canEdit ? (
          <>
            <div className="empty-content-placeholder">
              <h3 className="text-style--Subtitle1 mb-16">{t("UserProfile.Empty.Education.Title")}</h3>
              <p className="text-style--Body2 text-color--brand10 mb-16 empty-content-placeholder--description">{t("UserProfile.Empty.Education.Content")}</p>
              <DeliwiButton
                onClick={() => {
                  props.setFieldGroupToEdit("education");
                  props.showPopup();
                }}
                className="button-style--candy button-size--large"
              >
                <IconAddSmall className="icon-size--10" />
                {t("UserProfile.Empty.Education.Button")}
              </DeliwiButton>
            </div>
          </>
        ) : null}
      </div>

      <div className="field-group--container">
        <div className="field-group--header">
          <h3 className="text-style--Subtitle1 text-color--brand10">{t("TrainingsAndCourses")}</h3>
          {canEdit && (
            <DeliwiButton
              className="button-style--outline button-size--medium"
              value="trainings"
              onClick={() => {
                props.setFieldGroupToEdit("trainings");
                props.showPopup();
              }}
            >
              <IconEdit />
              {t("Edit")}
            </DeliwiButton>
          )}
        </div>

        {!!shadowUserProfile?.Trainings && shadowUserProfile.Trainings.length > 0 ? (
          <>
            {shadowUserProfile?.Trainings?.map((training, index) => {
              const trainingDate = new Date(training.Since);
              const trainingDateFormatted = format(trainingDate, "LLL yyyy");
              return (
                <div className="field-group--item item-listing" key={index}>
                  <div className="field-group--item--wrapper">
                    <span className="text-style--field-display-label">{training.Name}</span>
                  </div>

                  <div className="field-group--item--wrapper">
                    <span className="text-style--Body1 fw-600">{training.Issuer}</span>
                  </div>

                  <div className="field-group--item--wrapper">
                    <span className="text-style--Body2">{trainingDateFormatted}</span>
                  </div>
                </div>
              );
            })}
          </>
        ) : displayMode !== "preview" && canEdit ? (
          <>
            <div className="empty-content-placeholder">
              <h3 className="text-style--Subtitle1 mb-16">{t("UserProfile.Empty.Training.Title")}</h3>
              <p className="text-style--Body2 text-color--brand10 mb-16 empty-content-placeholder--description">{t("UserProfile.Empty.Training.Content")}</p>
              <DeliwiButton
                onClick={() => {
                  props.setFieldGroupToEdit("trainings");
                  props.showPopup();
                }}
                className="button-style--candy button-size--large"
              >
                <IconAddSmall className="icon-size--10" />
                {t("UserProfile.Empty.Training.Button")}
              </DeliwiButton>
            </div>
          </>
        ) : null}
      </div>

      <div className="field-group--container">
        <div className="field-group--header">
          <h3 className="text-style--Subtitle1 text-color--brand10">{t("Certifications")}</h3>
          {canEdit && (
            <DeliwiButton
              className="button-style--outline button-size--medium"
              value="certifications"
              onClick={() => {
                props.setFieldGroupToEdit("certifications");
                props.showPopup();
              }}
            >
              <IconEdit />
              {t("Edit")}
            </DeliwiButton>
          )}
        </div>

        {!!shadowUserProfile?.Certifications && shadowUserProfile.Certifications.length > 0 ? (
          <>
            {shadowUserProfile?.Certifications?.map((certification, index) => {
              const certificationDate = new Date(certification.Since);
              const certificationDateFormatted = format(certificationDate, "LLL yyyy");
              return (
                <div className="field-group--item item-listing" key={index}>
                  <div className="field-group--item--wrapper">
                    <span className="text-style--field-display-label">{certification.Name}</span>
                  </div>

                  <div className="field-group--item--wrapper">
                    <span className="text-style--Body1 fw-600">{certification.Issuer}</span>
                  </div>

                  <div className="field-group--item--wrapper">
                    <span className="text-style--Body2">{certificationDateFormatted}</span>
                  </div>
                </div>
              );
            })}
          </>
        ) : displayMode !== "preview" && canEdit ? (
          <>
            <div className="empty-content-placeholder">
              <h3 className="text-style--Subtitle1 mb-16">{t("UserProfile.Empty.Certificates.Title")}</h3>
              <p className="text-style--Body2 text-color--brand10 mb-16 empty-content-placeholder--description">{t("UserProfile.Empty.Certificates.Content")}</p>
              <DeliwiButton
                onClick={() => {
                  props.setFieldGroupToEdit("certifications");
                  props.showPopup();
                }}
                className="button-style--candy button-size--large"
              >
                <IconAddSmall className="icon-size--10" />
                {t("UserProfile.Empty.Certificates.Button")}
              </DeliwiButton>
            </div>
          </>
        ) : null}
      </div>

      <div className="field-group--container">
        <div className="field-group--header">
          <h3 className="text-style--Subtitle1 text-color--brand10">{t("Awards")}</h3>
          {canEdit && (
            <DeliwiButton
              className="button-style--outline button-size--medium"
              value="awards"
              onClick={() => {
                props.setFieldGroupToEdit("awards");
                props.showPopup();
              }}
            >
              <IconEdit />
              {t("Edit")}
            </DeliwiButton>
          )}
        </div>

        {!!shadowUserProfile?.Awards && shadowUserProfile.Awards.length > 0 ? (
          <>
            {shadowUserProfile?.Awards?.map((award, index) => {
              const awardDate = new Date(award.Since);
              const awardDateFormatted = format(awardDate, "LLL yyyy");
              return (
                <div className="field-group--item item-listing" key={index}>
                  <div className="field-group--item--wrapper">
                    <span className="text-style--field-display-label">{award.Name}</span>
                  </div>

                  <div className="field-group--item--wrapper">
                    <span className="text-style--Body1 fw-600">{award.Issuer}</span>
                  </div>

                  <div className="field-group--item--wrapper">
                    <span className="text-style--Body2">{awardDateFormatted}</span>
                  </div>
                </div>
              );
            })}
          </>
        ) : displayMode !== "preview" && canEdit ? (
          <>
            <div className="empty-content-placeholder">
              <h3 className="text-style--Subtitle1 mb-16">{t("UserProfile.Empty.Awards.Title")}</h3>
              <p className="text-style--Body2 text-color--brand10 mb-16 empty-content-placeholder--description">{t("UserProfile.Empty.Awards.Content")}</p>
              <DeliwiButton
                onClick={() => {
                  props.setFieldGroupToEdit("awards");
                  props.showPopup();
                }}
                className="button-style--candy button-size--large"
              >
                <IconAddSmall className="icon-size--10" />
                {t("UserProfile.Empty.Awards.Button")}
              </DeliwiButton>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};
