import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconBlur = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Blur">
          <path id="Shape" d="M4.50781 17.1515C4.50781 10.1718 10.166 4.51367 17.1457 4.51367C19.7295 4.51367 22.1323 5.28911 24.134 6.61998H17.1457C11.3293 6.61998 6.61412 11.3351 6.61412 17.1515C6.61412 22.9679 11.3293 27.6831 17.1457 27.6831V25.5768H26.5655C24.2514 28.1622 20.8886 29.7894 17.1457 29.7894C10.166 29.7894 4.50781 24.1312 4.50781 17.1515ZM25.8868 8.02419H17.1457V10.1305H27.6554C27.1431 9.36519 26.5494 8.65896 25.8868 8.02419ZM17.1457 11.5347H28.4698C28.803 12.2052 29.0785 12.9095 29.2896 13.641H17.1457V11.5347ZM29.6088 15.0452H17.1457V17.1515H29.7835C29.7835 16.4339 29.7237 15.7302 29.6088 15.0452ZM17.1457 18.5557H29.7064C29.6265 19.2779 29.4859 19.9818 29.2896 20.662H17.1457V18.5557ZM28.7923 22.0662H17.1457V24.1725H27.6554C28.0976 23.5118 28.4793 22.8071 28.7923 22.0662Z" fill="#020404" />
        </g>
      </svg>
    </span>
  );
};
