import React, { useEffect, useRef, useCallback } from "react";
import { t } from "i18next";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, TooltipItem } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "assets/styles/components/SkillsChart.scss";
import { IAnalysisResults } from "interfaces/IControlPanel";

ChartJS.register(ArcElement, Legend, Tooltip, ChartDataLabels); // Register the plugin

interface SkillsChartProps {
  analysisResults: IAnalysisResults;
  analysisType?: string;
}

export const SurveyChart = ({ analysisResults, analysisType }: SkillsChartProps) => {
  const chartRef = useRef<ChartJS<"doughnut"> | null>(null);

  // Calculate values, considering the possibility of null results
  const values = [analysisResults.ExpertTotal ?? 0, analysisResults.AdvancedTotal ?? 0, analysisResults.NoviceTotal ?? 0, analysisResults.NoInformationTotal ?? 0, analysisResults.NoAnswerTotal ?? 0];

  // Check if all values are 0
  const allValuesAreZero = values.every((value) => value === 0);

  const data = {
    labels: [t("SpecialExpertise"), t("Professional"), t("Basic"), t("NotFamiliar"), t("NoAnswerYet")],
    datasets: [
      {
        rotation: -90,
        data: allValuesAreZero ? [0, 0, 0, 0] : values, // If all values are 0, show a chart with one segment
        backgroundColor: ["#22B6AF", "#9AD6DA", "#C6E9EB", "#ECEDF0", "#DEDEDE"],
        borderColor: ["#22B6AF", "#9AD6DA", "#C6E9EB", "#ECEDF0", "#DEDEDE"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    cutout: "65%",
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (context: TooltipItem<"doughnut">) {
            let value = context.parsed as number;
            let total = context.chart.data.datasets[0].data.filter((item): item is number => typeof item === "number").reduce((a: number, b: number) => a + b, 0);
            if (total === 0) return "0%";
            let percentage = ((value / total) * 100).toFixed(0) + "%";
            return percentage;
          },
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        formatter: (value: number, ctx: { chart: ChartJS }) => {
          // if (value === 0) return "";
          let sum = ctx.chart.data.datasets[0].data.filter((item): item is number => typeof item === "number").reduce((a: number, b: number) => a + b, 0);
          if (sum === 0) return "0%";
          let percentage = ((value / sum) * 100).toFixed(0) + "%";
          return percentage;
        },
        color: (context: { dataIndex: number }) => {
          return context.dataIndex === 0 ? "#FFFFFF" : "#000000";
        },
        font: {
          size: 16,
          weight: 700,
        },
      },
    },
  };

  const generateLegend = useCallback(() => {
    const chartLegend = document.getElementById("custom-legend");
    if (chartLegend && chartRef.current) {
      const total = data.datasets[0].data.reduce((acc, value) => acc + value, 0); // Calculate total here
      const originalColors = [...data.datasets[0].backgroundColor]; // Clone the original colors
      const customHighlightColors = ["#9ad6da", "#36A2EB", "#FFCE56", "#FF6384", "#8F8F8F"]; // Example highlight colors
      // If total is 0 (to handle division by zero), set a flag or directly calculate percentage as 0%
      const isTotalZero = total === 0;

      chartLegend.innerHTML = data.labels
        .map((label, index) => {
          const value = data.datasets[0].data[index];
          const percentage = isTotalZero ? "0" : ((value / total) * 100).toFixed(0); // Use '0' if total is 0, else calculate
          const backgroundColor = originalColors[index];

          return `
        <div class="legend-item" data-index="${index}">
          <div class="legend-box" style="background-color: ${backgroundColor}">
            <span>${percentage}%</span>
          </div>
          <div class="legend-label">
            <div class="stars stars-${index}"></div>
            <div class="title">${label}</div>
          </div>
        </div>
      `;
        })
        .join("");

      const legendItems = chartLegend.querySelectorAll(".legend-item");
      legendItems.forEach((item, index) => {
        // Ensure the element exists and has non-null textContent before proceeding
        const percentageSpan = item.querySelector(".legend-box span");
        if (percentageSpan && percentageSpan.textContent) {
          const percentage = parseFloat(percentageSpan.textContent.replace("%", ""));

          // Check if percentage is greater than 0
          if (percentage > 0) {
            item.addEventListener("mouseenter", () => {
              // Highlight the chart segment and legend box
              (chartRef.current?.data.datasets[0].backgroundColor as string[])[index] = customHighlightColors[index];
              chartRef.current?.update();

              // Highlight legend box
              const legendBox = item.querySelector(".legend-box") as HTMLElement;
              legendBox.style.backgroundColor = customHighlightColors[index];
            });

            item.addEventListener("mouseleave", () => {
              // Revert chart segment and legend box to original color
              (chartRef.current?.data.datasets[0].backgroundColor as string[])[index] = originalColors[index];
              chartRef.current?.update();

              // Revert legend box color
              const legendBox = item.querySelector(".legend-box") as HTMLElement;
              legendBox.style.backgroundColor = originalColors[index];
            });
          } else {
            // Add a class to visually indicate it's disabled and to prevent hover effects
            item.classList.add("disabled-legend-item");

            // Optionally, you can also directly apply a style to visually indicate it's disabled
            const legendBox = item.querySelector(".legend-box") as HTMLElement;
            legendBox.style.opacity = "0.5"; // Dim the legend item for 0% values
          }
        } else {
          // Handle the case where the span or its textContent is null
          // For example, you could log an error, set a default percentage, or apply default styling
        }
      });
    }
  }, [data.datasets, data.labels]);

  useEffect(() => {
    generateLegend();
  }, [analysisResults, generateLegend]);

  return (
    <div className="skills-chart">
      <div style={{ position: "relative" }}>
        <Doughnut ref={chartRef} data={data} options={options} />
      </div>
      <div id="custom-legend" className="custom-legend"></div>
    </div>
  );
};
