import { useState, useEffect } from "react";
import { t } from "i18next";
// import { IUserData } from "interfaces/IUser";
import { IUserProfile } from "interfaces/IUserProfile";
import { iSurveyReportProps } from "interfaces/IControlPanel";
import { SurveyChart } from "components/ControlPanel/SurveyChart";
import { SkillRateTable } from "components/ControlPanel/SkillRateTable";
import { updateSurvey, getSurveyResults, getUserProfileByIdentifier } from "services/apiService";
import { DeliwiButton } from "components/DeliwiButton";

// Icons imports
import IconPie from "assets/icons/icon-pie.svg";
import { IconStopHand } from "components/Icons/IconStopHand";
import { IconArrowUp } from "components/Icons/IconArrowUp";

interface SurveyAnswer {
  ProfileID: string; // Assuming UUID string
  Answer: number; // 0 = Not Familiar, 1 = Basic, 2 = Professional, 3 = Special Expertise
}

interface SurveyData {
  Id: string[]; // Array of IDs, adjust according to actual structure
  Skill: string[]; // Array of skills, adjust according to actual structure
  Title: string[]; // Array of titles, adjust according to actual structure
  StartDate: string[]; // Array of start dates, adjust according to actual structure
  Answers: SurveyAnswer[];
  Status: number; // 0 = Ongoing, 1 = Completed
}

export const SurveyReport = ({ surveyId, surveySkill, surveyStatus, surveyAnswerCount, surveyPersonCount, onReturnToTable }: iSurveyReportProps) => {
  const [surveyData, setSurveyData] = useState<any>([]);
  const [analysisSurveyData, setAnalysisSurveyData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentSurveyStatus, setCurrentSurveyStatus] = useState<number>(surveyStatus);

  const transformSurveyDataToAnalysisResults = async (surveyData: SurveyData) => {
    let expertProfiles: IUserProfile[] = [];
    let advancedProfiles: IUserProfile[] = [];
    let noviceProfiles: IUserProfile[] = [];
    let noInfoProfiles: IUserProfile[] = [];
    let noAnswerProfiles: IUserProfile[] = [];

    const profilePromises = surveyData.Answers.map((answer) => getUserProfileByIdentifier(answer.ProfileID));
    const allProfiles = await Promise.all(profilePromises);

    surveyData.Answers.forEach((answer) => {
      const userProfile = allProfiles.find((profile) => profile.id === answer.ProfileID);

      if (!userProfile) {
        return;
      }

      switch (answer.Answer) {
        case 3:
          expertProfiles.push(userProfile);
          break;
        case 2:
          advancedProfiles.push(userProfile);
          break;
        case 1:
          noviceProfiles.push(userProfile);
          break;
        case 0:
          noInfoProfiles.push(userProfile);
          break;
        default:
          noAnswerProfiles.push(userProfile);
          break;
      }
    });

    return {
      Expert: expertProfiles,
      ExpertTotal: expertProfiles.length,
      Advanced: advancedProfiles,
      AdvancedTotal: advancedProfiles.length,
      Novice: noviceProfiles,
      NoviceTotal: noviceProfiles.length,
      NoInformation: noInfoProfiles,
      NoInformationTotal: noInfoProfiles.length,
      NoAnswer: noAnswerProfiles,
      NoAnswerTotal: noAnswerProfiles.length,
    };
  };

  useEffect(() => {
    const fetchSurveyData = async () => {
      setIsLoading(true);

      try {
        const surveyResults = await getSurveyResults(surveyId);
        setSurveyData(surveyResults);
        const formattedResults = await transformSurveyDataToAnalysisResults(surveyResults);
        // console.log(surveyResults);
        setAnalysisSurveyData(formattedResults);
      } catch (error) {
        console.error("Failed to fetch survey:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSurveyData();
  }, [surveyId]);

  const toggleSurveyStatus = async (status: number) => {
    try {
      const updated = await updateSurvey(surveyId, surveyData.Title, status); // status is now a parameter
      console.log(updated);
      setCurrentSurveyStatus(status);
    } catch (error) {
      console.error("Failed to update survey status", error);
    } finally {
      console.log("Survey status should now be changed to", status);
    }
  };

  if (isLoading) {
    return <div className="deliwi-spinner"></div>;
  }

  return (
    <>
      <DeliwiButton className="button-style--outline button-size--large ml-auto" onClick={onReturnToTable}>
        <IconArrowUp className="icon-size--18" />
        {t("ShowAllSurveys")}
      </DeliwiButton>
      <div className="admin-box__iconset mb-32">
        <img src={IconPie} className="icon-size-62 icon-shadow" alt="" />

        <h4 className="text-style--Title2 text-style--semibold">{surveyData.Title}</h4>
      </div>
      <div className="admin-survey__results mb-40">
        <div className="admin-survey__graph">{surveyData && <SurveyChart analysisResults={analysisSurveyData} analysisType="survey" />}</div>
        <div className="admin-survey__info">
          <div className="admin-box">
            <h4 className="text-style--Subtitle1 mb-32 admin-survey__info--title">{t("SurveyInfo")}</h4>
            <p className="text-style--Body2 text-color--grey44 mb-4">{t("SurveySentTo")}:</p>
            <p className="text-style--Title3 text-style--semibold mb-24">
              {surveyPersonCount} {t("Users")}
            </p>
            <p className="text-style--Body2 text-color--grey44 mb-4">{t("SkillUnderSurvey")}:</p>
            <p className="text-style--Title3 text-style--semibold mb-24">{surveySkill}</p>
            <div className="admin-survey__info--status mb-24">
              <div>
                <p className="text-style--Body2 text-color--grey44 mb-4">{t("SurveyStatus")}:</p>
                <p className="text-style--Title3 text-style--semibold">{currentSurveyStatus === 0 ? t("Ongoing") : t("Completed")}</p>
              </div>
              {currentSurveyStatus === 0 && (
                <DeliwiButton onClick={() => toggleSurveyStatus(1)} className="button-style--outline button-size--medium">
                  <IconStopHand className="icon-size--16" />
                  {t("StopSurvey")}
                </DeliwiButton>
              )}
              {currentSurveyStatus === 1 && (
                <DeliwiButton onClick={() => toggleSurveyStatus(0)} className="button-style--outline button-size--medium">
                  <IconStopHand className="icon-size--16" /> {/* Adjust according to your actual icon component */}
                  {t("StartSurvey")}
                </DeliwiButton>
              )}
            </div>
            <p className="text-style--Body2 text-color--grey44 mb-8">{t("Answers")}:</p>
            <div className="admin-survey__info--count">
              <div
                style={{
                  background: `linear-gradient(90deg, rgba(34, 182, 175, 1) ${(surveyAnswerCount / surveyPersonCount) * 100}%, rgba(255, 255, 255, 1) ${(surveyAnswerCount / surveyPersonCount) * 100}%)`,
                }}
              >
                <span className="text-style--Title3 text-color--neutral-foreground">
                  {surveyAnswerCount} / {surveyPersonCount}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="admin-survey__table mb-40">{surveyData && <SkillRateTable analysisResults={analysisSurveyData} analysisType="survey" />}</div>
    </>
  );
};
