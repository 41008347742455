import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconChevron = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.292894 4.79289C0.683419 4.40237 1.31658 4.40237 1.70711 4.79289L10 13.0858L18.2929 4.79289C18.6834 4.40237 19.3166 4.40237 19.7071 4.79289C20.0976 5.18342 20.0976 5.81658 19.7071 6.2071L10.7071 15.2071C10.3166 15.5977 9.68343 15.5977 9.29291 15.2071L0.292894 6.2071C-0.0976314 5.81658 -0.0976314 5.18342 0.292894 4.79289Z" fill="#08757A" />
      </svg>
    </span>
  );
};
