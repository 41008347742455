import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { EventType, PublicClientApplication, InteractionType } from "@azure/msal-browser";

import { I18nextProvider } from "react-i18next";
import localization from "localization";

import { loginRequest } from "msalConfig";

import { PageLayout } from "components/PageLayout";
import { Search } from "pages/Search";
import { MyProfile } from "pages/MyProfile";
import { UserProfile } from "pages/UserProfile";
import { ControlPanel } from "pages/ControlPanel";
import { Setup } from "pages/Setup";
import { HelpCenter } from "pages/HelpCenter";
import { FirstVisitPopup } from "components/FirstVisitPopup";
import { UserRoleProvider } from "contexts/UserRoleContext";
import { SetupStatusProvider, useSetup } from "contexts/SetupContext";
import { sendUserLoginTime } from "services/apiService";

import "assets/styles/app.scss";

interface AppProps {
  instance: PublicClientApplication;
}

const AppContent: React.FC = () => {
  const { isSetupComplete, isLoading } = useSetup();

  if (isLoading) return <div className="ml-24 mt-24 deliwi-spinner"></div>;

  return (
    <>
      <Routes>
        {isSetupComplete ? (
          <>
            <Route path="/" element={<Search />} />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/user-profile/:id" element={<UserProfile />} />
            <Route path="/control-panel" element={<ControlPanel />} />
            <Route path="/setup" element={<Setup />} />
            <Route path="/help-center" element={<HelpCenter />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        ) : (
          <>
            <Route path="/setup" element={<Setup />} />
            <Route path="*" element={<Navigate to="/setup" replace />} />
          </>
        )}
      </Routes>
      {isSetupComplete && !isLoading && <FirstVisitPopup />}
    </>
  );
};

export const App = ({ instance }: AppProps) => {
  useEffect(() => {
    instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        sendUserLoginTime();
      }
    });
  }, [instance]);

  return (
    <Router>
      <MsalProvider instance={instance}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
          <UserRoleProvider>
            <I18nextProvider i18n={localization}>
              <PageLayout>
                <SetupStatusProvider>
                  <AppContent />
                </SetupStatusProvider>
              </PageLayout>
            </I18nextProvider>
          </UserRoleProvider>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </Router>
  );
};
