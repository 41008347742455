import { t } from "i18next";

import IconTools from "assets/icons/icon-tools.svg";
import "assets/styles/pages/HelpCenter.scss";
import content from "local-json/help-center-qa-content-fi.json";
import categories from "local-json/help-center-qa-categories.json";
import { useState } from "react";
import { IconAddCircle } from "../../Icons/IconAddCircle";
import { IconDismiss } from "../../Icons/IconDismiss";
import { IconDismissCircle } from "../../Icons/IconDismissCircle";
import { IQAContent, IQAContentCategory } from "../../../pages/HelpCenter";
import { DeliwiButton } from "../../DeliwiButton";
import { IconArrowForward } from "../../Icons/IconArrowForward";
import { searchUserProfiles } from "../../../services/apiService";
import { SearchFieldHelpCenter } from "../../SearchFieldHelpCenter";
import { IconChevron } from "../../Icons/IconChevron";

export interface ITabQuestionsAnswersProps {
  groupedContent: IQAContentCategory[];
  qaItemOpened: number | undefined;
  qaItemFullDisplayOpened: number | undefined;
  searchString: string | undefined;

  setQaItemOpened(data: number | undefined): void;

  setQaItemFullDisplayOpened(data: number | undefined): void;

  setSearchString(data: string | undefined): void;
}

export const TabQuestionsAnswers = (props: ITabQuestionsAnswersProps) => {
  const [openedCategory, setOpenedCategory] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const groupedContent = props.groupedContent;

  const search = async (searchString: string) => {
    props.setSearchString(searchString);
    if (searchString === "") {
      setOpenedCategory(undefined);
    }
  };

  return (
    <>
      <SearchFieldHelpCenter search={search} setSearchString={props.setSearchString} />
      {!!groupedContent && Object.entries(groupedContent).length > 0 ? (
        Object.entries(groupedContent).map(([key, item], i) => {
          const subItemsCount = item.items.length;
          return (
            <div key={key} className="help-center-box">
              <div className="help-center-box__iconset mb-32">
                <div className="help-center-box__iconset--wrapper icon-shadow">
                  <img src={item.icon} className="icon-size-24" alt="" />
                </div>

                <div className="help-center-qa--category qa-category">
                  <h3 className="text-style--Subtitle1 text-style--semibold mb-16">{item.name}</h3>
                  <div className="help-center-qa--category--description-row">
                    <p className="text-style--Body1">{item.description}</p>
                    <div
                      className="qa-category--items-trigger text-style--Body1"
                      onClick={() => {
                        const valueToSet = openedCategory === item.name ? undefined : item.name;
                        setOpenedCategory(valueToSet);
                      }}
                    >
                      {openedCategory === item.name || (!!props.searchString && subItemsCount > 0) ? t("HelpCenter.QA.HideItems", { subItemsCount }) : t("HelpCenter.QA.ShowItems", { subItemsCount })}
                      {openedCategory === item.name || (!!props.searchString && subItemsCount > 0) ? <IconDismissCircle /> : <IconAddCircle />}
                    </div>
                  </div>
                </div>
              </div>
              {openedCategory === item.name || (!!props.searchString && subItemsCount > 0) ? (
                <div className="qa-category--items">
                  {item.items.map((qaItem, index) => (
                    <div key={index} className={`qa-item--container ${props.qaItemOpened === qaItem.id ? "item-opened" : ""} ${props.qaItemFullDisplayOpened === qaItem.id ? "item-opened-in-full-display" : ""}`}>
                      <div
                        className="qa-item--header"
                        onClick={() => {
                          const newValue = props.qaItemOpened === qaItem.id ? undefined : qaItem.id;
                          props.setQaItemOpened(newValue);
                        }}
                      >
                        <div className="qa-item--title text-style--Subtitle1">{qaItem.title}</div>

                        <IconChevron />
                      </div>
                      {props.qaItemOpened === qaItem.id && (
                        <div className="qa-item--answer-content">
                          <div className="qa-item--short-description text-style--Body1">{qaItem.short_description}</div>
                          {!!qaItem.long_description && (
                            <DeliwiButton className="button-style--primary button-size--medium ml-auto read-more" onClick={() => props.setQaItemFullDisplayOpened(qaItem.id)}>
                              {t("ReadMore")}
                              <IconArrowForward />
                            </DeliwiButton>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          );
        })
      ) : (
        <div className="text-style--Body1">{t("HelpCenter.QA.NoResults")}</div>
      )}
    </>
  );
};
