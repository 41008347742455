import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconInfo = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.99958 0C15.5233 0 20.0012 4.4778 20.0012 10.0016C20.0012 15.5253 15.5233 20.0031 9.99958 20.0031C4.47588 20.0031 -0.00195312 15.5253 -0.00195312 10.0016C-0.00195312 4.4778 4.47588 0 9.99958 0ZM9.99958 1.5C5.30428 1.5 1.49808 5.3063 1.49808 10.0016C1.49808 14.6968 5.30428 18.5031 9.99958 18.5031C14.6949 18.5031 18.5012 14.6968 18.5012 10.0016C18.5012 5.3063 14.6949 1.5 9.99958 1.5ZM9.99598 8.5006C10.3757 8.5003 10.6897 8.7823 10.7396 9.1483L10.7465 9.2501L10.7501 14.7517C10.7503 15.1659 10.4148 15.5019 10.0006 15.5022C9.62088 15.5024 9.30688 15.2205 9.25698 14.8544L9.25008 14.7527L9.24648 9.2511C9.24618 8.8369 9.58178 8.5009 9.99598 8.5006ZM10.0001 5.0028C10.5516 5.0028 10.9987 5.4499 10.9987 6.0015C10.9987 6.553 10.5516 7.0001 10.0001 7.0001C9.44848 7.0001 9.00138 6.553 9.00138 6.0015C9.00138 5.4499 9.44848 5.0028 10.0001 5.0028Z" fill="white" />
      </svg>
    </span>
  );
};
