import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconOnline = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="Shape" d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM19.89 11.4899L13.59 17.79C13.0433 18.3367 12.1568 18.3367 11.6101 17.79L8.81006 14.99C8.26332 14.4433 8.26331 13.5568 8.81004 13.0101C9.35678 12.4633 10.2432 12.4633 10.7899 13.0101L12.6 14.8201L17.91 9.51006C18.4568 8.96332 19.3432 8.96331 19.8899 9.51005C20.4367 10.0568 20.4367 10.9432 19.89 11.4899Z" fill="#13A10E" />
      </svg>
    </span>
  );
};
