import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconHeart = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Heart">
          <path id="Shape" d="M18.651 8.13459L17.4986 9.28956L16.3426 8.13354C13.3951 5.18602 8.61617 5.18602 5.66865 8.13354C2.72113 11.0811 2.72113 15.8599 5.66865 18.8075L16.7554 29.8942C17.1667 30.3055 17.8335 30.3055 18.2448 29.8942L29.34 18.8054C32.281 15.8481 32.286 11.0826 29.338 8.13459C26.3851 5.1817 21.6039 5.1817 18.651 8.13459ZM27.8465 17.3202L17.5001 27.6601L7.15803 17.3181C5.03308 15.1931 5.03308 11.7479 7.15803 9.62293C9.28299 7.49797 12.7282 7.49797 14.8532 9.62293L16.7593 11.5291C17.1776 11.9473 17.8582 11.9392 18.2663 11.511L20.1404 9.62397C22.2708 7.49365 25.7183 7.49365 27.8486 9.62397C29.9741 11.7494 29.9704 15.1845 27.8465 17.3202Z" fill="#020404" />
        </g>
      </svg>
    </span>
  );
};
