import { IIconProps, Text } from "@fluentui/react";
import { DeliwiButton } from "components/DeliwiButton";
// The icons are in use but the way IIconProps is setting them is causing this
import { ChromeCloseIcon, DeleteIcon } from "@fluentui/react-icons-mdl2";
import { ISkill, IUserProfile, IUserProfileEditForm } from "../../../interfaces/IUserProfile";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "assets/styles/components/EditSkills.scss";
import { UserLanguageSkillsEditAddLanguageSkill } from "./UserLanguageSkillsEditAddLanguageSkill";
import { UserLanguageSkillsEditRateLanguageSkills } from "./UserLanguageSkillsEditRateLanguageSkills";
import { IconCheckmark } from "components/Icons/IconCheckmark";
import { IconClose } from "../../Icons/IconClose";

export interface IUserLanguageSkillsFormProps {
  Name: string;
  Level: number;
}

const IconRemove: IIconProps = { iconName: "DeleteIcon" };

export const UserLanguageSkillsEdit = (props: IUserProfileEditForm) => {
  const shadowUserProfile = props.userProfileData;
  const [languageSkills, setLanguageSkills] = useState<ISkill[]>([] as ISkill[]);

  const saveNewValues = async () => {
    if (!languageSkills) {
      props.hidePopup();
      return;
    }

    const sortedValues = languageSkills.sort((a, b) => b.Level - a.Level);
    const updatedProfile = {
      ...shadowUserProfile,
      LanguageSkills: sortedValues,
    } as IUserProfile;

    try {
      await props.saveProfile(updatedProfile);
      props.userProfileSetter(updatedProfile);
      props.hidePopup();
    } catch (error) {
      console.error("Failed to save changes:", error);
    }
  };

  useEffect(() => {
    setLanguageSkills(shadowUserProfile.LanguageSkills);
  }, []);

  return (
    <>
      <div className="edit-dialog--header form--heading--wrapper">
        <h2 className="form--heading">{t("LanguageSkills")}</h2>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-edit-popup" onClick={() => props.hidePopup()}></ChromeCloseIcon>
        </div>
      </div>
      <div className="form--container x-large mb-40">
        <DndProvider backend={HTML5Backend}>
          <UserLanguageSkillsEditAddLanguageSkill languageSkills={languageSkills} setLanguageSkills={setLanguageSkills} />
          <div className="edit-skills--rate-skills">
            <UserLanguageSkillsEditRateLanguageSkills languageSkills={languageSkills} setLanguageSkills={setLanguageSkills} />
          </div>
        </DndProvider>
      </div>
      <div className="edit-dialog--buttons">
        <DeliwiButton className="button-style--outline button-size--large" onClick={() => props.hidePopup()}>
          <IconClose className="icon-size--20" />
          {t("Cancel")}
        </DeliwiButton>
        <DeliwiButton type="submit" className="button-style--primary button-size--large" onClick={saveNewValues} disabled={props.saving}>
          <IconCheckmark />
          {t("SaveChanges")}
        </DeliwiButton>
      </div>
    </>
  );
};
