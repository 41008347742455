import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconRibbonStar = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Ribbon Star">
          <path id="Shape" d="M17.4672 8.22397L18.7486 10.8255L21.6187 11.2402C21.9126 11.2827 22.0301 11.6439 21.8174 11.8512L19.7415 13.8739L20.2316 16.732C20.2817 17.0247 19.9744 17.2477 19.7117 17.1094L17.1459 15.7579L14.5801 17.1094C14.3173 17.2478 14.0099 17.0245 14.0603 16.7317L14.5528 13.8739L12.4746 11.8513C12.2617 11.6441 12.3791 11.2827 12.6731 11.2402L15.5433 10.8255L16.8247 8.22397C16.9559 7.95747 17.3359 7.95747 17.4672 8.22397ZM26.9753 12.9388C26.9753 7.51016 22.5745 3.10938 17.1458 3.10938C11.7172 3.10938 7.31641 7.51016 7.31641 12.9388C7.31641 15.6166 8.38719 18.0443 10.1239 19.8171L10.1259 30.1406L10.1357 30.2874C10.2382 31.0493 11.1288 31.4641 11.7855 31.0017L17.1445 27.2276L22.5064 31.0018L22.632 31.0783C23.3139 31.433 24.1657 30.9437 24.1657 30.1406L24.1677 19.8171C25.9045 18.0443 26.9753 15.6166 26.9753 12.9388ZM22.0597 21.4537L22.0594 28.1114L17.7506 25.0784L17.609 24.9944C17.2675 24.8264 16.8561 24.8545 16.538 25.0785L12.2323 28.1108L12.2319 21.4537C13.6775 22.2898 15.3558 22.7682 17.1458 22.7682C18.9359 22.7682 20.6141 22.2898 22.0597 21.4537ZM9.42271 12.9388C9.42271 8.67345 12.8805 5.21568 17.1458 5.21568C21.4112 5.21568 24.869 8.67345 24.869 12.9388C24.869 17.2042 21.4112 20.6619 17.1458 20.6619C12.8805 20.6619 9.42271 17.2042 9.42271 12.9388Z" fill="#020404" />
        </g>
      </svg>
    </span>
  );
};
