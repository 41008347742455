import { ISkill } from "../../../interfaces/IUserProfile";
import { useEffect, useState } from "react";
import { IconDismiss } from "../../Icons/IconDismiss";
import { IconReOrderDots } from "../../Icons/IconReOrderDots";
import { useDrag, useDrop } from "react-dnd";
import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";
import { IconArrowDown } from "../../Icons/IconArrowDown";
import { HelpDialog } from "../../HelpDialog";

interface IUserSkillsEditRateSkillsProps {
  skills: ISkill[];

  //setSkills(data: ISkill[]): void;
  setSkills: Dispatch<SetStateAction<ISkill[]>>;
}

interface ISkillsDraggableSectionProps {
  level: number;
  skills: ISkill[];

  //setSkills(data: ISkill[]): void;
  setSkills: Dispatch<SetStateAction<ISkill[]>>;

  nonRatedSkills: ISkill[];
  basicSkills: ISkill[];
  professionalSkills: ISkill[];
  specialExpertiseSkills: ISkill[];
}

interface ISkillSingleProps {
  skill: ISkill;
  skills: ISkill[];

  //setSkills(data: ISkill[]): void;
  setSkills: Dispatch<SetStateAction<ISkill[]>>;
}

interface IDroppableItem {
  Name: string;
}

export const UserSkillsEditRateSkills = ({ skills, setSkills }: IUserSkillsEditRateSkillsProps) => {
  const [nonRatedSkills, setNonRatedSkills] = useState([] as ISkill[]);
  const [basicSkills, setBasicSkills] = useState([] as ISkill[]);
  const [professionalSkills, setProfessionalSkills] = useState([] as ISkill[]);
  const [specialExpertiseSkills, setSpecialExpertiseSkills] = useState([] as ISkill[]);
  const [countOfNonRatedSkills, setCountOfNonRatedSkills] = useState<number>();
  const [countOfAllSkills, setCountOfAllSkills] = useState<number>();

  useEffect(() => {
    const filteredNonRatedSkills = !!skills && skills.filter((skill) => skill.Level === 0);
    const filteredBasicSkills = !!skills && skills.filter((skill) => skill.Level === 1);
    const filteredProfessionalSkills = !!skills && skills.filter((skill) => skill.Level === 2);
    const filteredSpecialExpertiseSkills = !!skills && skills.filter((skill) => skill.Level === 3);

    setNonRatedSkills(filteredNonRatedSkills);
    setBasicSkills(filteredBasicSkills);
    setProfessionalSkills(filteredProfessionalSkills);
    setSpecialExpertiseSkills(filteredSpecialExpertiseSkills);

    const countOfNonRatedSkills = !!filteredNonRatedSkills ? filteredNonRatedSkills.length : 0;

    setCountOfNonRatedSkills(countOfNonRatedSkills);
    setCountOfAllSkills(filteredNonRatedSkills.length + filteredBasicSkills.length + filteredProfessionalSkills.length + filteredSpecialExpertiseSkills.length);
  }, [skills]);

  const skillLevels = [0, 3, 2, 1];

  return (
    <>
      <HelpDialog className="mb-24" dialogContent={t("Help.EditForms.EditSkills.RateSkills.Content")} title={t("Help.EditForms.EditSkills.RateSkills.Title")}>
        <h3 className={`text-style--Body1 fw-600 ${countOfAllSkills && countOfAllSkills > 0 ? "text-color--brand80" : "text-color--grey44"} count-of-non-rated-skills-${countOfNonRatedSkills || 0}`}>{t("EditSkillsStep2Label")}</h3>
      </HelpDialog>
      <div className="rate-skills-wrap">
        {skillLevels.map((skillLevel, index) => (
          <div key={skillLevel} className={`rate-skills--skills-group level-${skillLevel} count-of-non-rated-skills-${countOfNonRatedSkills || 0}`}>
            {skillLevel !== 0 && <IconArrowDown className="skill-group--arrow-down" />}
            <SkillsDraggableSection key={skillLevel} level={skillLevel} skills={skills} setSkills={setSkills} nonRatedSkills={nonRatedSkills} basicSkills={basicSkills} professionalSkills={professionalSkills} specialExpertiseSkills={specialExpertiseSkills} />
          </div>
        ))}
      </div>
    </>
  );
};

const SkillsDraggableSection = ({ level, skills, setSkills, nonRatedSkills, basicSkills, professionalSkills, specialExpertiseSkills }: ISkillsDraggableSectionProps) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "skill",
    drop: (item: IDroppableItem) => addItemToSection(item.Name),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  let sectionHeading;
  let skillsToMap;
  switch (level) {
    case 1:
      sectionHeading = "Basic skills";
      skillsToMap = basicSkills;
      break;
    case 2:
      sectionHeading = "Professional skills";
      skillsToMap = professionalSkills;
      break;
    case 3:
      sectionHeading = "Special expertise skills";
      skillsToMap = specialExpertiseSkills;
      break;
    default:
      sectionHeading = "Non rated skills";
      skillsToMap = nonRatedSkills;
  }

  const addItemToSection = (Name: string) => {
    setSkills((prevState) => {
      const mSkills = prevState.map((s) => {
        if (s.Name === Name) {
          return { ...s, Level: level };
        }
        return s;
      });
      return mSkills;
    });
  };
  const nonRatedSkillsCount = !!nonRatedSkills ? nonRatedSkills.length : 0;
  return (
    <div key={level} ref={drop} className={`rate-skills--skills-group--container ${isOver ? "is-over" : ""} non-rated-skills-count-${nonRatedSkillsCount}`}>
      <h3 className={`text-style--Body1 text-style--semibold ${nonRatedSkillsCount && nonRatedSkillsCount > 0 ? "text-color--brand10" : "text-color--grey44"}`}>
        {sectionHeading} {level === 0 && `(${nonRatedSkillsCount})`}
      </h3>
      <div className="skills-container">{skillsToMap.length > 0 ? skillsToMap.map((skill) => <Skill key={skill.Name} skill={skill} skills={skills} setSkills={setSkills} />) : <>{level !== 0 && <span className={`drag-skills-here non-rated-skills-count-${nonRatedSkillsCount}`}>{t("DragSkillsHere")}</span>}</>}</div>
      {level === 0 && <p className="text-style--Body1 text-color--grey44 fw-600 pt-16">{t("EditSkillsLevel0Description")}</p>}
    </div>
  );
};

const Skill = ({ skill, skills, setSkills }: ISkillSingleProps) => {
  const [, drag] = useDrag(() => ({
    type: "skill",
    item: { Name: skill.Name },
  }));

  const handleRemove = (Name: string) => {
    const filteredSkills = skills.filter((s) => s.Name !== Name);
    setSkills(filteredSkills);
  };

  return (
    <div ref={drag} className="rate-skills--skill">
      <IconReOrderDots className="icon-reorder" />
      <span>{skill.Name}</span>
      <button className="remove-skill" onClick={() => handleRemove(skill.Name)}>
        <IconDismiss className="icon-size--10" />
      </button>
    </div>
  );
};
