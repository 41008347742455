import { t } from "i18next";

import "assets/styles/pages/ControlPanel.scss";
import { HelpCenterTabs } from "../components/HelpCenter/HelpCenterTabs";
import { UserProfilePreview } from "../components/UserProfilePreview";
import { useState } from "react";
import qa_content from "local-json/help-center-qa-content-fi.json";
import qa_categories from "local-json/help-center-qa-categories.json";
import IconTools from "../assets/icons/icon-tools.svg";
import { QaItemFullDisplay } from "../components/HelpCenter/QaItemFullDisplay";
import { useRef } from "react";
import { useUserRole } from "../contexts/UserRoleContext";

export interface IQACategorySourceData {
  id: number;
  name: string;
  icon: string;
  description: string;
}

export interface IQAContentCategory {
  id: number;
  name: string;
  icon: string;
  description: string;
  items: IQAContent[];
}

export interface IQAContent {
  id: number;
  title: string;
  short_description: string;
  long_description?: string;
  category: string;
  roles: string[];
}

export const HelpCenter = () => {
  const [qaItemOpened, setQaItemOpened] = useState<number | undefined>(undefined);
  const [qaItemFullDisplayOpened, setQaItemFullDisplayOpened] = useState<number | undefined>(undefined);
  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const ref = useRef<null | HTMLDivElement>(null);
  const { userRole } = useUserRole();

  const isRoleAllowed = (roles: string[]) => roles.some((role: string) => role === "any" || userRole.includes(role));

  let qaContent = qa_content.filter((item) => isRoleAllowed(item.roles));

  if (!!searchString) {
    qaContent = qaContent.filter((qaItem) => qaItem.title.includes(searchString) || qaItem.short_description.includes(searchString));
  }

  const groupedContent = !!qaContent
    ? qaContent.reduce((group: { [key: string]: IQAContentCategory }, item) => {
        if (!group[item.category]) {
          const categoryData = qa_categories.find((element) => {
            return element.name === item.category;
          });
          group[item.category] = {
            id: categoryData?.id || 0,
            name: categoryData?.name || item.category,
            icon: categoryData?.icon || IconTools,
            description: categoryData?.description || "",
            items: [],
          };
        }
        group[item.category].items.push(item);
        return group;
      }, {})
    : [];

  const triggerQaItemFullDisplay = (data: number | undefined) => {
    setQaItemFullDisplayOpened(data);
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const triggerQaItem = (data: number | undefined) => {
    setQaItemOpened(data);
  };

  let qaItemToFullDisplay = undefined;
  if (qaItemFullDisplayOpened !== undefined) {
    qaItemToFullDisplay = qa_content.find((element) => {
      return element.id === qaItemFullDisplayOpened;
    });
  }

  const closeDisplay = () => {
    setQaItemFullDisplayOpened(undefined);
  };

  return (
    <div ref={ref} className={`help-center help-center-page ${qaItemFullDisplayOpened !== undefined ? "qa-item-full-display-opened" : ""}`}>
      <div className="help-center-page__container">
        <h1 className="text-style--Large-title text-style--semibold">{t("HelpCenter.Heading1")}</h1>
        <HelpCenterTabs groupedQaContent={groupedContent} qaItemOpened={qaItemOpened} qaItemFullDisplayOpened={qaItemFullDisplayOpened} setQaItemOpened={triggerQaItem} setQaItemFullDisplayOpened={triggerQaItemFullDisplay} searchString={searchString} setSearchString={setSearchString} />
      </div>
      {qaItemFullDisplayOpened !== undefined && qaItemToFullDisplay !== undefined && (
        <div className="help-center--qa-item-full-display qa-item-full-display">
          <QaItemFullDisplay content={qaItemToFullDisplay} closeDisplay={closeDisplay} />
        </div>
      )}
    </div>
  );
};
