import { useEffect, useState } from "react";
import { t } from "i18next";
import { DeliwiButton } from "components/DeliwiButton";
import { ISkill } from "../../../interfaces/IUserProfile";
import { IconAddCircle } from "../../Icons/IconAddCircle";
import * as React from "react";
import { TagPicker, ITag, IInputProps, IBasePickerSuggestionsProps } from "@fluentui/react/lib/Pickers";
import ISO6391 from "iso-639-1";
import { HelpDialog } from "../../HelpDialog";

interface IUserSkillsEditAddSkillProps {
  languageSkills: ISkill[];

  setLanguageSkills(data: ISkill[]): void;
}

const inputProps: IInputProps = {
  // onBlur: (ev: React.FocusEvent<HTMLInputElement>) => console.log("onBlur called"),
  // onFocus: (ev: React.FocusEvent<HTMLInputElement>) => console.log("onFocus called"),
  "aria-label": "Language picker",
};

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: t("EditLanguageSkills.SuggestedLanguages"),
  noResultsFoundText: t("EditLanguageSkills.NoLanguagesFound"),
};

const allLanguageNames = ISO6391.getAllNames();

const filteredLanguageNames: ITag[] = allLanguageNames.map((language) => ({
  name: language,
  key: language,
}));

export const UserLanguageSkillsEditAddLanguageSkill = (props: IUserSkillsEditAddSkillProps) => {
  const [isValidLanguage, setIsValidLanguage] = useState(false);

  const [languageSkill, setLanguageSkill] = useState({
    Name: "",
    Level: 0, // Can also be 1, 2 or 3
  });

  const listContainsTagList = (language: ITag, languageList?: ITag[]) => {
    if (!languageList || !languageList.length || languageList.length === 0) {
      return false;
    }
    return languageList.some((compareLanguage) => compareLanguage.key === language.key);
  };

  const filterSuggestedLanguages = (filterText: string, languageList?: ITag[] | undefined): ITag[] => {
    const suggestedLanguages = filterText ? filteredLanguageNames.filter((language) => language.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(language, languageList)) : [];

    // Update the validity based on whether the input matches any suggested languages
    setIsValidLanguage(suggestedLanguages.length > 0);

    return suggestedLanguages;
  };

  const getTextFromItem = (languageSkill: ITag) => languageSkill.name;

  const addSuggestedLanguageSkill = (selectedItem?: ITag | undefined): ITag | PromiseLike<ITag> | null => {
    if (selectedItem !== undefined) {
      // Ensure languageSkills is always treated as an array
      const languageSkillsArray = props.languageSkills || [];

      // Check if the language is already in the languageSkills array
      const isLanguageAlreadyAdded = languageSkillsArray.some((languageSkill) => languageSkill.Name.trim().toLowerCase() === selectedItem.name.trim().toLowerCase());

      if (!isLanguageAlreadyAdded) {
        // If not already added, update the language skill state to add the new language
        setLanguageSkill({ ...languageSkill, Name: selectedItem.name.trim(), Level: 0 });
        setIsValidLanguage(true);
      } else {
        // Optionally, inform the user that the language is already added
        // console.log("This language is already added."); // Placeholder for actual user feedback implementation
      }
    }
    return null;
  };

  useEffect(() => {
    if (!!languageSkill?.Name) {
      const previousLanguageSkills = props.languageSkills || [];
      const updatedLanguageSkills = [...previousLanguageSkills, languageSkill];
      props.setLanguageSkills(updatedLanguageSkills);
      setLanguageSkill({
        Name: "",
        Level: 0,
      });
    }
  }, [languageSkill]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const inputLanguageName = languageSkill.Name.trim();
    // Debugging: Log out the input and existing language names for comparison
    // console.log("Attempting to add language:", inputLanguageName);
    // console.log(
    //   "Existing languages:",
    //   props.languageSkills.map((skill) => skill.Name.toLowerCase()),
    // );

    const isLanguageAlreadyAdded = props.languageSkills.some((languageSkill) => languageSkill.Name.trim().toLowerCase() === inputLanguageName.toLowerCase());

    if (inputLanguageName && !isLanguageAlreadyAdded) {
      const updatedLanguageSkills = [...props.languageSkills, { ...languageSkill, Name: inputLanguageName }];
      props.setLanguageSkills(updatedLanguageSkills);
      setLanguageSkill({ Name: "", Level: 0 });
    } else {
      // Provide more specific feedback based on the condition that failed
      if (inputLanguageName) {
        // console.log("This language is already added.");
      } else {
        // console.log("Invalid input.");
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <HelpDialog className="mb-24" dialogContent={t("Help.EditForms.EditLanguageSkills.AddNewLanguage.Content")} title={t("Help.EditForms.EditLanguageSkills.AddNewLanguage.Title")}>
          <h3 className="text-style--Body1 fw-600 text-color--brand80">{t("EditLanguageSkills.Step1Label")}</h3>
        </HelpDialog>
        <div className="add-skill-input--container mb-32">
          <label>{t("EditLanguageSkills.LanguagePickerLabel")}</label>
          <div className="add-interest-input--wrapper">
            <TagPicker
              removeButtonAriaLabel="Remove"
              selectionAriaLabel="Selected language skills"
              onResolveSuggestions={filterSuggestedLanguages}
              getTextFromItem={getTextFromItem}
              pickerSuggestionsProps={pickerSuggestionsProps}
              onItemSelected={addSuggestedLanguageSkill}
              inputProps={{
                ...inputProps,
                id: "skill-picker",
              }}
            />
            <DeliwiButton className="button-style--primary button-size--medium" type="submit" disabled={!isValidLanguage}>
              <IconAddCircle />
              {t("Add")}
            </DeliwiButton>
          </div>
        </div>
      </form>
    </>
  );
};
