interface LabelWithTextFieldProps {
  label: string;
  value: string;
  canEdit: boolean;
}

export const LabelWithTextField = ({ label, value, canEdit }: LabelWithTextFieldProps) => {
  const labelClass = value ? "field-label" : "field-label field-label-empty";
  const valueClass = "field-value";
  const placeholderClass = "field-placeholder";

  return (
    <>
      <label className={labelClass}>{label}</label>
      {value ? <span className={valueClass}>{value}</span> : canEdit ? <div className={placeholderClass}></div> : null}
    </>
  );
};
