import { ISkill } from "../../../interfaces/IUserProfile";
import { useEffect, useState } from "react";
import { IconDismiss } from "../../Icons/IconDismiss";
import { IconReOrderDots } from "../../Icons/IconReOrderDots";
import { useDrag, useDrop } from "react-dnd";
import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";
import { IconArrowDown } from "../../Icons/IconArrowDown";
import { HelpDialog } from "../../HelpDialog";

interface IUserLanguageSkillsEditRateLanguageSkillsProps {
  languageSkills: ISkill[];

  setLanguageSkills: Dispatch<SetStateAction<ISkill[]>>;
}

interface ILanguageSkillsDraggableSectionProps {
  level: number;
  languageSkills: ISkill[];

  //setSkills(data: ISkill[]): void;
  setLanguageSkills: Dispatch<SetStateAction<ISkill[]>>;

  nonRatedLanguageSkills: ISkill[];
  basicLanguageSkills: ISkill[];
  professionalLanguageSkills: ISkill[];
  nativeLanguageSkills: ISkill[];
}

interface ILanguageSkillSingleProps {
  languageSkill: ISkill;
  languageSkills: ISkill[];

  setLanguageSkills: Dispatch<SetStateAction<ISkill[]>>;
}

interface IDroppableItem {
  Name: string;
}

export const UserLanguageSkillsEditRateLanguageSkills = ({ languageSkills, setLanguageSkills }: IUserLanguageSkillsEditRateLanguageSkillsProps) => {
  const [nonRatedLanguageSkills, setNonRatedLanguageSkills] = useState([] as ISkill[]);
  const [basicLanguageSkills, setBasicLanguageSkills] = useState([] as ISkill[]);
  const [professionalLanguageSkills, setProfessionalLanguageSkills] = useState([] as ISkill[]);
  const [nativeLanguageSkills, setNativeLanguageSkills] = useState([] as ISkill[]);
  const [countOfNonRatedLanguageSkills, setCountOfNonRatedLanguageSkills] = useState<number>();
  const [countOfAllLanguageSkills, setCountOfAllLanguageSkills] = useState<number>();

  useEffect(() => {
    const filteredNonRatedLanguageSkills = !!languageSkills && languageSkills.filter((skill) => skill.Level === 0);
    const filteredBasicLanguageSkills = !!languageSkills && languageSkills.filter((skill) => skill.Level === 1);
    const filteredProfessionalLanguageSkills = !!languageSkills && languageSkills.filter((skill) => skill.Level === 2);
    const filteredNativeLanguageSkills = !!languageSkills && languageSkills.filter((skill) => skill.Level === 3);

    setNonRatedLanguageSkills(filteredNonRatedLanguageSkills);
    setBasicLanguageSkills(filteredBasicLanguageSkills);
    setProfessionalLanguageSkills(filteredProfessionalLanguageSkills);
    setNativeLanguageSkills(filteredNativeLanguageSkills);

    const countOfNonRatedLanguageSkills = !!filteredNonRatedLanguageSkills ? filteredNonRatedLanguageSkills.length : 0;

    setCountOfNonRatedLanguageSkills(countOfNonRatedLanguageSkills);
    setCountOfAllLanguageSkills(filteredNonRatedLanguageSkills.length + filteredBasicLanguageSkills.length + filteredProfessionalLanguageSkills.length + filteredNativeLanguageSkills.length);
  }, [languageSkills]);

  const skillLevels = [0, 3, 2, 1];

  return (
    <>
      <HelpDialog className="mb-24" dialogContent={t("Help.EditForms.EditLanguageSkills.RateLanguages.Content")} title={t("Help.EditForms.EditLanguageSkills.RateLanguages.Title")}>
        <h3 className={`text-style--Body1 fw-600 ${countOfAllLanguageSkills && countOfAllLanguageSkills > 0 ? "text-color--brand80" : "text-color--grey44"} count-of-non-rated-skills-${countOfNonRatedLanguageSkills || 0}`}>{t("EditLanguageSkills.Step2Label")}</h3>
      </HelpDialog>
      <div className="rate-skills-wrap">
        {skillLevels.map((skillLevel, index) => (
          <div key={skillLevel} className={`rate-skills--skills-group level-${skillLevel} language count-of-non-rated-skills-${countOfNonRatedLanguageSkills || 0}`}>
            {skillLevel !== 0 && <IconArrowDown className="skill-group--arrow-down" />}
            <SkillsDraggableSection key={skillLevel} level={skillLevel} languageSkills={languageSkills} setLanguageSkills={setLanguageSkills} nonRatedLanguageSkills={nonRatedLanguageSkills} basicLanguageSkills={basicLanguageSkills} professionalLanguageSkills={professionalLanguageSkills} nativeLanguageSkills={nativeLanguageSkills} />
          </div>
        ))}
      </div>
    </>
  );
};

const SkillsDraggableSection = ({ level, languageSkills, setLanguageSkills, nonRatedLanguageSkills, basicLanguageSkills, professionalLanguageSkills, nativeLanguageSkills }: ILanguageSkillsDraggableSectionProps) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "skill",
    drop: (item: IDroppableItem) => addItemToSection(item.Name),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  let sectionHeading;
  let languageSkillsToMap;
  switch (level) {
    case 1:
      sectionHeading = "Basics";
      languageSkillsToMap = basicLanguageSkills;
      break;
    case 2:
      sectionHeading = "Professional";
      languageSkillsToMap = professionalLanguageSkills;
      break;
    case 3:
      sectionHeading = "Native or bilingual";
      languageSkillsToMap = nativeLanguageSkills;
      break;
    default:
      sectionHeading = "Non rated languages";
      languageSkillsToMap = nonRatedLanguageSkills;
  }

  const addItemToSection = (Name: string) => {
    setLanguageSkills((prevState) => {
      const mSkills = prevState.map((s) => {
        if (s.Name === Name) {
          return { ...s, Level: level };
        }
        return s;
      });
      return mSkills;
    });
  };
  const nonRatedLanguageSkillsCount = !!nonRatedLanguageSkills ? nonRatedLanguageSkills.length : 0;

  return (
    <div key={level} ref={drop} className={`rate-skills--skills-group--container ${isOver ? "is-over" : ""} non-rated-skills-count-${nonRatedLanguageSkillsCount}`}>
      <h3 className={`text-style--Body1 text-style--semibold ${nonRatedLanguageSkillsCount && nonRatedLanguageSkillsCount > 0 ? "text-color--brand10" : "text-color--grey44"}`}>
        {sectionHeading} {level === 0 && `(${nonRatedLanguageSkillsCount})`}
      </h3>
      <div className="skills-container">{languageSkillsToMap.length > 0 ? languageSkillsToMap.map((skill) => <LanguageSkill key={skill.Name} languageSkill={skill} languageSkills={languageSkills} setLanguageSkills={setLanguageSkills} />) : <>{level !== 0 && <span className={`drag-skills-here non-rated-skills-count-${nonRatedLanguageSkills}`}>{t("DragSkillsHere")}</span>}</>}</div>
      {level === 0 && <p className="text-style--Body1 text-color--grey44 fw-600 pt-16">{t("EditLanguageSkills.DragAndDropDescriptionText")}</p>}
    </div>
  );
};

const LanguageSkill = ({ languageSkill, languageSkills, setLanguageSkills }: ILanguageSkillSingleProps) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "skill",
    item: { Name: languageSkill.Name },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const handleRemove = (Name: string) => {
    const fSkills = languageSkills.filter((s) => s.Name !== Name);
    setLanguageSkills(fSkills);
  };
  return (
    <div ref={drag} className="rate-skills--skill">
      <IconReOrderDots className="icon-reorder" />
      <span>{languageSkill.Name}</span>
      <button className="remove-skill" onClick={() => handleRemove(languageSkill.Name)}>
        <IconDismiss className="icon-size--10" />
      </button>
    </div>
  );
};
