import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { InitialSetup, SetupContextType } from "interfaces/InitialSetup";
import { getInitialSetupStatus } from "services/apiService";

const defaultSetupStatus: InitialSetup = {
  ConfigurationExist: true,
  TeamsBotEnabled: true,
  AdminsAdded: true,
};

const defaultState: SetupContextType = {
  isSetupComplete: true,
  isLoading: true,
  setupStatus: defaultSetupStatus,
  setSetupStatus: () => {},
};

const SetupContext = createContext<SetupContextType>(defaultState);

interface SetupProviderProps {
  children: ReactNode;
}

export const SetupStatusProvider = ({ children }: SetupProviderProps) => {
  const [setupStatus, setSetupStatus] = useState(defaultSetupStatus);
  const [isLoading, setIsLoading] = useState(true);
  const updateSetupStatus = (status: InitialSetup) => {
    setSetupStatus(status);
  };

  useEffect(() => {
    const fetchSetupStatus = async () => {
      setIsLoading(true);
      try {
        const response = await getInitialSetupStatus();
        setSetupStatus(response);
      } catch (error) {
        console.error("Error fetching setup status:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSetupStatus();
  }, []);

  return (
    <SetupContext.Provider
      value={{
        isSetupComplete: setupStatus.ConfigurationExist && setupStatus.TeamsBotEnabled && setupStatus.AdminsAdded,
        isLoading: isLoading,
        setupStatus,
        setSetupStatus: updateSetupStatus,
      }}
    >
      {children}
    </SetupContext.Provider>
  );
};

export const useSetup = () => useContext(SetupContext);
