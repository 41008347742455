import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconReOrderDots = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Re Order Dots">
          <path id="Shape" d="M6.33301 5.5C6.88529 5.5 7.33301 5.05228 7.33301 4.5C7.33301 3.94772 6.88529 3.5 6.33301 3.5C5.78072 3.5 5.33301 3.94772 5.33301 4.5C5.33301 5.05228 5.78072 5.5 6.33301 5.5ZM6.33301 9.5C6.88529 9.5 7.33301 9.05228 7.33301 8.5C7.33301 7.94772 6.88529 7.5 6.33301 7.5C5.78072 7.5 5.33301 7.94772 5.33301 8.5C5.33301 9.05228 5.78072 9.5 6.33301 9.5ZM7.33301 12.5C7.33301 13.0523 6.88529 13.5 6.33301 13.5C5.78072 13.5 5.33301 13.0523 5.33301 12.5C5.33301 11.9477 5.78072 11.5 6.33301 11.5C6.88529 11.5 7.33301 11.9477 7.33301 12.5ZM10.333 5.5C10.8853 5.5 11.333 5.05228 11.333 4.5C11.333 3.94772 10.8853 3.5 10.333 3.5C9.78072 3.5 9.33301 3.94772 9.33301 4.5C9.33301 5.05228 9.78072 5.5 10.333 5.5ZM11.333 8.5C11.333 9.05228 10.8853 9.5 10.333 9.5C9.78072 9.5 9.33301 9.05228 9.33301 8.5C9.33301 7.94772 9.78072 7.5 10.333 7.5C10.8853 7.5 11.333 7.94772 11.333 8.5ZM10.333 13.5C10.8853 13.5 11.333 13.0523 11.333 12.5C11.333 11.9477 10.8853 11.5 10.333 11.5C9.78072 11.5 9.33301 11.9477 9.33301 12.5C9.33301 13.0523 9.78072 13.5 10.333 13.5Z" fill="#242424" />
        </g>
      </svg>
    </span>
  );
};
