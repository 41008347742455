import { useState } from "react";
import { t } from "i18next";
import { ChoiceGroup, IChoiceGroupOption, TextField, IconButton, Dialog, DialogFooter } from "@fluentui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IUserProfile } from "interfaces/IUserProfile";
import { saveUserProfile, sendUsersLinkedInData } from "services/apiService";
import { DeliwiButton } from "components/DeliwiButton";
import { HelpDialog } from "components/HelpDialog";
import { IconCheckmark } from "components/Icons/IconCheckmark";
import { IconClose } from "components/Icons/IconClose";
import "assets/styles/components/Forms.scss";
import deliwiRound from "assets/images/deliwi-round.svg";
import linkedinMe from "assets/images/linkedin_me.png";

interface UserFirstVisitLinkedInEditProps {
  userProfileData: IUserProfile | undefined;
  userProfileSetter: (data: IUserProfile) => void;
  finishSteps: () => void;
}

interface LinkedInFormValues {
  LinkedInPermission: string;
  LinkedInAddress: string;
}

const linkedInOptions: IChoiceGroupOption[] = [
  { key: "linkedin_allow", text: t("LinkedInPermissionAllow") },
  { key: "linkedin_disallow", text: t("LinkedInPermissionDisallowOrNoProfile") },
];

export const UserFirstVisitLinkedInEdit = ({ userProfileData, userProfileSetter, finishSteps }: UserFirstVisitLinkedInEditProps) => {
  const [isInfoVisible, setInfoVisible] = useState(false);

  const validationSchema = Yup.object({
    LinkedInPermission: Yup.string().required(t("FieldRequired")),
    LinkedInAddress: Yup.string().when("LinkedInPermission", (LinkedInPermission: any, schema) => {
      const permission = LinkedInPermission as string;
      if (permission[0] === "linkedin_allow") {
        return schema
          .transform((currentValue) => {
            let transformedValue = currentValue.trim(); // Trim any leading/trailing spaces

            // Remove all spaces within the string
            transformedValue = transformedValue.replace(/\s+/g, "");

            // Check if the URL does not start with "http://" or "https://", and prepend if necessary
            const doesNotStartWithHttp = transformedValue && !(transformedValue.startsWith("http://") || transformedValue.startsWith("https://"));
            if (doesNotStartWithHttp) {
              transformedValue = `https://${transformedValue}`;
            }

            // Check if the URL contains "https://www.linkedin.com/in" or "https://linkedin.com/in"
            const isValidLinkedInUrl = transformedValue.startsWith("https://www.linkedin.com/in") || transformedValue.startsWith("https://linkedin.com/in");

            // If the URL does not meet the LinkedIn URL criteria, return undefined to trigger validation error
            if (!isValidLinkedInUrl) {
              return undefined;
            }

            return transformedValue;
          })
          .url(t("InvalidLinkedInURL"))
          .required(t("InvalidLinkedInURL"));
      } else {
        return schema.notRequired();
      }
    }),
  });

  const formik = useFormik<LinkedInFormValues>({
    initialValues: {
      LinkedInPermission: userProfileData?.LinkedInDataRetrievalConsent === null || userProfileData?.LinkedInDataRetrievalConsent ? "linkedin_allow" : "linkedin_disallow",
      LinkedInAddress: userProfileData?.LinkedInProfileUrl || "",
    },
    validationSchema,
    onSubmit: async (values: LinkedInFormValues) => {
      await saveNewValues(values);
      finishSteps();
    },
  });

  const saveNewValues = async (values: LinkedInFormValues) => {
    const { LinkedInPermission, LinkedInAddress } = values;
    const linkedInPermission = LinkedInPermission === "linkedin_allow";
    const linkedInProfileUrl = LinkedInAddress;

    await sendUsersLinkedInData(linkedInPermission, linkedInProfileUrl);

    const updatedProfile = {
      ...userProfileData,
      IsProfileInitialized: true,
      LinkedInDataRetrievalConsent: linkedInPermission,
      LinkedInProfileUrl: linkedInProfileUrl,
    } as IUserProfile;

    userProfileSetter(updatedProfile);
    saveUserProfile(updatedProfile);
  };

  const handleToggleInfo = () => setInfoVisible(!isInfoVisible);

  const isButtonDisabled = formik.values.LinkedInPermission === "linkedin_allow" ? !formik.isValid || (formik.touched.LinkedInAddress && !!formik.errors.LinkedInAddress) : false;

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="form--container mb-24">
          <div className="form--container--inner">
            <div className="form--container--header mb-32">
              <div className="logo-wrapper">
                <img src={deliwiRound} alt="DeliwiAI" className="icon-size-62 icon-shadow" />
              </div>
              <div className="text-style--Body1 fw-600">{t("EnrichProfileWithLinkedIn")}</div>
            </div>
            {formik.values.LinkedInPermission === "linkedin_allow" && (
              <div>
                <label className="linkedin-infolabel">
                  {t("YourLinkedInAddress")}
                  <IconButton iconProps={{ iconName: "Info" }} onClick={handleToggleInfo} title={t("ShowExplanation")} />
                </label>
                <div className="form--field--wrapper linkedin-input col-12 mb-32">
                  <TextField label="" id="LinkedInAddress" placeholder={t("CopyPasteLinkedIn")} name="LinkedInAddress" defaultValue={formik.values.LinkedInAddress} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                  <div className="linkedin-icon"></div>
                </div>
                {formik.errors.LinkedInAddress ? <div className="input-error">{formik.errors.LinkedInAddress}</div> : null}
                <Dialog
                  hidden={!isInfoVisible}
                  onDismiss={handleToggleInfo}
                  dialogContentProps={{
                    showCloseButton: true,
                    title: t("ToFindPublicProfileURL"),
                  }}
                  modalProps={{
                    className: "linkedin__dialog",
                  }}
                >
                  <div className="linkedin__dialog--content">
                    <ul className="linkedin-instructions text-style--Body1">
                      <li>
                        <span>
                          Click the <img src={linkedinMe} alt="" /> <span>Me</span> icon or profile picture at the top of your LinkedIn homepage.
                        </span>
                      </li>
                      <li>
                        <span>
                          Click the <span>View Profile.</span>
                        </span>
                      </li>
                      <li>
                        <span>
                          On your profile page, click <span>Edit public profile & URL</span> on the right pane.
                        </span>
                      </li>
                      <li>
                        <span>
                          Your public profile URL would be located under the <span>Edit your custom URL</span> section.
                          <ul>
                            <li className="text-style--Body2">
                              It'll be an address that starts with <strong>www.linkedin.com/in</strong>
                            </li>
                          </ul>
                        </span>
                      </li>
                      <li>
                        <span>
                          Copy and paste that URL <span>to input field.</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <DialogFooter>
                    <DeliwiButton onClick={handleToggleInfo} className="button-style--secondary button-size--medium">
                      <IconClose />
                      {t("Close")}
                    </DeliwiButton>
                  </DialogFooter>
                </Dialog>
              </div>
            )}
            <div className="form--fields-container flex-column">
              <div className="form--field--wrapper col-12">
                <HelpDialog dialogContent={t("Help.LinkedIn.Content")} title={t("Help.LinkedIn.Title")} className="mb-16">
                  <p className="text-style--Body1 fw-600 text-color--neutral-foreground">{t("LabelLinkedInPermissions")}</p>
                </HelpDialog>
                <ChoiceGroup
                  label=""
                  name="LinkedInPermission"
                  options={linkedInOptions}
                  selectedKey={formik.values.LinkedInPermission}
                  onChange={(event, option) => {
                    if (option !== undefined) {
                      formik.setFieldValue("LinkedInPermission", option.key);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>

        <DeliwiButton className="button-style--primary button-size--large ml-auto" type="submit" disabled={isButtonDisabled}>
          <IconCheckmark />
          {t("SaveAndStartToUse")}
        </DeliwiButton>
      </form>
    </>
  );
};
