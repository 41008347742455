import React from "react";
import ReactDOM from "react-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./msalConfig";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { App } from "App";
import { setMsalInstance } from "./services/apiService";

initializeIcons();

// MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
const msalInstance = new PublicClientApplication(msalConfig);
setMsalInstance(msalInstance);

msalInstance
  .handleRedirectPromise()
  .then((response) => {
    if (response) {
      // console.log("Token acquired successfully:", response.accessToken);
      // Handle the token, e.g., store it or use it as needed.
    }
  })
  .catch((error) => {
    // console.error("Error handling redirect:", error);
  });

ReactDOM.render(
  <React.StrictMode>
    <App instance={msalInstance} />
  </React.StrictMode>,
  document.getElementById("root"),
);
