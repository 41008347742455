import { useState, ReactNode } from "react";

import "assets/styles/components/Collapsible.scss";

export interface CollapsibleProps {
  title: string;
  icon?: string;
  children: ReactNode;
}

export const Collapsible = (props: CollapsibleProps) => {
  const { title, icon, children } = props;
  const [isOpen, setIsOpen] = useState(false);
  const collapseClassName = isOpen ? "open" : "closed";

  return (
    <div className={`collapse-element ${collapseClassName}`}>
      <div onClick={() => setIsOpen(!isOpen)} className={`collapse-element__header${icon ? " has-icon" : " no-icon"}`} aria-label="Expand or collapse">
        {icon && <img src={icon} className="icon-size-62 icon-shadow" alt="" />}
        <h3 className="text-style--Title3 text-style--semibold">{title}</h3>
      </div>
      {isOpen && <div className="collapse-element__content">{children}</div>}
    </div>
  );
};
