import { useState } from "react";
import { t } from "i18next";

import { RunAnalysis } from "components/ControlPanel/RunAnalysis";
import { CreateSurvey } from "components/ControlPanel/CreateSurvey";
import { SurveyReports } from "components/ControlPanel/SurveyReports";
import { DeliwiButton } from "components/DeliwiButton";
import { HelpDialog } from "components/HelpDialog";

export const SkillAnalysisPanel = () => {
  const [activeComponent, setActiveComponent] = useState<string>("RunAnalysis");
  const [reloadKey, setReloadKey] = useState<number>(0); // Add a key to track re-rendering

  const handleButtonClick = (componentName: string) => {
    setActiveComponent(componentName);
    setReloadKey((prevKey) => prevKey + 1); // Increment the key to force re-render
  };

  const makeSurveyReportsActive = () => {
    handleButtonClick("SurveyReports");
  };

  // Function to render the active component with a key
  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "RunAnalysis":
        return <RunAnalysis key={reloadKey} makeSurveyReportsActive={makeSurveyReportsActive} />;
      case "CreateSurvey":
        return <CreateSurvey key={reloadKey} makeSurveyReportsActive={makeSurveyReportsActive} />;
      case "SurveyReports":
        return <SurveyReports key={reloadKey} makeSurveyReportsActive={makeSurveyReportsActive} />;
      default:
        return null;
    }
  };

  return (
    <div className="admin-box admin-analyses mb-40">
      <HelpDialog className="mb-32" dialogContent={t("Help.InvestigateYourOrganization.Content")} title={t("Help.InvestigateYourOrganization.Title")}>
        <h3 className="text-style--Subtitle1">{t("InvestigateYourOrganization")}</h3>
      </HelpDialog>
      <div className="admin-analyses__buttons mb-40">
        <DeliwiButton className={`${activeComponent === "RunAnalysis" ? "button-style--secondary button-size--medium" : "button-style--outline button-size--medium"}`} onClick={() => handleButtonClick("RunAnalysis")}>
          {t("RunAnalysis")}
        </DeliwiButton>
        <DeliwiButton className={`${activeComponent === "CreateSurvey" ? "button-style--secondary button-size--medium" : "button-style--outline button-size--medium"}`} onClick={() => handleButtonClick("CreateSurvey")}>
          {t("CreateSurvey")}
        </DeliwiButton>
        <DeliwiButton className={`${activeComponent === "SurveyReports" ? "button-style--secondary button-size--medium" : "button-style--outline button-size--medium"}`} onClick={() => handleButtonClick("SurveyReports")}>
          {t("SurveyReports")}
        </DeliwiButton>
      </div>
      {renderActiveComponent()}
    </div>
  );
};
