import { FormEvent, useState } from "react";
import { t } from "i18next";
import { ChoiceGroup, IChoiceGroupOption, TextField, IconButton, Dialog, DialogFooter, Checkbox } from "@fluentui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IUserProfile, IUserProfileEditForm } from "interfaces/IUserProfile";
import { saveUserProfile, sendUsersLinkedInData } from "services/apiService";
import { DeliwiButton } from "components/DeliwiButton";
import { HelpDialog } from "components/HelpDialog";
import { IconCheckmark } from "components/Icons/IconCheckmark";
import { IconClose } from "components/Icons/IconClose";
import "assets/styles/components/Forms.scss";
import deliwiRound from "assets/images/deliwi-round.svg";
import linkedinMe from "assets/images/linkedin_me.png";
import { IconChevron } from "../../Icons/IconChevron";
import { IconLinkedIn } from "../../Icons/IconLinkedIn";
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";

interface UserFirstVisitLinkedInEditProps {
  userProfileData: IUserProfile | undefined;
  userProfileSetter: (data: IUserProfile) => void;
  finishSteps: () => void;
}

interface LinkedInFormValues {
  LinkedInPermission: string;
  LinkedInAddress: string;
}

const linkedInOptions: IChoiceGroupOption[] = [
  { key: "linkedin_allow", text: t("LinkedInPermissionAllow") },
  { key: "linkedin_disallow", text: t("LinkedInPermissionDisallowOrNoProfile") },
];

export const UserLinkedInEdit = (props: IUserProfileEditForm) => {
  const [isInfoVisible, setInfoVisible] = useState(false);
  const [linkedInFieldsVisible, setLinkedInFieldsVisible] = useState<boolean>(false);
  const shadowUserProfile = props.userProfileData;

  const triggerLinkedInFieldsVisibility = () => {
    if (linkedInFieldsVisible) {
      setLinkedInFieldsVisible(false);
    } else {
      setLinkedInFieldsVisible(true);
    }
  };

  const validationSchema = Yup.object({
    LinkedInPermission: Yup.string().required(t("FieldRequired")),
    LinkedInAddress: Yup.string().when("LinkedInPermission", (LinkedInPermission: any, schema) => {
      const permission = LinkedInPermission as string;
      if (permission[0] === "linkedin_allow") {
        return schema
          .transform((currentValue) => {
            let transformedValue = currentValue.trim(); // Trim any leading/trailing spaces

            // Remove all spaces within the string
            transformedValue = transformedValue.replace(/\s+/g, "");

            // Check if the URL does not start with "http://" or "https://", and prepend if necessary
            const doesNotStartWithHttp = transformedValue && !(transformedValue.startsWith("http://") || transformedValue.startsWith("https://"));
            if (doesNotStartWithHttp) {
              transformedValue = `https://${transformedValue}`;
            }

            // Check if the URL contains "https://www.linkedin.com/in" or "https://linkedin.com/in"
            const isValidLinkedInUrl = transformedValue.startsWith("https://www.linkedin.com/in") || transformedValue.startsWith("https://linkedin.com/in");

            // If the URL does not meet the LinkedIn URL criteria, return undefined to trigger validation error
            if (!isValidLinkedInUrl) {
              return undefined;
            }

            return transformedValue;
          })
          .url(t("InvalidLinkedInURL"))
          .required(t("InvalidLinkedInURL"));
      } else {
        return schema.notRequired();
      }
    }),
  });

  const handleLinkedInConsentChange = (ev?: FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean) => {
    // Ensure that we have the event and the checked value is defined

    if (ev && checked !== undefined) {
      if (checked) {
        formik.setFieldValue("LinkedInPermission", "linkedin_allow");
      } else {
        formik.setFieldValue("LinkedInPermission", "linkedin_disallow");
      }
    }
  };

  const formik = useFormik<LinkedInFormValues>({
    initialValues: {
      LinkedInPermission: shadowUserProfile?.LinkedInDataRetrievalConsent ? "linkedin_allow" : "linkedin_disallow",
      LinkedInAddress: shadowUserProfile?.LinkedInProfileUrl || "",
    },
    validationSchema,
    onSubmit: async (values: LinkedInFormValues) => {
      await saveNewValues(values);
    },
  });

  const saveNewValues = async (values: LinkedInFormValues) => {
    const { LinkedInPermission, LinkedInAddress } = values;
    const linkedInPermission = LinkedInPermission === "linkedin_allow";
    const linkedInProfileUrl = LinkedInAddress;

    await sendUsersLinkedInData(linkedInPermission, linkedInProfileUrl);

    const updatedProfile = {
      ...shadowUserProfile,
      LinkedInDataRetrievalConsent: linkedInPermission,
      LinkedInProfileUrl: linkedInProfileUrl,
    } as IUserProfile;

    try {
      await props.saveProfile(updatedProfile);
      props.userProfileSetter(updatedProfile);
      props.hidePopup();
    } catch (error) {
      console.error("Failed to save changes:", error);
    }
  };

  const handleToggleInfo = () => setInfoVisible(!isInfoVisible);

  const isButtonDisabled = formik.values.LinkedInPermission === "linkedin_allow" ? !formik.isValid || (formik.touched.LinkedInAddress && !!formik.errors.LinkedInAddress) : false;

  return (
    <>
      <div className="linkedin-dialog-heading form--heading--wrapper">
        <h2 className="form--heading text-style--Subtitle2">{t("Linkedin")}</h2>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-edit-popup" onClick={() => props.hidePopup()}></ChromeCloseIcon>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="form--container linkedin-edit-form mb-24">
          <div className="form--container--inner">
            <div className="form--container--header mb-32">
              <div className="logo-wrapper icon-shadow icon-size-62 bg-white">
                <IconLinkedIn />
              </div>
              <div>
                <div className="text-style--Title2 fw-600 mb-16">{t("EditForms.EditLinkedIn.Title")}</div>
                <div className="why-linkedin-description">
                  <div className="title text-style--Subtitle2 fw-600">{t("EditForms.EditLinkedIn.DescriptionTitle")}</div>
                  <div className="description text-style--Body2">{t("EditForms.EditLinkedIn.Description")}</div>
                </div>
              </div>
            </div>
            <div className="form--fields-container flex-column">
              <div className="form--field--wrapper col-12">
                <HelpDialog dialogContent={t("Help.LinkedIn.Content")} title={t("Help.LinkedIn.Title")} className="mb-16">
                  <p className="text-style--Subtitle1 fw-600 text-color--neutral-foreground">{t("LabelLinkedInPermissions")}</p>
                </HelpDialog>
                <Checkbox className="mb-16" label="I give DeliwiAI permission to import my public LinkedIn profile data" onChange={handleLinkedInConsentChange} defaultChecked={formik.values.LinkedInPermission === "linkedin_allow"}></Checkbox>
              </div>
            </div>
            {formik.values.LinkedInPermission === "linkedin_allow" && (
              <div>
                <label className="linkedin-infolabel">
                  {t("YourLinkedInAddress")}
                  <IconButton iconProps={{ iconName: "Info" }} onClick={handleToggleInfo} title={t("ShowExplanation")} />
                </label>
                <div className="form--field--wrapper linkedin-input col-12 mb-32">
                  <TextField label="" id="LinkedInAddress" placeholder={t("CopyPasteLinkedIn")} name="LinkedInAddress" defaultValue={formik.values.LinkedInAddress} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                  <div className="linkedin-icon"></div>
                </div>
                {formik.errors.LinkedInAddress ? <div className="input-error">{formik.errors.LinkedInAddress}</div> : null}
                <Dialog
                  hidden={!isInfoVisible}
                  onDismiss={handleToggleInfo}
                  dialogContentProps={{
                    showCloseButton: true,
                    title: t("ToFindPublicProfileURL"),
                  }}
                  modalProps={{
                    className: "linkedin__dialog",
                  }}
                >
                  <div className="linkedin__dialog--content">
                    <ul className="linkedin-instructions text-style--Body1">
                      <li>
                        <span>
                          Click the <img src={linkedinMe} alt="" /> <span>Me</span> icon or profile picture at the top of your LinkedIn homepage.
                        </span>
                      </li>
                      <li>
                        <span>
                          Click the <span>View Profile.</span>
                        </span>
                      </li>
                      <li>
                        <span>
                          On your profile page, click <span>Edit public profile & URL</span> on the right pane.
                        </span>
                      </li>
                      <li>
                        <span>
                          Your public profile URL would be located under the <span>Edit your custom URL</span> section.
                          <ul>
                            <li className="text-style--Body2">
                              It'll be an address that starts with <strong>www.linkedin.com/in</strong>
                            </li>
                          </ul>
                        </span>
                      </li>
                      <li>
                        <span>
                          Copy and paste that URL <span>to input field.</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <DialogFooter>
                    <DeliwiButton onClick={handleToggleInfo} className="button-style--secondary button-size--medium">
                      <IconClose />
                      {t("Close")}
                    </DeliwiButton>
                  </DialogFooter>
                </Dialog>
              </div>
            )}
          </div>
        </div>

        <div className="long-text--container">
          <div className="info-text mb-16">
            <p className="text-style--Body1 text-style--semibold" dangerouslySetInnerHTML={{ __html: t("EditForms.EditLinkedIn.FieldsDescription") }} />
          </div>
          <div className="linkedin-fields--description">
            <div className="linkedin-fields--trigger text-style--Body1 text-style--semibold text-color--brand80 mb-16" onClick={triggerLinkedInFieldsVisibility}>
              {t("EditForms.EditLinkedIn.ShowAllFieldsTrigger")}

              <IconChevron className={`icon-size--10 ${linkedInFieldsVisible ? "chevron-up" : ""}`}></IconChevron>
            </div>
            {linkedInFieldsVisible && (
              <div className="linkedin-fields--fields-list">
                <span className="list-label text-style--Body1 text-style--bold">{t("WorkExperience")}</span>
                <ul className="linkedin-fields--fields-list--items">
                  <li className="text-style--Body1">{t("MyCareer")}</li>
                  <li className="text-style--Body1">{t("WorkExperience")}</li>
                </ul>
                <span className="list-label text-style--Body1 text-style--bold">{t("EducationTrainings")}</span>
                <ul className="linkedin-fields--fields-list--items">
                  <li className="text-style--Body1">{t("Education")}</li>
                </ul>
                <span className="list-label text-style--Body1 text-style--bold">{t("Skills")}</span>
                <ul className="linkedin-fields--fields-list--items">
                  <li className="text-style--Body1">{t("Skills")}</li>
                  <li className="text-style--Body1">{t("LanguageSkills")}</li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="edit-dialog--buttons mt-40">
          <DeliwiButton className="button-style--outline button-size--large" onClick={() => props.hidePopup()}>
            <IconClose className="icon-size--20" />
            {t("Cancel")}
          </DeliwiButton>
          <DeliwiButton className="button-style--primary button-size--large" type="submit" disabled={isButtonDisabled}>
            <IconCheckmark />
            {t("EditForms.EditLinkedIn.SubmitButton")}
          </DeliwiButton>
        </div>
      </form>
    </>
  );
};
