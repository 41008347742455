import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconArrowDown = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 0C11.5523 0 12 0.44772 12 1V22.5858L20.2929 14.2929C20.6834 13.9024 21.3166 13.9024 21.7071 14.2929C22.0976 14.6834 22.0976 15.3166 21.7071 15.7071L11.7071 25.7071C11.3166 26.0976 10.6834 26.0976 10.2929 25.7071L0.29289 15.7071C-0.09763 15.3166 -0.09763 14.6834 0.29289 14.2929C0.68342 13.9024 1.31658 13.9024 1.70711 14.2929L10 22.5858V1C10 0.44772 10.4477 0 11 0Z" fill="#08757A" />
      </svg>
    </span>
  );
};
