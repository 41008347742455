import { useState, useRef, useEffect, KeyboardEvent, ChangeEvent } from "react";

import { t } from "i18next";

import { Dialog, DialogFooter, Dropdown } from "@fluentui/react";
import { DeliwiButton } from "components/DeliwiButton";

import { IUserData } from "interfaces/IUser";

import { getUserProfiles } from "services/apiService";
import { deleteUserProfile } from "services/apiService";
import { getTeamsBotStatus } from "services/apiService";

import { UsersList } from "components/ControlPanel/UsersList";
import { Pagination } from "components/ControlPanel/UsersListPagination";

import { IconDismiss } from "components/Icons/IconDismiss";
import { IconTrash } from "components/Icons/IconTrash";

import "assets/styles/pages/ControlPanel.scss";

export const ManageUsers = () => {
  const [userProfiles, setUserProfiles] = useState<IUserData[]>([]);
  const [teamsBotStatus, setTeamsBotStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [orderByColumn, setOrderByColumn] = useState<number>(0);
  const [usersAdded, setUsersAdded] = useState<boolean>(false);
  const [orderByDescending, setOrderByDescending] = useState<boolean>(false);
  const [isExiting, setIsExiting] = useState(false);
  const [isEntering, setIsEntering] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [showDeleteConfirmationMultiple, setShowDeleteConfirmationMultiple] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<string>("");
  const [usersToDelete, setUsersToDelete] = useState<string>("");
  const [usersPerPage, setUsersPerPage] = useState(20);

  const enterKeyRef = useRef(false);
  const searchTimeoutRef = useRef<number | null>(null);

  const dropdownOptions = [
    { key: "5", text: "5" },
    { key: "10", text: "10" },
    { key: "15", text: "15" },
    { key: "20", text: "20" },
    { key: "30", text: "30" },
    { key: "50", text: "50" },
  ];

  const handleDeleteClick = async (Id: string) => {
    setShowDeleteConfirmation(true);
    setUserToDelete(Id);
  };

  useEffect(() => {
    const fetchTeamsBotStatus = async () => {
      try {
        const response = await getTeamsBotStatus();
        if (response) {
          setTeamsBotStatus(response.TeamsBotEnabled);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch Teams Bot status", error);
        setIsLoading(false);
      }
    };
    fetchTeamsBotStatus();
  }, []);

  const fetchUsers = async (search = "", orderByDesc = false, orderByCol = 0) => {
    setIsLoadingUsers(true);
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
      searchTimeoutRef.current = null;
    }

    try {
      setIsExiting(true);
      setIsEntering(false);

      const offset = (currentPage - 1) * usersPerPage;
      const response = await getUserProfiles(offset, usersPerPage, search, orderByDesc, orderByCol);

      if (response && response.Data) {
        setUserProfiles(response.Data);
        setTotalPages(Math.ceil(response.TotalCount / usersPerPage));
      }

      setTimeout(() => {
        setIsEntering(true);
      }, 500);
      setIsLoadingUsers(false);
    } catch (error) {
      console.error("Failed to fetch user profiles:", error);
      setIsLoadingUsers(false);
    }
  };

  useEffect(() => {
    if (teamsBotStatus && (searchTerm.length === 0 || searchTerm.length > 2)) {
      setIsLoadingUsers(true); // Ensure loading state is set immediately
      fetchUsers(searchTerm, orderByDescending, orderByColumn);
    }
  }, [teamsBotStatus, orderByDescending, orderByColumn, searchTerm, currentPage, usersPerPage, usersAdded]);

  const clearSearch = () => {
    setSearchTerm("");
    fetchUsers(searchTerm, orderByDescending, orderByColumn);
  };

  const refreshUserList = async (): Promise<void> => {
    fetchUsers(searchTerm, orderByDescending, orderByColumn);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (searchTimeoutRef.current !== null) {
        clearTimeout(searchTimeoutRef.current);
        searchTimeoutRef.current = null;
      }
      if (searchTerm.length > 2) {
        enterKeyRef.current = true;
        fetchUsers(searchTerm, orderByDescending, orderByColumn);
      }
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const sortUserList = (columnIndex: number, isDescending: boolean) => {
    setOrderByColumn(columnIndex);
    setOrderByDescending(isDescending);

    fetchUsers(searchTerm, isDescending, columnIndex);
  };

  const confirmDeleteUser = async () => {
    try {
      await deleteUserProfile(userToDelete);
      const updatedUserProfiles = userProfiles.filter((user) => user.Id !== userToDelete);
      setUserProfiles(updatedUserProfiles);

      if (userProfiles.length === 1 && currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    } catch (error) {
      console.error("Failed to delete user:", error);
    } finally {
      setShowDeleteConfirmation(false); // Close the confirmation modal
    }
  };

  const confirmDeleteMultipleUsers = async () => {
    try {
      const userIds = usersToDelete.split(","); // Splitting the string into an array of user IDs
      for (const userId of userIds) {
        await deleteUserProfile(userId.trim()); // Trim to remove any extra whitespace
      }

      const updatedUserProfiles = userProfiles.filter((user) => !userIds.includes(user.Id));
      setUserProfiles(updatedUserProfiles);

      // Update the current page if necessary
      if (updatedUserProfiles.length === 0 && currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    } catch (error) {
      console.error("Failed to delete users:", error);
    } finally {
      setShowDeleteConfirmationMultiple(false); // Close the confirmation modal
    }
  };

  const handleMultiUserDeletion = (userIds: string) => {
    setShowDeleteConfirmationMultiple(true);
    setUsersToDelete(userIds);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page on search
  };

  if (isLoading) {
    return <div className="deliwi-spinner"></div>;
  }

  return teamsBotStatus ? (
    <>
      {showDeleteConfirmation && (
        <Dialog
          hidden={!showDeleteConfirmation}
          onDismiss={() => setShowDeleteConfirmation(false)}
          dialogContentProps={{
            showCloseButton: true,
            title: t("DeleteUserFromDeliwiAI"),
          }}
          modalProps={{
            className: "admin-dialog admin-dialog--delete",
          }}
        >
          <div className="box-gray mb-32">
            <p className="text-style--Body1 text-style--semibold mb-16">{t("AreYouSure")}</p>
            <p className="text-style--Body1">{t("WhenDeletingUserAllInformationLost")}</p>
          </div>
          <DialogFooter>
            <DeliwiButton className="button-style--outline button-size--large" onClick={() => setShowDeleteConfirmation(false)} text={t("Cancel")}>
              <IconDismiss className="icon-size--16" />
            </DeliwiButton>
            <DeliwiButton className="button-style--delete button-size--large" onClick={confirmDeleteUser} text={t("Delete")}>
              <IconTrash />
            </DeliwiButton>
          </DialogFooter>
        </Dialog>
      )}

      {showDeleteConfirmationMultiple && (
        <Dialog
          hidden={!showDeleteConfirmationMultiple}
          onDismiss={() => setShowDeleteConfirmationMultiple(false)}
          dialogContentProps={{
            showCloseButton: true,
            title: t("DeleteUsersFromDeliwiAI"),
          }}
          modalProps={{
            className: "admin-dialog admin-dialog--delete",
          }}
        >
          <div className="box-gray mb-32">
            <p className="text-style--Body1 text-style--semibold mb-16">{t("AreYouSure")}</p>
            <p className="text-style--Body1">{t("WhenDeletingUsersAllInformationLost")}</p>
          </div>
          <DialogFooter>
            <DeliwiButton className="button-style--outline button-size--large" onClick={() => setShowDeleteConfirmationMultiple(false)} text={t("Cancel")}>
              <IconDismiss className="icon-size--16" />
            </DeliwiButton>
            <DeliwiButton className="button-style--delete button-size--large" onClick={confirmDeleteMultipleUsers} text={t("Delete")}>
              <IconTrash />
            </DeliwiButton>
          </DialogFooter>
        </Dialog>
      )}

      <div className="admin-search__userlist mt-48 mb-32">
        <input type="text" placeholder={t("SearchListByNameOrEmail")} value={searchTerm} onChange={handleSearchChange} onKeyDown={handleKeyDown} className="admin-search__userlist-input" />
        {searchTerm && (
          <DeliwiButton onClick={clearSearch} className="button-style--outline button-size--large">
            <IconDismiss className="icon-size--16" />
            {t("ClearSearch")}
          </DeliwiButton>
        )}
      </div>
      <div className="admin-search__paging text-style--Body3 text-color--grey44 mb-16">
        <span>{t("Show")}</span>
        <Dropdown
          selectedKey={usersPerPage.toString()}
          onChange={(_, item) => {
            if (item) {
              setUsersPerPage(Number(item.key));
              setCurrentPage(1);
            }
          }}
          className="admin-search__paging--dropdown"
          placeholder=""
          options={dropdownOptions}
          styles={{ dropdown: { width: 60 } }}
        />
        <span>{t("Users")}</span>
      </div>
      <div className="userlist-wrapper">
        <div className={isExiting ? "fade-exit-active" : isEntering ? "fade-enter-active" : ""}>
          {isLoadingUsers && (
            <div className="deliwi-spinner--wrap">
              <div className="deliwi-spinner"></div>
            </div>
          )}

          <UsersList userProfiles={userProfiles} sortUserList={sortUserList} orderByColumn={orderByColumn} orderByDescending={orderByDescending} onDeleteUser={handleDeleteClick} updateUserList={refreshUserList} userIdsToDelete={handleMultiUserDeletion} />
          <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
        </div>
      </div>
    </>
  ) : (
    // Your JSX for when teamsBotStatus is false
    <div>{/* Content to display when teamsBotStatus is false */}</div>
  );
};
