import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { useLocation, useNavigate } from "react-router-dom";
import { IUserProfile } from "../../interfaces/IUserProfile";
import { useEffect, useState, MouseEvent } from "react";
import { t } from "i18next";
import { getUserProfile, saveUserProfile } from "../../services/apiService";
import { TabMyProfile } from "./UserProfileTabs/TabMyProfile";
import { TabWorkExperience } from "./UserProfileTabs/TabWorkExperience";
import { TabSkills } from "./UserProfileTabs/TabSkills";
import { TabEducationTrainings } from "./UserProfileTabs/TabEducationTrainings";
import { UserProfileEdit } from "./UserProfileEdit";
import { useBoolean } from "@fluentui/react-hooks";
import { TabMyInterests } from "./UserProfileTabs/TabMyInterests";
import { useMsal } from "@azure/msal-react";
import { useUserRole } from "contexts/UserRoleContext";
import { DeliwiButton } from "../DeliwiButton";
import { IconLink } from "../Icons/IconLink";

export interface IUserProfileDetailsProps {
  userProfile: IUserProfile;
  displayMode: string;
  saving?: boolean;
  setSaving?: (data: boolean) => void;
}

export const UserProfileDetails = (props: IUserProfileDetailsProps) => {
  const { userRole } = useUserRole();
  const { accounts } = useMsal();
  const [userProfile, setUserProfile] = useState<IUserProfile>();
  const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] = useBoolean(false);
  const [fieldGroupToEdit, setFieldGroupToEdit] = useState<string>();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const displayMode = props.displayMode;
  const currentTab = location.hash.substring(1) || "about-me";
  const saving = props.saving;

  useEffect(() => {
    (async () => {
      const userProfile = currentPath.startsWith("/my-profile") ? await getUserProfile() : props.userProfile;
      setUserProfile(userProfile);
    })();
  }, [props.userProfile, currentPath, saving]);

  let canEdit;
  // Check if current user has access to edit the visible profile
  if (displayMode === "preview") {
    canEdit = false;
  } else if (userProfile?.AccountName?.includes("#EXT#")) {
    // This check might be unredundant because there shouldn't be EXT accounts
    // But at least we developers have EXT accounts so for testing purposes this is needed
    canEdit = !!accounts && accounts[0].localAccountId === userProfile?.id;
  } else {
    canEdit = !!accounts && accounts[0].username === userProfile?.AccountName;
  }

  const onTabClick = (item?: PivotItem, ev?: MouseEvent<HTMLElement>) => {
    if (item) {
      const newActiveTab = item.props.itemKey;
      const newPath = `${location.pathname}#${newActiveTab}`;
      navigate(newPath);
    }
  };

  const saveProfile = async (updatedProfile: IUserProfile) => {
    if (updatedProfile) {
      props.setSaving?.(true);
      try {
        await saveUserProfile(updatedProfile);
      } catch (error) {
        console.error("Failed to save user profile:", error);
        // Optionally, show an error message to the user
      } finally {
        props.setSaving?.(false);
      }
    }
  };
  let userProfileTabs = userProfile
    ? [
        {
          headerText: t("AboutMe"),
          tabComponent: <TabMyProfile userProfileData={userProfile} userProfileSetter={setUserProfile} setFieldGroupToEdit={setFieldGroupToEdit} showPopup={showPopup} canEdit={canEdit} displayMode={displayMode} />,
          className: "profile-tab--content",
          roles: ["any"],
          key: "about",
        },
        {
          headerText: t("WorkExperience"),
          tabComponent: <TabWorkExperience userProfileData={userProfile} userProfileSetter={setUserProfile} setFieldGroupToEdit={setFieldGroupToEdit} showPopup={showPopup} canEdit={canEdit} displayMode={displayMode} />,
          className: "profile-tab--content",
          roles: ["any"],
          key: "workexperience",
        },
        {
          headerText: displayMode === "preview" ? t("Education") : t("EducationTraining"),
          tabComponent: <TabEducationTrainings userProfileData={userProfile} userProfileSetter={setUserProfile} setFieldGroupToEdit={setFieldGroupToEdit} showPopup={showPopup} canEdit={canEdit} displayMode={displayMode} />,
          className: "profile-tab--content",
          roles: ["superuser", "admin"],
          key: "education",
        },
        {
          headerText: t("Skills"),
          tabComponent: <TabSkills userProfileData={userProfile} userProfileSetter={setUserProfile} setFieldGroupToEdit={setFieldGroupToEdit} showPopup={showPopup} canEdit={canEdit} displayMode={displayMode} />,
          className: "profile-tab--content",
          roles: ["superuser", "admin"],
          key: "skills",
        },
        {
          headerText: t("MyInterests"),
          tabComponent: <TabMyInterests userProfileData={userProfile} userProfileSetter={setUserProfile} setFieldGroupToEdit={setFieldGroupToEdit} showPopup={showPopup} canEdit={canEdit} displayMode={displayMode} />,
          className: "profile-tab--content",
          roles: ["any"],
          key: "interests",
        },
      ]
    : [];

  const userInterests = userProfile?.Interests?.Keywords;
  const userInterestsDescription = userProfile?.Interests?.Description;

  if (!userInterests && !userInterestsDescription && (displayMode === "preview" || displayMode === "user-profile")) {
    userProfileTabs = userProfileTabs.filter((tab) => tab.key !== "interests");
  }

  const userRecentProjects = userProfile?.RecentProjects;
  const userWorkBio = userProfile?.WorkBio;

  if (!userRecentProjects && !userWorkBio && (displayMode === "preview" || displayMode === "user-profile")) {
    userProfileTabs = userProfileTabs.filter((tab) => tab.key !== "workexperience");
  }

  const isRoleAllowed = (roles: string[], displayMode: string, userRole: string[]) => {
    if (displayMode === "my-profile") {
      return true;
    }
    const isAdminOrSuperuser = userRole.includes("admin") || userRole.includes("superuser");
    if (isAdminOrSuperuser) {
      return true;
    }

    // In preview mode, and user is not an admin or superuser, check if the user's roles match any of the tab's roles or if the tab is for 'any' role.
    return roles.some((role) => role === "any" || userRole.includes(role));
  };

  return (
    <>
      {userProfile && (
        <div className={`user-profile-details display-mode-${displayMode} ${canEdit && !userProfile.LinkedInDataRetrievalConsent ? "linkedin-disconnected" : ""}`}>
          {isPopupVisible && <UserProfileEdit userProfile={userProfile} userProfileSetter={setUserProfile} showPopup={showPopup} hidePopup={hidePopup} fieldGroupToEdit={fieldGroupToEdit} saveProfile={saveProfile} saving={saving || false} />}
          <Pivot className="profile-tabs-container" selectedKey={currentTab} onLinkClick={onTabClick}>
            {userProfileTabs
              .filter((tab) => isRoleAllowed(tab.roles, displayMode, userRole))
              .map((tab, index) => (
                <PivotItem headerText={tab.headerText} className={tab.className} itemKey={tab.key} key={index}>
                  {tab.tabComponent}
                </PivotItem>
              ))}
          </Pivot>
          {canEdit && !userProfile.LinkedInDataRetrievalConsent && (
            <DeliwiButton
              className="button-style--secondary button-size--medium connect-linkedin"
              value="personal-informatino"
              onClick={() => {
                setFieldGroupToEdit("connect-linkedin");
                showPopup();
              }}
            >
              <IconLink />
              Linkedin
            </DeliwiButton>
          )}
        </div>
      )}
    </>
  );
};
