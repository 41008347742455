import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconLinkedIn = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.16667 24H21.8333C23.3061 24 24.5 22.8061 24.5 21.3333V2.66667C24.5 1.19391 23.3061 0 21.8333 0H3.16667C1.69391 0 0.5 1.19391 0.5 2.66667V21.3333C0.5 22.8061 1.69391 24 3.16667 24Z" fill="#020404" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.1663 20.6693H17.6049V14.6033C17.6049 12.9402 16.9729 12.0108 15.6566 12.0108C14.2245 12.0108 13.4764 12.978 13.4764 14.6033V20.6693H10.0441V9.11372H13.4764V10.6702C13.4764 10.6702 14.5084 8.76068 16.9605 8.76068C19.4116 8.76068 21.1663 10.2574 21.1663 13.353V20.6693ZM5.94946 7.60061C4.78036 7.60061 3.83301 6.64582 3.83301 5.46827C3.83301 4.29072 4.78036 3.33594 5.94946 3.33594C7.11855 3.33594 8.06534 4.29072 8.06534 5.46827C8.06534 6.64582 7.11855 7.60061 5.94946 7.60061ZM4.17719 20.6693H7.75614V9.11372H4.17719V20.6693Z" fill="white" />
      </svg>
    </span>
  );
};
