import React, { useState, useEffect, useRef, FormEvent, KeyboardEvent } from "react";
import { t } from "i18next";
import { TextField } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { DeliwiButton } from "components/DeliwiButton";
import { IconPaperplane } from "components/Icons/IconPaperplane";
import { IconMicrophone } from "components/Icons/IconMicrophone";
import { IconDismiss } from "components/Icons/IconDismiss";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

export interface ISearchFieldProps {
  search: (searchString: string) => void;
}

export const SearchField = (props: ISearchFieldProps) => {
  const { search } = props;
  const [searchString, setSearchString] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  // const [isSearchTriggeredBySpeech, setIsSearchTriggeredBySpeech] = useState<boolean>(false);
  const [multiline, { toggle: toggleMultiline }] = useBoolean(false);

  const { transcript, finalTranscript, listening, isMicrophoneAvailable, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

  useEffect(() => {
    // This useEffect now only updates searchString without triggering search
    if (!listening && finalTranscript) {
      setSearchString(finalTranscript);
      // Removed the setIsSearchTriggeredBySpeech state and related logic
    }
  }, [finalTranscript, listening]);

  const handleStartListening = () => {
    SpeechRecognition.startListening({ continuous: false, language: "en-US" });
    resetTranscript();
    setSearchString("");
  };

  const handleStopListening = () => {
    SpeechRecognition.stopListening();
  };

  const onChangedSearchTerm = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setSearchString(newValue || "");

    // Toggle multiline based on the length of the text
    const newMultiline = (newValue || "").length > 90;
    if (newMultiline !== multiline) {
      toggleMultiline();
    }
  };
  const onEnter = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      search(searchString);
    }
  };

  const handleFocus = () => setIsActive(true);
  const handleBlur = () => setIsActive(false);

  return (
    <>
      <div className={`search-field ${isActive ? "is-active" : ""}`}>
        <TextField
          className="search-field__input"
          placeholder={t("SearchFieldPlaceholder")}
          onChange={onChangedSearchTerm}
          onKeyDown={onEnter}
          value={searchString}
          onFocus={handleFocus}
          onBlur={handleBlur}
          multiline={multiline}
          autoAdjustHeight // Automatically adjust the height based on content
        />
        <div className="search-field__buttons">
          <DeliwiButton
            className="search-field__button"
            onClick={() => {
              search(searchString);
              // setSearchString("");
              if (multiline) {
                toggleMultiline(); // This assumes toggleMultiline is a function that toggles the boolean state
              }
            }}
            disabled={!searchString.trim()}
          >
            <IconPaperplane className={`icon-size--20 ${searchString ? "search-field__button--green" : ""}`} />
          </DeliwiButton>
          {browserSupportsSpeechRecognition &&
            isMicrophoneAvailable &&
            (listening ? (
              <DeliwiButton className={`search-field__button search-field__button--mic listening`} onClick={handleStopListening}>
                <IconDismiss className="icon-size--20" />
              </DeliwiButton>
            ) : (
              <DeliwiButton className="search-field__button search-field__button--mic" onClick={handleStartListening}>
                <IconMicrophone className="icon-size--20" />
              </DeliwiButton>
            ))}
        </div>
      </div>
    </>
  );
};
