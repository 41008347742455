import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconPlus = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.74986 0C9.12955 0 9.4434 0.282013 9.49314 0.648078L9.50001 0.749848L9.50121 8H16.7543C17.1685 8 17.5043 8.33579 17.5043 8.75C17.5043 9.1297 17.2221 9.44349 16.8561 9.49315L16.7543 9.5H9.50121L9.50326 16.7491C9.50334 17.1633 9.16762 17.4993 8.75341 17.4993C8.37371 17.4993 8.05986 17.2173 8.01013 16.8512L8.00326 16.7494L8.00121 9.5H0.752213C0.337999 9.5 0.00221252 9.16421 0.00221252 8.75C0.00221252 8.3703 0.284367 8.05651 0.650442 8.00685L0.752213 8H8.00121L8.00001 0.750152C7.99992 0.335939 8.33564 0 8.74986 0Z" fill="white" />
      </svg>
    </span>
  );
};
