import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconSpeed = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.0625 1.92799C7.51624 2.10395 6.10322 2.70988 4.94701 3.62145L5.97533 4.6495C6.34149 5.01557 6.34156 5.60916 5.9755 5.97533C5.60942 6.34149 5.01584 6.34156 4.64967 5.9755L3.61804 4.94413C2.75856 6.02411 2.17224 7.3271 1.96152 8.75H4.0625C4.58026 8.75 5 9.16974 5 9.6875C5 10.2053 4.58026 10.625 4.0625 10.625H1.90653C2.091 12.6632 3.07145 14.5535 4.48576 15.8782C4.86365 16.2322 4.88304 16.8255 4.52909 17.2034C4.17513 17.5813 3.58185 17.6006 3.20396 17.2468C1.27774 15.4425 0 12.7824 0 9.92909C0 4.43848 4.48411 0 10 0C15.5159 0 20 4.43848 20 9.92909C20 12.7711 18.7769 15.4344 16.842 17.2468C16.4641 17.6006 15.8709 17.5813 15.517 17.2034C15.163 16.8255 15.1824 16.2322 15.5603 15.8782C16.9675 14.5601 17.9172 12.672 18.0949 10.625H15.9375C15.4197 10.625 15 10.2053 15 9.6875C15 9.16972 15.4197 8.75 15.9375 8.75H18.0385C17.5079 5.16697 14.5956 2.34426 10.9375 1.92799V3.4375C10.9375 3.95526 10.5178 4.375 10 4.375C9.48224 4.375 9.0625 3.95526 9.0625 3.4375V1.92799ZM14.311 4.75935C14.5451 4.57364 14.878 4.57967 15.1052 4.77374C15.3325 4.96781 15.3905 5.29562 15.2437 5.55595L15.1052 5.80114C15.0169 5.95733 14.8903 6.18097 14.7369 6.45096C14.4301 6.99084 14.0162 7.71646 13.5882 8.45879C13.1605 9.2006 12.7174 9.96134 12.3522 10.5706C12.1699 10.875 12.0052 11.1445 11.8709 11.3562C11.7459 11.5531 11.6233 11.7384 11.5333 11.8438C10.8609 12.6312 9.67734 12.7245 8.88985 12.052C8.10237 11.3795 8.00914 10.196 8.6816 9.40856C8.7716 9.30316 8.93542 9.15309 9.1103 8.99882C9.29834 8.83295 9.53877 8.62814 9.81081 8.40039C10.3554 7.94441 11.0374 7.38757 11.7031 6.84897C12.3693 6.31 13.0211 5.78765 13.5064 5.40016C13.749 5.20639 13.95 5.04626 14.0905 4.93455L14.311 4.75935Z"
          fill="#08757A"
        />
      </svg>
    </span>
  );
};
