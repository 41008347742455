import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconPremiumPerson = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Premium Person">
          <path
            id="Shape"
            d="M25.9238 4.51367C26.2621 4.51367 26.5765 4.6758 26.7731 4.94405L26.8499 5.06535L31.4326 13.5303L31.4858 13.6608L31.5028 13.7167L31.531 13.8602L31.5392 13.9921C31.5392 14.089 31.5261 14.1829 31.5015 14.272L31.435 14.449L31.3823 14.5454C31.3751 14.557 31.3677 14.5685 31.36 14.5798C31.3494 14.5955 31.3381 14.6113 31.3266 14.6265L31.3256 14.6278C31.31 14.6484 31.2936 14.6686 31.2763 14.6882L31.3034 14.6531L29.5148 16.6845C28.9204 16.2523 28.2255 15.9501 27.4717 15.8196L28.1538 15.0452H23.9767L18.4811 29.1193C18.3187 29.5352 17.9211 29.7895 17.5001 29.7896L17.4961 29.7896C17.4528 29.7895 17.4093 29.7866 17.3657 29.781L17.3583 29.7805L17.3518 29.7791C17.3033 29.7722 17.2549 29.7618 17.2068 29.7478L17.1926 29.7447C17.1081 29.7205 17.0259 29.6846 16.9489 29.638L16.936 29.6275C16.8698 29.5877 16.808 29.5392 16.7519 29.4825L3.70808 14.6734L3.67123 14.6275L3.61497 14.5453C3.53706 14.4196 3.48498 14.2761 3.466 14.1224L3.45801 13.9921L3.46201 13.8996L3.48057 13.775C3.48924 13.7329 3.50034 13.6926 3.5137 13.6532L3.54574 13.5711L3.58506 13.4906L8.14726 5.06535C8.30831 4.76793 8.60061 4.56862 8.93013 4.52343L9.07335 4.51367H25.9238ZM21.7155 15.0452H13.2846L17.4986 25.8393L17.5001 25.8356V25.8408L21.7155 15.0452ZM11.0253 15.0452H6.84074L14.3546 23.5744L11.0253 15.0452ZM13.2467 6.61998H9.69963L6.27625 12.9389H11.2247L13.2467 6.61998ZM19.5375 6.61998H15.4583L13.4349 12.9389H21.5582L19.5375 6.61998ZM25.2961 6.61998H21.7492L23.7712 12.9389H28.7169L25.2961 6.61998ZM30.1379 20.662C30.1379 22.6008 28.5662 24.1725 26.6274 24.1725C24.6886 24.1725 23.1169 22.6008 23.1169 20.662C23.1169 18.7232 24.6886 17.1515 26.6274 17.1515C28.5662 17.1515 30.1379 18.7232 30.1379 20.662ZM32.9463 28.2096C32.9463 30.3953 31.1409 32.5977 26.6274 32.5978C22.1139 32.5978 20.3085 30.4035 20.3085 28.2096V28.065C20.3085 26.6902 21.423 25.5768 22.7977 25.5768H30.457C31.8318 25.5768 32.9463 26.6902 32.9463 28.065V28.2096Z"
            fill="#020404"
          />
        </g>
      </svg>
    </span>
  );
};
