import { FocusTrapZone, Layer, Overlay, Popup } from "@fluentui/react";
import { IUserProfile } from "../../interfaces/IUserProfile";
import "../../assets/styles/components/Forms.scss";
import { UserProfilePictureEdit } from "./UserEditForms/UserProfilePictureEdit";

export interface IUserProfilePictureEditProps {
  userProfile: IUserProfile;

  hidePopup(): void;

  showPopup(): void;

  updatedProfilePictureSetter(data: string | undefined): void;

  updatedProfilePicture: string | undefined;
}

export const UserProfileEditPicture = (props: IUserProfilePictureEditProps) => {
  return (
    <Layer eventBubblingEnabled={true}>
      <Popup className="edit-dialog--root" role="dialog" aria-modal="true" onDismiss={props.hidePopup} enableAriaHiddenSiblings={true}>
        <Overlay onClick={props.hidePopup} />
        <FocusTrapZone>
          <div role="document" className="edit-dialog edit-dialog--sm">
            <UserProfilePictureEdit userProfileData={props.userProfile} showPopup={props.showPopup} hidePopup={props.hidePopup} updatedProfilePictureSetter={props.updatedProfilePictureSetter} updatedProfilePicture={props.updatedProfilePicture} />
          </div>
        </FocusTrapZone>
      </Popup>
    </Layer>
  );
};
