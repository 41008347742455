import { ISkill, IUserProfileTab } from "../../../interfaces/IUserProfile";
import { t } from "i18next";
import { DeliwiButton } from "components/DeliwiButton";
import { IconStar } from "../../Icons/IconStar";
import { IconAddSmall } from "../../Icons/IconAddSmall";
import { IconEdit } from "../../Icons/IconEdit";
import { HelpDialog } from "components/HelpDialog";
import { IconHatGraduation } from "../../Icons/IconHatGraduation";
import { IconPremiumPerson } from "../../Icons/IconPremiumPerson";
import { IconLinkedIn } from "../../Icons/IconLinkedIn";

export interface IGroupedSkillArray {
  [key: string]: string[];
}

export const TabSkills = (props: IUserProfileTab) => {
  const shadowUserProfile = props.userProfileData;
  const canEdit = props.canEdit;
  const displayMode = props.displayMode;

  const userSkills = shadowUserProfile?.Skills;

  const groupedSkills = !!userSkills
    ? userSkills.reduce((group: { [key: string]: ISkill[] }, item) => {
        if (!group[item.Level]) {
          group[item.Level] = [];
        }
        group[item.Level].push(item);
        return group;
      }, {})
    : undefined;

  const userLanguageSkills = shadowUserProfile?.LanguageSkills;
  const groupedLanguageSkills = !!userLanguageSkills
    ? userLanguageSkills.reduce((group: { [key: string]: ISkill[] }, item) => {
        if (!group[item.Level]) {
          group[item.Level] = [];
        }
        group[item.Level].push(item);
        return group;
      }, {})
    : undefined;

  return (
    <>
      {displayMode !== "preview" && (
        <div className="user-profile-tab--heading--container mb-40 ml-24">
          <IconPremiumPerson />
          <h2 className="user-profile-tab--heading text-style--Title2 text-style--semibold">{t("Skills")}</h2>
        </div>
      )}
      <div className="field-group--container">
        <div className="field-group--header">
          <h3 className="text-style--Subtitle1 text-color--brand10">{t("Skills")}</h3>
          {canEdit && (
            <div className="side-by-side-buttons--wrapper">
              {!shadowUserProfile.LinkedInDataRetrievalConsent && <IconLinkedIn className="icon--linkedin" />}
              <DeliwiButton
                className="button-style--outline button-size--medium"
                value="skills"
                onClick={() => {
                  props.setFieldGroupToEdit("skills");
                  props.showPopup();
                }}
              >
                <IconEdit />
                {t("Edit")}
              </DeliwiButton>
            </div>
          )}
        </div>
        <div className="skill-groups">
          <div className="skill-group special-expertise">
            <div className={`skill-group--label ${!groupedSkills || !groupedSkills[3] || groupedSkills[3].length === 0 ? "skill-group--label-noskills" : ""}`}>
              <div className="skill-rating">
                <IconStar className="icon-star" />
                <IconStar className="icon-star" />
                <IconStar className="icon-star" />
              </div>
              <h4>{t("SpecialExpertise")}</h4>
            </div>
            <div className="skill-group--items">
              {groupedSkills && groupedSkills[3] && groupedSkills[3].length > 0
                ? groupedSkills[3].map((skill, index) => (
                    <span key={index} className="skill-item special-expertise">
                      {skill.Name}
                    </span>
                  ))
                : canEdit && (
                    <div className="empty-skill-placeholder">
                      <p className="text-style--Body2 text-color--brand10">{t("UserProfile.Empty.Skills.Expert")}</p>
                      <DeliwiButton
                        onClick={() => {
                          props.setFieldGroupToEdit("skills");
                          props.showPopup();
                        }}
                        className="button-style--candy button-size--large"
                      >
                        <IconAddSmall className="icon-size--10" />
                        {t("AddSkills")}
                      </DeliwiButton>
                    </div>
                  )}
            </div>
          </div>

          <div className="skill-group professional">
            <div className={`skill-group--label ${!groupedSkills || !groupedSkills[2] || groupedSkills[2].length === 0 ? "skill-group--label-noskills" : ""}`}>
              <div className="skill-rating">
                <IconStar className="icon-star" />
                <IconStar className="icon-star" />
              </div>
              <h4>{t("Professional")}</h4>
            </div>
            <div className="skill-group--items">
              {groupedSkills && groupedSkills[2] && groupedSkills[2].length > 0
                ? groupedSkills[2].map((skill, index) => (
                    <span key={index} className="skill-item professional">
                      {skill.Name}
                    </span>
                  ))
                : canEdit && (
                    <div className="empty-skill-placeholder">
                      <p className="text-style--Body2 text-color--brand10">{t("UserProfile.Empty.Skills.Professional")}</p>
                      <DeliwiButton
                        onClick={() => {
                          props.setFieldGroupToEdit("skills");
                          props.showPopup();
                        }}
                        className="button-style--candy button-size--large"
                      >
                        <IconAddSmall className="icon-size--10" />
                        {t("AddSkills")}
                      </DeliwiButton>
                    </div>
                  )}
            </div>
          </div>

          <div className="skill-group basic">
            <div className={`skill-group--label ${!groupedSkills || !groupedSkills[1] || groupedSkills[1].length === 0 ? "skill-group--label-noskills" : ""}`}>
              <div className="skill-rating">
                <IconStar className="icon-star" />
              </div>
              <h4>{t("Basic")}</h4>
            </div>
            <div className="skill-group--items">
              {groupedSkills && groupedSkills[1] && groupedSkills[1].length > 0
                ? groupedSkills[1].map((skill, index) => (
                    <span key={index} className="skill-item basic">
                      {skill.Name}
                    </span>
                  ))
                : canEdit && (
                    <div className="empty-skill-placeholder">
                      <p className="text-style--Body2 text-color--brand10">{t("UserProfile.Empty.Skills.Basic")}</p>
                      <DeliwiButton
                        onClick={() => {
                          props.setFieldGroupToEdit("skills");
                          props.showPopup();
                        }}
                        className="button-style--candy button-size--large"
                      >
                        <IconAddSmall className="icon-size--10" />
                        {t("AddSkills")}
                      </DeliwiButton>
                    </div>
                  )}
            </div>
          </div>

          {groupedSkills && groupedSkills[0] && canEdit && (
            <>
              <div className="skill-group non-rated">
                <div className="skill-group--label skill-group--label-noskills">
                  <HelpDialog dialogContent={t("Help.NonRatedSkills.Content")} title={t("Help.NonRatedSkills.Title")}>
                    <h4>{t("NonRatedSkills")}</h4>
                  </HelpDialog>
                </div>
                <div className="non-ratedskills--wrapper">
                  <div className="skill-group--items">
                    {groupedSkills[0].map((skill, index) => (
                      <span key={index} className="skill-item non-rated">
                        {skill.Name}
                      </span>
                    ))}
                  </div>
                  {canEdit && (
                    <DeliwiButton
                      onClick={() => {
                        props.setFieldGroupToEdit("skills");
                        props.showPopup();
                      }}
                      className="button-style--candy button-size--medium"
                    >
                      <IconAddSmall className="icon-size--10" />
                      {t("RateSkills")}
                    </DeliwiButton>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="field-group--container">
        <div className="field-group--header">
          <h3 className="text-style--Subtitle1 text-color--brand10">{t("LanguageSkills")}</h3>
          {canEdit && (
            <div className="side-by-side-buttons--wrapper">
              {!shadowUserProfile.LinkedInDataRetrievalConsent && <IconLinkedIn className="icon--linkedin" />}
              <DeliwiButton
                className="button-style--outline button-size--medium"
                value="language-skills"
                onClick={() => {
                  props.setFieldGroupToEdit("language-skills");
                  props.showPopup();
                }}
              >
                <IconEdit />
                {t("Edit")}
              </DeliwiButton>
            </div>
          )}
        </div>
        <div className="language-skills--container">
          <div className="skill-group special-expertise">
            <div className={`skill-group--label ${!groupedLanguageSkills || !groupedLanguageSkills[3] || groupedLanguageSkills[3].length === 0 ? "skill-group--label-noskills" : ""}`}>
              <div className="skill-rating">
                <IconStar className="icon-star" />
                <IconStar className="icon-star" />
                <IconStar className="icon-star" />
              </div>
              <h4>{t("LanguageSkillLevel3")}</h4>
            </div>
            <div className="skill-group--items">
              {groupedLanguageSkills && groupedLanguageSkills[3] && groupedLanguageSkills[3].length > 0
                ? groupedLanguageSkills[3].map((skill, index) => (
                    <span key={index} className="skill-item language-native">
                      {skill.Name}
                    </span>
                  ))
                : canEdit && (
                    <div className="empty-language-placeholder">
                      <DeliwiButton
                        onClick={() => {
                          props.setFieldGroupToEdit("language-skills");
                          props.showPopup();
                        }}
                        className="button-style--candy button-size--medium"
                      >
                        <IconAddSmall className="icon-size--10" />
                        {t("AddSkills")}
                      </DeliwiButton>
                    </div>
                  )}
            </div>
          </div>

          <div className="skill-group professional">
            <div className={`skill-group--label ${!groupedLanguageSkills || !groupedLanguageSkills[2] || groupedLanguageSkills[2].length === 0 ? "skill-group--label-noskills" : ""}`}>
              <div className="skill-rating">
                <IconStar className="icon-star" />
                <IconStar className="icon-star" />
              </div>
              <h4>{t("LanguageSkillLevel2")}</h4>
            </div>
            <div className="skill-group--items">
              {groupedLanguageSkills && groupedLanguageSkills[2] && groupedLanguageSkills[2].length > 0
                ? groupedLanguageSkills[2].map((skill, index) => (
                    <span key={index} className="skill-item language-professional">
                      {skill.Name}
                    </span>
                  ))
                : canEdit && (
                    <div className="empty-language-placeholder">
                      <DeliwiButton
                        onClick={() => {
                          props.setFieldGroupToEdit("language-skills");
                          props.showPopup();
                        }}
                        className="button-style--candy button-size--medium"
                      >
                        <IconAddSmall className="icon-size--10" />
                        {t("AddSkills")}
                      </DeliwiButton>
                    </div>
                  )}
            </div>
          </div>

          <div className="skill-group basic">
            <div className={`skill-group--label ${!groupedLanguageSkills || !groupedLanguageSkills[1] || groupedLanguageSkills[1].length === 0 ? "skill-group--label-noskills" : ""}`}>
              <div className="skill-rating">
                <IconStar className="icon-star" />
              </div>
              <h4>{t("LanguageSkillLevel1")}</h4>
            </div>
            <div className="skill-group--items">
              {groupedLanguageSkills && groupedLanguageSkills[1] && groupedLanguageSkills[1].length > 0
                ? groupedLanguageSkills[1].map((skill, index) => (
                    <span key={index} className="skill-item language-basics">
                      {skill.Name}
                    </span>
                  ))
                : canEdit && (
                    <div className="empty-language-placeholder">
                      <DeliwiButton
                        onClick={() => {
                          props.setFieldGroupToEdit("language-skills");
                          props.showPopup();
                        }}
                        className="button-style--candy button-size--medium"
                      >
                        <IconAddSmall className="icon-size--10" />
                        {t("AddSkills")}
                      </DeliwiButton>
                    </div>
                  )}
            </div>
          </div>
        </div>

        {groupedLanguageSkills && groupedLanguageSkills[0] && canEdit && (
          <>
            <div className="skill-group non-rated mt-24">
              <div className="skill-group--label skill-group--label-noskills">
                <HelpDialog dialogContent={t("Help.NonRatedSkills.Content")} title={t("Help.NonRatedSkills.Title")}>
                  <h4>{t("NonRatedSkills")}</h4>
                </HelpDialog>
              </div>
              <div className="non-ratedskills--wrapper non-ratedskills--wrapper-language">
                <div className="skill-group--items">
                  {groupedLanguageSkills[0].map((skill, index) => (
                    <span key={index} className="skill-item non-rated">
                      {skill.Name}
                    </span>
                  ))}
                </div>
                {canEdit && (
                  <DeliwiButton
                    onClick={() => {
                      props.setFieldGroupToEdit("language-skills");
                      props.showPopup();
                    }}
                    className="button-style--candy button-size--medium"
                  >
                    <IconAddSmall className="icon-size--10" />
                    {t("RateLanguageSkills")}
                  </DeliwiButton>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
