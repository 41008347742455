import { IUserProfileTab } from "../../../interfaces/IUserProfile";
import { t } from "i18next";
import { DeliwiButton } from "components/DeliwiButton";
import { IconAddSmall } from "../../Icons/IconAddSmall";
import { IconEdit } from "../../Icons/IconEdit";
import { ReadMoreWeb } from "react-shorten";
import textWithLineBreaks from "functions/TextWithLineBreaks";
import { IconPremiumPerson } from "../../Icons/IconPremiumPerson";
import { IconHeart } from "../../Icons/IconHeart";

export const TabMyInterests = (props: IUserProfileTab) => {
  const shadowUserProfile = props.userProfileData;
  const canEdit = props.canEdit;
  const displayMode = props.displayMode;

  const userInterests = shadowUserProfile?.Interests?.Keywords;
  const userInterestsDescription = shadowUserProfile?.Interests?.Description;

  return (
    <>
      {displayMode !== "preview" && (
        <div className="user-profile-tab--heading--container mb-40 ml-24">
          <IconHeart />
          <h2 className="user-profile-tab--heading text-style--Title2 text-style--semibold">{t("MyInterests")}</h2>
        </div>
      )}
      <div className="field-group--container">
        <div className="field-group--header mb-24">
          <h3 className="text-style--Subtitle1 text-color--brand10">{t("MyInterestsAndHobbies")}</h3>
          {canEdit && (
            <DeliwiButton
              className="button-style--outline button-size--medium"
              value="my-interests"
              onClick={() => {
                props.setFieldGroupToEdit("my-interests");
                props.showPopup();
              }}
            >
              <IconEdit />
              {t("Edit")}
            </DeliwiButton>
          )}
        </div>
        <div className="field-group--item my-interests--container">
          {!!userInterestsDescription ? (
            <>
              <div className="field-group--item--wrapper mb-32">
                <h4 className="field-label mb-8">{t("MyInterestsShortDescriptionLabel")}</h4>
                <div className="long-text--container">
                  <div className="field-value text-style--Body2 text-color--brand10">
                    <ReadMoreWeb truncate={400} showMoreText={t("ReadMore")} showLessText={t("ReadLess")} className="read-more-btn">
                      {textWithLineBreaks(userInterestsDescription)}
                    </ReadMoreWeb>
                  </div>
                </div>
              </div>
            </>
          ) : displayMode !== "preview" && canEdit ? (
            <>
              <h3 className="text-style--Body1 fw-600 text-color--grey46 mb-16">{t("Short description of my interests and hobbies")}</h3>
              <div className="empty-interest-placeholder mb-24">
                <div>
                  <h3 className="text-style--Subtitle1 mb-12">{t("UserProfile.Empty.Interests.Title")}</h3>
                  <p className="text-style--Body2 empty-content-placeholder--description">{t("UserProfile.Empty.Interests.Content")}</p>
                </div>
                <DeliwiButton
                  onClick={() => {
                    props.setFieldGroupToEdit("my-interests");
                    props.showPopup();
                  }}
                  className="button-style--candy button-size--large"
                >
                  <IconAddSmall className="icon-size--10" />
                  {t("UserProfile.Empty.Interests.Button")}
                </DeliwiButton>
              </div>
            </>
          ) : null}
        </div>
        <div className="field-group--item my-interests--container">
          {!!userInterests && userInterests.length > 0 ? (
            <>
              <h3 className="text-style--Body1 fw-600 text-color--brand80 mb-16">{t("InterestsHobbiesKeywords")}</h3>
              <div className="my-interests--list--wrapper">
                <div className="my-interests--list--items">
                  {!!userInterests &&
                    userInterests.map((interest, index) => {
                      return (
                        <span key={index} className="my-interests--list--item">
                          {interest}
                        </span>
                      );
                    })}
                </div>
              </div>
            </>
          ) : displayMode !== "preview" && canEdit ? (
            <>
              <h3 className="text-style--Body1 fw-600 text-color--grey46 mb-16">{t("InterestsHobbiesKeywords")}</h3>
              <div className="empty-interest-placeholder">
                <div>
                  <h3 className="text-style--Subtitle1 mb-12">{t("UserProfile.Empty.InterestsKeywords.Title")}</h3>
                  <p className="text-style--Body2 empty-content-placeholder--description">{t("UserProfile.Empty.InterestsKeywords.Content")}</p>
                </div>
                <DeliwiButton
                  onClick={() => {
                    props.setFieldGroupToEdit("my-interests");
                    props.showPopup();
                  }}
                  className="button-style--candy button-size--large"
                >
                  <IconAddSmall className="icon-size--10" />
                  {t("UserProfile.Empty.InterestsKeywords.Button")}
                </DeliwiButton>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
