import { IUserProfileTab } from "../../../interfaces/IUserProfile";
import { t } from "i18next";
import { Label } from "@fluentui/react";
import { WorkLoadMeter } from "../../WorkLoadMeter";
import { IconEdit } from "../../Icons/IconEdit";
import { ReadMoreWeb } from "react-shorten";
import { HelpDialog } from "../../HelpDialog";
import { DeliwiButton } from "components/DeliwiButton";
import { LabelWithTextField } from "components/LabelWithTextField";
import { IconAddSmall } from "../../Icons/IconAddSmall";
import { useUserRole } from "contexts/UserRoleContext";
import textWithLineBreaks from "functions/TextWithLineBreaks";
import { IconContactCard } from "../../Icons/IconContactCard";

export const TabMyProfile = (props: IUserProfileTab) => {
  const shadowUserProfile = props.userProfileData;
  const canEdit = props.canEdit;

  const firstName = shadowUserProfile?.FirstName || "";
  const lastName = shadowUserProfile?.LastName || "";
  const email = shadowUserProfile?.Email || "";
  const phone = shadowUserProfile?.PhoneNumber || "";
  const userLanguage = shadowUserProfile?.PreferredLanguage || "";

  const companyName = shadowUserProfile?.Company?.Name || "";
  const companyCity = shadowUserProfile?.Company?.Location?.City || "";
  const companyCountry = shadowUserProfile?.Company?.Location?.Country || "";
  const companyDepartment = shadowUserProfile?.Company?.Department || "";
  const workingSpaceLocation = shadowUserProfile?.Location?.City || "";
  const workingSpaceCountry = shadowUserProfile?.Location?.Country || "";
  const whatWouldYouLikeToDoIn3Years = shadowUserProfile?.WhatWouldYouLikeToDoIn3Years || "";
  const dreamPositionOrJobIn5Years = shadowUserProfile?.DreamPositionOrJobIn5Years || "";
  const workloadPrediction = shadowUserProfile?.WorkloadPrediction || 0;
  const jobDescription = shadowUserProfile?.JobDescription || "";
  const jobTitle = shadowUserProfile?.JobTitle || "";
  const supervisor = shadowUserProfile?.Supervisor || "";
  const displayMode = props.displayMode;
  const { userRole } = useUserRole();
  const canView = canEdit || userRole.includes("admin") || userRole.includes("superuser");

  return (
    <>
      {displayMode !== "preview" && (
        <div className="user-profile-tab--heading--container mb-40 ml-24">
          <IconContactCard />
          <h2 className="user-profile-tab--heading text-style--Title2 text-style--semibold">{t("AboutMe")}</h2>
        </div>
      )}
      <div className="field-group--container">
        <div className="field-group--header mb-32">
          <h3 className="text-style--Subtitle1 text-color--brand10">{t("PersonalInformation")}</h3>
          {canEdit && (
            <DeliwiButton
              className="button-style--outline button-size--medium"
              value="personal-informatino"
              onClick={() => {
                props.setFieldGroupToEdit("personal-information");
                props.showPopup();
              }}
            >
              <IconEdit />
              {t("Edit")}
            </DeliwiButton>
          )}
        </div>
        <div className="field-group--fields">
          {displayMode !== "preview" && displayMode !== "user-profile" && (
            <>
              <div className="field-wrapper">
                <LabelWithTextField label={t("First name")} value={firstName} canEdit={canEdit} />
              </div>

              <div className="field-wrapper">
                <LabelWithTextField label={t("Last name")} value={lastName} canEdit={canEdit} />
              </div>
            </>
          )}

          <div className={`field-wrapper ${displayMode === "preview" ? "full-width" : ""}`}>
            <LabelWithTextField label={t("Email")} value={email} canEdit={canEdit} />
          </div>

          <div className="field-wrapper">
            <LabelWithTextField label={t("PhoneNumber")} value={phone} canEdit={canEdit} />
          </div>

          <div className="field-wrapper">
            <LabelWithTextField label={t("NativeLanguage")} value={userLanguage} canEdit={canEdit} />
          </div>
        </div>
        {canEdit && (firstName === "" || lastName === "" || email === "" || phone === "" || userLanguage === "") && (
          <div className="empty-content-placeholder">
            <h3 className="text-style--Subtitle1 mb-16">{t("UserProfile.Empty.PersonalInformation.Title")}</h3>
            {/*<p className="text-style--Body2 empty-content-placeholder--description">{t("AddContent")}</p>*/}
            <DeliwiButton
              onClick={() => {
                props.setFieldGroupToEdit("personal-information");
                props.showPopup();
              }}
              className="button-style--candy button-size--large"
            >
              <IconAddSmall className="icon-size--10" />
              {t("UserProfile.Empty.PersonalInformation.Button")}
            </DeliwiButton>
          </div>
        )}
      </div>

      <div className="field-group--container">
        <div className="field-group--header mb-32">
          <h3 className="text-style--Subtitle1 text-color--brand10">{t("JobInformation")}</h3>
          {canEdit && (
            <DeliwiButton
              className="button-style--outline button-size--medium"
              value="company-information"
              onClick={() => {
                props.setFieldGroupToEdit("company-information");
                props.showPopup();
              }}
            >
              <IconEdit className="icon-edit" />
              {t("Edit")}
            </DeliwiButton>
          )}
        </div>
        <div className="field-group--fields">
          <div className="field-wrapper">
            <LabelWithTextField label={t("CompanyName")} value={companyName} canEdit={canEdit} />
          </div>

          <div className="field-wrapper">
            <LabelWithTextField label={t("CompanyCity")} value={companyCity} canEdit={canEdit} />
          </div>

          <div className="field-wrapper">
            <LabelWithTextField label={t("CompanyCountry")} value={companyCountry} canEdit={canEdit} />
          </div>

          <div className="field-wrapper">
            <LabelWithTextField label={t("CompanyDepartment")} value={companyDepartment} canEdit={canEdit} />
          </div>

          <div className="field-wrapper">
            <LabelWithTextField label={t("WorkingSpaceLocation")} value={workingSpaceLocation} canEdit={canEdit} />
          </div>

          <div className="field-wrapper">
            <LabelWithTextField label={t("WorkingSpaceCountry")} value={workingSpaceCountry} canEdit={canEdit} />
          </div>

          <div className="field-wrapper">
            <LabelWithTextField label={t("JobTitle")} value={jobTitle} canEdit={canEdit} />
          </div>

          <div className="field-wrapper">
            <LabelWithTextField label={t("Supervisor")} value={supervisor} canEdit={canEdit} />
          </div>
        </div>
        {canEdit && (companyName === "" || companyCity === "" || companyCountry === "" || companyDepartment === "" || workingSpaceLocation === "" || workingSpaceCountry === "" || jobTitle === "" || supervisor === "") && (
          <div className="empty-content-placeholder">
            <h3 className="text-style--Subtitle1 mb-16">{t("UserProfile.Empty.CompanyDetails.Title")}</h3>
            {/*<p className="text-style--Body2 empty-content-placeholder--description">{t("AddContent")}</p>*/}
            <DeliwiButton
              onClick={() => {
                props.setFieldGroupToEdit("company-information");
                props.showPopup();
              }}
              className="button-style--candy button-size--large"
            >
              <IconAddSmall className="icon-size--10" />
              {t("UserProfile.Empty.CompanyDetails.Button")}
            </DeliwiButton>
          </div>
        )}
      </div>

      <div className="field-group--container">
        <div className="field-group--header mb-32">
          {(displayMode === "preview" || displayMode === "user-profile") && !canView ? (
            <>
              <h3 className="text-style--Subtitle1 text-color--brand10">{t("CurrentWork")}</h3>
            </>
          ) : (
            <>
              <h3 className="text-style--Subtitle1 text-color--brand10">{t("FutureAndDevelopment")}</h3>
              {canEdit && (
                <DeliwiButton
                  className="button-style--outline button-size--medium"
                  value="future-development"
                  onClick={() => {
                    props.setFieldGroupToEdit("future-development");
                    props.showPopup();
                  }}
                >
                  <IconEdit />
                  {t("Edit")}
                </DeliwiButton>
              )}
            </>
          )}
        </div>
        <div className="field-group--fields">
          {(displayMode === "preview" || displayMode === "user-profile") && !canView ? (
            <>
              <div className="field-wrapper full-width">
                <LabelWithTextField label={t("JobDescription")} value={jobDescription || t("NotGiven")} canEdit={canEdit} />
              </div>
            </>
          ) : (
            <>
              <div className="field-wrapper full-width">
                <HelpDialog className="mb-0" dialogContent={t("Help.MyProfile.FutureDevelopment.WorkloadPrediction.Content")} title={t("Help.MyProfile.FutureDevelopment.WorkloadPrediction.Title")}>
                  <Label className="field-label">{t("WorkloadPredictionForNext3Months")}</Label>
                </HelpDialog>
                <WorkLoadMeter workLoad={workloadPrediction || 0} />
              </div>
              <div className="field-group--container-separator"></div>

              <div className="field-wrapper full-width">
                <Label className={`field-label ${whatWouldYouLikeToDoIn3Years === "" ? "field-label-empty" : ""}`}>{t("WhatWouldYouLikeToDoIn3Years")}</Label>
                {whatWouldYouLikeToDoIn3Years !== "" && (
                  <div className="long-text--container">
                    <div className="text-style--Body2">
                      <ReadMoreWeb truncate={400} showMoreText={t("ReadMore")} showLessText={t("ReadLess")} className="read-more-btn">
                        {textWithLineBreaks(whatWouldYouLikeToDoIn3Years || "")}
                      </ReadMoreWeb>
                    </div>
                  </div>
                )}
                {whatWouldYouLikeToDoIn3Years === "" && canEdit && (
                  <div className="empty-content-placeholder">
                    <h3 className="text-style--Subtitle1 mb-16">{t("UserProfile.Empty.threeYears.Title")}</h3>
                    <p className="text-style--Body2 text-color--brand10 mb-16 empty-content-placeholder--description">{t("UserProfile.Empty.threeYears.Content")}</p>
                    <DeliwiButton
                      onClick={() => {
                        props.setFieldGroupToEdit("future-development");
                        props.showPopup();
                      }}
                      className="button-style--candy button-size--large"
                    >
                      <IconAddSmall className="icon-size--10" />
                      {t("UserProfile.Empty.threeYears.Button")}
                    </DeliwiButton>
                  </div>
                )}
              </div>
              <div className="field-group--container-separator"></div>

              <div className="field-wrapper full-width">
                <Label className={`field-label ${dreamPositionOrJobIn5Years === "" ? "field-label-empty" : ""}`}>{t("DreamPositionOrJobIn5Years")}</Label>
                {dreamPositionOrJobIn5Years !== "" && (
                  <div className="long-text--container">
                    <div className="text-style--Body2">
                      <ReadMoreWeb truncate={400} showMoreText={t("ReadMore")} showLessText={t("ReadLess")} className="read-more-btn">
                        {textWithLineBreaks(dreamPositionOrJobIn5Years || "")}
                      </ReadMoreWeb>
                    </div>
                  </div>
                )}
                {dreamPositionOrJobIn5Years === "" && canEdit && (
                  <div className="empty-content-placeholder">
                    <h3 className="text-style--Subtitle1 mb-16">{t("UserProfile.Empty.fiveYears.Title")}</h3>
                    <p className="text-style--Body2 text-color--brand10 mb-16 empty-content-placeholder--description">{t("UserProfile.Empty.fiveYears.Content")}</p>
                    <DeliwiButton
                      onClick={() => {
                        props.setFieldGroupToEdit("future-development");
                        props.showPopup();
                      }}
                      className="button-style--candy button-size--large"
                    >
                      <IconAddSmall className="icon-size--10" />
                      {t("UserProfile.Empty.fiveYears.Button")}
                    </DeliwiButton>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
