import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconPieBtn = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.25 3.25C9.66421 3.25 10 3.58579 10 4V12H18C18.3797 12 18.6935 12.2822 18.7432 12.6482L18.75 12.75C18.75 17.7206 14.7206 21.25 9.75 21.25C4.77944 21.25 0.75 17.2206 0.75 12.25C0.75 7.27944 4.27944 3.25 9.25 3.25ZM8.5 4.787L8.29109 4.81073C4.6018 5.28155 2.25 8.43274 2.25 12.25C2.25 16.3921 5.60786 19.75 9.75 19.75C13.5673 19.75 16.7185 17.3982 17.1893 13.7089L17.212 13.5H9.25C8.8703 13.5 8.55651 13.2178 8.50685 12.8518L8.5 12.75V4.787ZM12.25 0.75C17.2206 0.75 21.25 4.77944 21.25 9.75C21.25 10.1642 20.9142 10.5 20.5 10.5H12.25C11.8358 10.5 11.5 10.1642 11.5 9.75V1.5C11.5 1.08579 11.8358 0.75 12.25 0.75ZM13 2.287V9H19.712L19.6893 8.79109C19.2585 5.41578 16.5842 2.74148 13.2089 2.31073L13 2.287Z" fill="white" />
      </svg>
    </span>
  );
};
