import { useState } from "react";
import { t } from "i18next";

import { AddUsers } from "components/ControlPanel/AddUsers";
import { ManageUsers } from "components/ControlPanel/ManageUsers";
import { UserReports } from "components/ControlPanel/UserReports";
import { DeliwiButton } from "components/DeliwiButton";
import { HelpDialog } from "components/HelpDialog";

export const TabUserManagement = () => {
  const [activeComponent, setActiveComponent] = useState<string>("AddUsers"); // Specify the type as string

  const handleButtonClick = (componentName: string) => {
    setActiveComponent(componentName);
  };

  return (
    <div className="admin-box admin-analyses mb-40">
      <HelpDialog className="mb-32" dialogContent={t("Help.UserAdministration.Content")} title={t("Help.UserAdministration.Title")}>
        <h3 className="text-style--Subtitle1">{t("UserAdministration")}</h3>
      </HelpDialog>
      <div className="admin-analyses__buttons mb-40">
        <DeliwiButton className={`${activeComponent === "AddUsers" ? "button-style--secondary button-size--medium" : "button-style--outline button-size--medium"}`} onClick={() => handleButtonClick("AddUsers")}>
          {t("AddUsers")}
        </DeliwiButton>
        <DeliwiButton className={`${activeComponent === "ManageUsers" ? "button-style--secondary button-size--medium" : "button-style--outline button-size--medium"}`} onClick={() => handleButtonClick("ManageUsers")}>
          {t("ManageUsers")}
        </DeliwiButton>
        <DeliwiButton className={`${activeComponent === "UserReports" ? "button-style--secondary button-size--medium" : "button-style--outline button-size--medium"}`} onClick={() => handleButtonClick("UserReports")}>
          {t("UserReports")}
        </DeliwiButton>
      </div>
      {activeComponent === "AddUsers" && <AddUsers />}
      {activeComponent === "ManageUsers" && <ManageUsers />}
      {activeComponent === "UserReports" && <UserReports />}
    </div>
  );
};
