import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetup } from "contexts/SetupContext";
import { InstallDeliwi } from "components/InstallDeliwi";

export const Setup = () => {
  const { isSetupComplete, isLoading } = useSetup();
  const navigate = useNavigate();

  console.log(isSetupComplete);

  useEffect(() => {
    if (!isLoading && isSetupComplete) {
      navigate("/");
    }
  }, [isLoading, isSetupComplete, navigate]);

  if (isLoading) {
    return (
      <div className="load-wrapper">
        <div className="deliwi-spinner"></div>
      </div>
    );
  }

  return !isSetupComplete ? <InstallDeliwi /> : null;
};
