import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { t } from "i18next";
import "assets/styles/components/QaItemFullDisplay.scss";
import { IQAContent } from "../../pages/HelpCenter";

export interface IUserProfilePreviewProps {
  content: IQAContent;

  closeDisplay(): void;
}

export const QaItemFullDisplay = (props: IUserProfilePreviewProps) => {
  const qaItem = props.content;

  const renderQaContent = () => {
    return { __html: qaItem.long_description || "" };
  };

  return (
    <div className="help-center--qa-item-full-display--container">
      <div className="qa-item-full-display--header">
        <h3 className="text-style--Title2">{qaItem.title}</h3>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-display" onClick={() => props.closeDisplay()}></ChromeCloseIcon>
        </div>
      </div>
      <div className="qa-item-full-display--content" dangerouslySetInnerHTML={renderQaContent()}></div>
    </div>
  );
};
