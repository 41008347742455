import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconAiSearch = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.6824 16.9425C22.0119 15.2432 20.3408 13.5447 18.6697 11.8461C18.6263 11.8014 18.5843 11.7548 18.5434 11.7114C20.8745 8.56029 20.1559 4.21184 17.2434 1.94404C14.4846 -0.203966 10.5079 0.0408163 8.03791 2.70234C7.88935 2.86294 7.74908 3.02872 7.61774 3.20033C7.86321 3.22817 8.04747 3.39395 8.13992 3.67435C8.29358 4.1406 8.44469 4.6088 8.60217 5.07376C8.60664 5.08736 8.61237 5.10031 8.61684 5.11391C9.45973 3.49562 11.1449 2.39928 13.0984 2.40252C15.8248 2.4077 18.0487 4.68327 18.0621 7.48208C18.0755 10.2802 15.8107 12.5798 13.0436 12.5785C11.3483 12.5778 9.84993 11.714 8.94583 10.3955C8.79345 10.6273 8.66721 10.8864 8.56966 11.1739C8.43066 11.5832 8.29804 11.9937 8.16287 12.4036C10.4913 14.7951 14.3264 15.2432 17.2358 13.0415C17.2766 13.0972 17.3091 13.1548 17.3537 13.2008C18.9942 14.869 20.6354 16.5358 22.2772 18.2033C22.3409 18.2681 22.4072 18.3309 22.4761 18.3898C22.8229 18.687 23.3553 18.6728 23.6862 18.36C24.1051 17.9631 24.1045 17.3712 23.6824 16.9425Z" fill="black" />
        <path d="M2.24987 8.08081C2.25242 8.29969 2.37866 8.4687 2.60756 8.54382C3.01179 8.67722 3.41857 8.80155 3.82152 8.93754C4.65613 9.21794 5.22422 9.77615 5.50731 10.6264C5.63291 11.0027 5.75533 11.3789 5.87966 11.7551C5.96382 12.0083 6.13087 12.1385 6.36869 12.1385C6.60268 12.1385 6.77292 12.0045 6.85644 11.7519C6.98396 11.3666 7.10765 10.98 7.23836 10.5953C7.52081 9.7645 8.08253 9.21665 8.89736 8.93625C9.29522 8.79896 9.69754 8.67722 10.0979 8.549C10.3485 8.46805 10.4824 8.30487 10.4843 8.07692C10.4869 7.84185 10.3498 7.66765 10.0986 7.58735C9.7071 7.46302 9.31307 7.34775 8.92478 7.21306C8.11249 6.93072 7.54695 6.38675 7.26578 5.55462C7.13571 5.16996 7.01074 4.78336 6.88386 4.39806C6.80097 4.1468 6.62882 4.00627 6.39993 4.00304C6.16594 3.9985 5.99124 4.13838 5.90452 4.3987C5.77764 4.77883 5.65459 5.16025 5.52516 5.53908C5.2459 6.35826 4.69757 6.9074 3.89548 7.1891C3.46575 7.33998 3.02836 7.46885 2.59608 7.61067C2.3742 7.6832 2.24796 7.85998 2.24987 8.08081Z" fill="black" />
        <path d="M0 13.6226C0.00127518 13.7683 0.086712 13.848 0.219968 13.8888C0.43356 13.9542 0.644602 14.0228 0.855643 14.0941C1.31917 14.2501 1.62904 14.5649 1.78333 15.0363C1.84901 15.2357 1.91468 15.4359 1.98354 15.6347C2.03008 15.7694 2.12508 15.8432 2.24877 15.8438C2.37502 15.8322 2.46555 15.7681 2.507 15.6379C2.57076 15.4423 2.63643 15.2481 2.69827 15.0518C2.85448 14.5539 3.18348 14.2352 3.66868 14.0759C3.8657 14.0112 4.06462 13.949 4.26228 13.8869C4.39043 13.8467 4.48543 13.7755 4.48543 13.6246C4.48543 13.4737 4.39872 13.3979 4.26802 13.3565C4.05124 13.2878 3.83382 13.2211 3.61831 13.146C3.17328 12.9906 2.87489 12.6837 2.72441 12.2291C2.65747 12.0244 2.58925 11.8198 2.52166 11.6152C2.48022 11.4869 2.39861 11.4112 2.26216 11.4092C2.11807 11.4066 2.03454 11.4876 1.99055 11.621C1.92488 11.8204 1.85729 12.0199 1.79226 12.22C1.63669 12.7005 1.31216 13.0055 0.844804 13.1616C0.638863 13.2302 0.430372 13.2943 0.222518 13.3591C0.0930879 13.3999 0 13.475 0 13.6226Z" fill="black" />
      </svg>
    </span>
  );
};
