import { useCallback, useEffect, useState } from "react";
import { TextField } from "@fluentui/react";
import { t } from "i18next";
import { DeliwiButton } from "components/DeliwiButton";
import { ISkill } from "interfaces/IUserProfile";
import { IconAddCircle } from "components/Icons/IconAddCircle";
import * as React from "react";
import { TagPicker, ITag, IInputProps, IBasePickerSuggestionsProps } from "@fluentui/react/lib/Pickers";
import { searchSkills } from "services/apiService";
import { HelpDialog } from "components/HelpDialog";

interface IUserSkillsEditAddSkillProps {
  skills: ISkill[];

  setSkills(data: ISkill[]): void;
}

const inputProps: IInputProps = {
  // onBlur: (ev: React.FocusEvent<HTMLInputElement>) => console.log("onBlur called"),
  // onFocus: (ev: React.FocusEvent<HTMLInputElement>) => console.log("onFocus called"),
  "aria-label": "Tag picker",
};

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: t("SuggestedSkills"),
  noResultsFoundText: t("NoSkillsFound"),
};

export const UserSkillsEditAddSkill = ({ skills, setSkills }: IUserSkillsEditAddSkillProps) => {
  const [skill, setSkill] = useState({
    Name: "",
    Level: 0, // Can also be 1, 2 or 3
  });
  const [hiddenInputValue, setHiddenInputValue] = useState<string>();
  const [tagPickerKey, setTagPickerKey] = useState(Date.now());

  const onFilterChanged = useCallback(async (filterText: string, tagList?: ITag[] | undefined): Promise<ITag[]> => {
    if (!filterText || filterText.length < 3) {
      return [];
    }

    try {
      const response = await searchSkills(filterText);

      if (!response) {
        console.error("Invalid response structure:", response);
        return [];
      }

      const filteredSkills: ITag[] = response.map((skill) => ({
        name: skill,
        key: skill,
      }));

      return filteredSkills;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }, []);

  const getTextFromItem = (skill: ITag) => skill.name;

  const addSuggestedSkill = (selectedItem?: ITag | undefined): ITag | PromiseLike<ITag> | null => {
    if (selectedItem !== undefined) {
      // Ensure skills is treated as an array even if it's null
      const currentSkills = skills || [];

      // Check if the skill is already in the currentSkills array
      const isSkillAlreadyAdded = currentSkills.some((skill) => skill.Name === selectedItem.name);
      if (!isSkillAlreadyAdded) {
        // If not already added, update the skill state to add the new skill
        setSkill({ ...skill, Name: selectedItem.name || "", Level: 0 });
      } else {
        // Optionally, inform the user that the skill is already added
        // Implementation of user feedback here
      }
    }
    return null;
  };

  useEffect(() => {
    if (!!skill?.Name) {
      const previousSkills = skills || [];
      const updatedSkills = [...previousSkills, skill];
      setSkills(updatedSkills); // Use destructured setSkills here
      setSkill({
        Name: "",
        Level: 0,
      });
    }
  }, [skill, skills, setSkills]);

  const handleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | undefined) => {
    setSkill({ ...skill, Name: value || "", Level: 0 });
  };

  const handlePickerChange = (value: string) => {
    setHiddenInputValue(value);
    return value;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!!hiddenInputValue) {
      setSkill({ ...skill, Name: hiddenInputValue || "", Level: 0 });
      setHiddenInputValue("");
      setTagPickerKey(Date.now());
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <HelpDialog className="mb-24" dialogContent={t("Help.EditForms.EditSkills.AddNewSkill.Content")} title={t("Help.EditForms.EditSkills.AddNewSkill.Title")}>
          <h3 className="text-style--Body1 fw-600 text-color--brand80">{t("EditSkillsStep1Label")}</h3>
        </HelpDialog>
        <div className="add-skill-input--container mb-32">
          <label>{t("FindSkillFromTheList")} *</label>
          <div className="add-interest-input--wrapper">
            <TagPicker
              key={tagPickerKey} // Step 3: Apply the key to the TagPicker
              removeButtonAriaLabel="Remove"
              selectionAriaLabel="Selected skills"
              onResolveSuggestions={onFilterChanged}
              getTextFromItem={getTextFromItem}
              pickerSuggestionsProps={pickerSuggestionsProps}
              onItemSelected={addSuggestedSkill}
              onInputChange={handlePickerChange}
              inputProps={{
                ...inputProps,
                id: "skill-picker",
              }}
            />
            <DeliwiButton className="button-style--primary button-size--medium" type="submit">
              <IconAddCircle />
              {t("Add")}
            </DeliwiButton>
          </div>
          <TextField className="hidden-input" autoAdjustHeight={true} label={t("Name")} name="add-new-skill" onChange={handleChange} value={hiddenInputValue} />
        </div>
      </form>
    </>
  );
};
