import { t } from "i18next";

import { Collapsible } from "components/Collapsible";
import { SkillAnalysisPanel } from "components/ControlPanel/SkillAnalysisPanel";
import IconAnalysesQuestion from "assets/icons/icon-analyses-question.svg";
import IconAnalyseBrush from "assets/icons/icon-analyses-brush.svg";
import IconAnalyseBars from "assets/icons/icon-analyses-bars.svg";
import "assets/styles/pages/ControlPanel.scss";

export const TabSkillAnalysis = () => {
  return (
    <>
      <div className="admin-box mb-40">
        <h2 className="text-style--Subtitle1 text-color--brand10 mb-32">{t("StudyTheOrganization")}</h2>
        <div className="admin-box__graywrap mb-24">
          <div className="admin-box__iconset admin-box__iconset-alignstart">
            <Collapsible title={t("WhatIsAnalysisTitle")} icon={IconAnalysesQuestion}>
              <div className="admin-analyses__collapsecols">
                <div className="admin-analyses__collapsecols-col">
                  <img src={IconAnalyseBrush} className="icon-size-62 icon-shadow" alt="" />
                  <div>
                    <h3 className="text-style--Title2 mb-16">{t("AnalysisInfoCol1Title")}</h3>
                    <p className="text-style--Body1 mb-16" dangerouslySetInnerHTML={{ __html: t("AnalysisInfoCol1Text1") }} />
                    <p className="text-style--Body1 mb-16" dangerouslySetInnerHTML={{ __html: t("AnalysisInfoCol1Text2") }} />
                    <p className="text-style--Body1 mb-16" dangerouslySetInnerHTML={{ __html: t("AnalysisInfoCol1Text3") }} />
                  </div>
                </div>
                <div className="admin-analyses__collapsecols-col">
                  <img src={IconAnalyseBars} className="icon-size-62 icon-shadow" alt="" />
                  <div>
                    <h3 className="text-style--Title2 mb-16">{t("AnalysisInfoCol2Title")}</h3>
                    <p className="text-style--Body1 mb-16" dangerouslySetInnerHTML={{ __html: t("AnalysisInfoCol2Text1") }} />
                    <p className="text-style--Body1 mb-16" dangerouslySetInnerHTML={{ __html: t("AnalysisInfoCol2Text2") }} />
                    <p className="text-style--Body1 mb-16" dangerouslySetInnerHTML={{ __html: t("AnalysisInfoCol2Text3") }} />
                  </div>
                </div>
              </div>
              <div className="info-text">
                <p className="text-style--semibold" dangerouslySetInnerHTML={{ __html: t("AnalysisInfoTop") }} />
                <p className="text-style--semibold" dangerouslySetInnerHTML={{ __html: t("AnalysisInfoBottom") }} />
              </div>
            </Collapsible>
          </div>
        </div>
      </div>
      <SkillAnalysisPanel />
    </>
  );
};
