import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconPersonAccounts = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Person Accounts">
          <path id="Shape" d="M18.9037 20.0295V19.9591H6.62077C4.87672 19.9591 3.46289 21.3729 3.46289 23.117V23.9282C3.46289 25.1818 3.91016 26.3943 4.72426 27.3477C6.92367 29.9233 10.2741 31.1944 14.6916 31.1944C15.1935 31.1944 15.6818 31.1779 16.1561 31.1451C16.1162 30.9331 16.0953 30.7143 16.0953 30.4907V29.0381C15.6445 29.0714 15.1766 29.088 14.6916 29.088C10.8464 29.088 8.08592 28.0408 6.32602 25.9798C5.83756 25.4078 5.5692 24.6803 5.5692 23.9282V23.117C5.5692 22.5362 6.04001 22.0654 6.62077 22.0654H16.3874C16.8387 21.0327 17.7724 20.2591 18.9037 20.0295ZM14.6916 3.11523C18.5692 3.11523 21.7126 6.25866 21.7126 10.1363C21.7126 14.0139 18.5692 17.1573 14.6916 17.1573C10.814 17.1573 7.67053 14.0139 7.67053 10.1363C7.67053 6.25866 10.814 3.11523 14.6916 3.11523ZM14.6916 5.22154C11.9772 5.22154 9.77684 7.42194 9.77684 10.1363C9.77684 12.8506 11.9772 15.051 14.6916 15.051C17.4059 15.051 19.6063 12.8506 19.6063 10.1363C19.6063 7.42194 17.4059 5.22154 14.6916 5.22154ZM20.3079 21.3635H19.6058C18.4425 21.3635 17.4995 22.3065 17.4995 23.4698V30.4907C17.4995 31.654 18.4425 32.597 19.6058 32.597H30.8394C32.0027 32.597 32.9457 31.654 32.9457 30.4907V23.4698C32.9457 22.3065 32.0027 21.3635 30.8394 21.3635H30.1373V19.6081C30.1373 18.251 29.0371 17.1508 27.68 17.1508H22.7652C21.4081 17.1508 20.3079 18.251 20.3079 19.6081V21.3635ZM22.4142 19.6081C22.4142 19.4143 22.5713 19.2571 22.7652 19.2571H27.68C27.8739 19.2571 28.031 19.4143 28.031 19.6081V21.3635H22.4142V19.6081Z" fill="#020404" />
        </g>
      </svg>
    </span>
  );
};
