import React, { useState, useEffect } from "react";
import { t } from "i18next";
import { DeliwiButton } from "components/DeliwiButton";
import { getSurveys, deleteSurvey } from "services/apiService";
import { iSurveysData, iSurvey } from "interfaces/IControlPanel";
import { IconTrash } from "components/Icons/IconTrash";
import { SurveyReport } from "components/ControlPanel/SurveyReport";
import { Dialog, DialogFooter } from "@fluentui/react";
import { IconDismiss } from "components/Icons/IconDismiss";

interface activeSurveyReportsProps {
  makeSurveyReportsActive: () => void;
}

export const SurveyReports = ({ makeSurveyReportsActive }: activeSurveyReportsProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [surveysData, setSurveysData] = useState<iSurveysData>({
    Surveys: [],
    TotalAmountOfSurveysInDatabase: 0,
  });
  const [sortConfig, setSortConfig] = useState({ key: "StartDate", direction: "desc" });
  const [currentView, setCurrentView] = useState("table");
  const [selectedSurveyId, setSelectedSurveyId] = useState<string>("");
  const [selectedSurveySkill, setSelectedSurveySkill] = useState<string>("");
  const [selectedSurveyPersonCount, setSelectedSurveyPersonCount] = useState<number>(0);
  const [selectedSurveyAnswerCount, setSelectedSurveyAnswerCount] = useState<number>(0);
  const [selectedSurveyStatus, setSelectedSurveyStatus] = useState<number>(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [surveyToDeleteId, setSurveyToDeleteId] = useState<string>("");

  const sortData = (data: iSurvey[], key: keyof iSurvey, direction: "asc" | "desc") => {
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
  };

  const fetchSurveys = async () => {
    setIsLoading(true);
    try {
      const response = await getSurveys();
      if (response && typeof response === "object") {
        // Sort immediately after fetching
        let sortedSurveys = sortData(response.Surveys, "StartDate", "desc");
        setSurveysData({ ...response, Surveys: sortedSurveys });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch surveys", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSurveys();
  }, []);

  const sortSurveys = (key: keyof iSurvey) => {
    const newDirection = sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction: newDirection }); // Update sort configuration
    // Re-sort based on new configuration without fetching data
    setSurveysData((prevState) => ({
      ...prevState,
      Surveys: sortData(prevState.Surveys, key, newDirection),
    }));
  };

  const getColumnClass = (key: keyof iSurvey) => {
    const baseClasses = `column-${key.toLowerCase()} sortable`;
    const isActive = sortConfig.key === key ? "active" : "";
    const direction = sortConfig.key === key ? sortConfig.direction : "asc";
    return `${baseClasses} ${direction} ${isActive}`; // Append 'active' class if column is currently sorted
  };

  const handleShowSurvey = (survey: any) => {
    setSelectedSurveyId(survey.Id);
    setSelectedSurveySkill(survey.Skill);
    setSelectedSurveyPersonCount(survey.PersonCount);
    setSelectedSurveyAnswerCount(survey.AnswerCount);
    setSelectedSurveyStatus(survey.Status);
    setCurrentView("report");
  };

  const handleReturnToTable = () => {
    fetchSurveys()
      .then(() => {
        setCurrentView("table");
        makeSurveyReportsActive(); // Activate SurveyReports in SkillAnalysisPanel
      })
      .catch((error) => {
        console.error("Failed to reload surveys", error);
        setCurrentView("table");
        makeSurveyReportsActive(); // Ensure active even on error
      });
  };

  const handleDeleteSurvey = async (survey: any) => {
    setSurveyToDeleteId(survey.Id);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteSurvey = async () => {
    try {
      const deleted = await deleteSurvey(surveyToDeleteId);
      console.log(deleted);
    } catch (error) {
      console.error("Failed to delete user:", error);
    } finally {
      fetchSurveys()
        .then(() => {
          setCurrentView("table");
        })
        .catch((error) => {
          console.error("Failed to reload surveys", error);
        });
      setShowDeleteConfirmation(false);
    }
  };

  if (isLoading) {
    return <div className="deliwi-spinner"></div>;
  }

  if (currentView === "report" && selectedSurveyId) {
    return <SurveyReport surveyId={selectedSurveyId} surveySkill={selectedSurveySkill} surveyStatus={selectedSurveyStatus} surveyAnswerCount={selectedSurveyAnswerCount} surveyPersonCount={selectedSurveyPersonCount} onReturnToTable={handleReturnToTable} />;
  }

  return (
    <>
      {showDeleteConfirmation && (
        <Dialog
          hidden={!showDeleteConfirmation}
          onDismiss={() => setShowDeleteConfirmation(false)}
          dialogContentProps={{
            showCloseButton: true,
            title: t("DeleteSurvey"),
          }}
          modalProps={{
            className: "admin-dialog admin-dialog--delete",
          }}
        >
          <div className="box-gray mb-32">
            <p className="text-style--Body1 text-style--semibold mb-16">{t("AreYouSure")}</p>
            <p className="text-style--Body1">{t("WhenDeletingSurveyInfo")}</p>
          </div>
          <DialogFooter>
            <DeliwiButton className="button-style--outline button-size--large" onClick={() => setShowDeleteConfirmation(false)} text={t("Cancel")}>
              <IconDismiss className="icon-size--16" />
            </DeliwiButton>
            <DeliwiButton className="button-style--delete button-size--large" onClick={confirmDeleteSurvey} text={t("Delete")}>
              <IconTrash />
            </DeliwiButton>
          </DialogFooter>
        </Dialog>
      )}
      <div className="admin-survey__list pt-24">
        <div className="admin-survey__list-header admin-survey__list-widths text-style--MobileSubtitle2 text-color--grey44">
          <div className={getColumnClass("Title")} onClick={() => sortSurveys("Title")}>
            <div className="">{t("Title")}</div>
          </div>
          <div className={getColumnClass("Skill")} onClick={() => sortSurveys("Skill")}>
            <div className="">{t("Skill")}</div>
          </div>
          <div className={getColumnClass("StartDate")} onClick={() => sortSurveys("StartDate")}>
            <div className="">{t("StartDate")}</div>
          </div>
          <div className={getColumnClass("AnswerCount")} onClick={() => sortSurveys("AnswerCount")}>
            <div className="">{t("Answers")}</div>
          </div>
          <div className={getColumnClass("Status")} onClick={() => sortSurveys("Status")}>
            <div className="">{t("Status")}</div>
          </div>
          <div>&nbsp;</div>
        </div>
        {surveysData.Surveys.map((survey, index) => (
          <div key={index} className="admin-survey__list-row admin-survey__list-widths text-style--Body2 text-color--brand10">
            <div>{survey.Title}</div>
            <div>{survey.Skill}</div>
            <div>{new Date(survey.StartDate).toLocaleDateString()}</div>
            <div>
              {survey.AnswerCount} / {survey.PersonCount}
            </div>
            <div>{survey.Status === 0 ? t("Ongoing") : t("Completed")}</div>
            <div className="admin-survey__list-buttons">
              <DeliwiButton className="button-style--primary button-size--medium ml-auto" onClick={() => handleShowSurvey(survey)}>
                {t("ShowReport")}
              </DeliwiButton>
              <DeliwiButton className="button-style--delete button-size--mediumicon" onClick={() => handleDeleteSurvey(survey)}>
                <IconTrash />
              </DeliwiButton>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
