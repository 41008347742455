import { useCallback, useEffect, useState } from "react";
import { TextField } from "@fluentui/react";
import { t } from "i18next";
import { DeliwiButton } from "components/DeliwiButton";
import { IconAddCircle } from "../../Icons/IconAddCircle";
import * as React from "react";
import { TagPicker, ITag, IInputProps, IBasePickerSuggestionsProps } from "@fluentui/react/lib/Pickers";
import { searchInterests } from "../../../services/apiService";

interface IUserMyInterestsEditAddInterestProps {
  interests: string[];

  setInterests(data: string[]): void;
}

const inputProps: IInputProps = {
  // onBlur: (ev: React.FocusEvent<HTMLInputElement>) => console.log("onBlur called"),
  // onFocus: (ev: React.FocusEvent<HTMLInputElement>) => console.log("onFocus called"),
  "aria-label": "Tag picker",
};

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: t("SuggestedInterests"),
  noResultsFoundText: t("NoInterestsFound"),
};

export const UserMyInterestsEditAddInterest = (props: IUserMyInterestsEditAddInterestProps) => {
  const [interest, setInterest] = useState<string>();
  const [pickerKey, setPickerKey] = useState(0);
  const [hiddenInputValue, setHiddenInputValue] = useState<string>();
  const onFilterChanged = useCallback(async (filterText: string, tagList?: ITag[] | undefined): Promise<ITag[]> => {
    if (!filterText || filterText.length < 3) {
      return [];
    }

    try {
      const response = await searchInterests(filterText);

      if (!response) {
        console.error("Invalid response structure:", response);
        return [];
      }

      const filteredInterests: ITag[] = response.map((interest) => ({
        name: interest,
        key: interest,
      }));

      return filteredInterests;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }, []);

  const getTextFromItem = (skill: ITag) => skill.name;

  const addSuggestedInterest = (selectedItem?: ITag | undefined): ITag | PromiseLike<ITag> | null => {
    if (selectedItem !== undefined) {
      setInterest(selectedItem.name);
      // Force TagPicker to re-render by updating its key
      setPickerKey((prevKey) => prevKey + 1);
    }
    return null;
  };

  useEffect(() => {
    if (!!interest) {
      const updatedInterests = [...props.interests, interest];
      props.setInterests(updatedInterests);
      setInterest("");
    }
  }, [interest, props]);

  const handleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | undefined) => {
    setInterest(value);
  };

  const handlePickerChange = (value: string) => {
    setHiddenInputValue(value);
    return value;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!!hiddenInputValue) {
      setInterest(hiddenInputValue);
      setHiddenInputValue("");
      setPickerKey((prevKey) => prevKey + 1);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="add-interest-input--container">
          <label>{t("AddInterestLabel")}</label>
          <div className="add-interest-input--wrapper">
            <TagPicker
              key={pickerKey}
              removeButtonAriaLabel="Remove"
              selectionAriaLabel="Selected colors"
              onResolveSuggestions={onFilterChanged}
              getTextFromItem={getTextFromItem}
              pickerSuggestionsProps={pickerSuggestionsProps}
              onItemSelected={addSuggestedInterest}
              onInputChange={handlePickerChange}
              inputProps={{
                ...inputProps,
                id: "interests-picker",
              }}
            />
            <DeliwiButton className="button-style--primary button-size--medium" type="submit">
              <IconAddCircle />
              {t("Add")}
            </DeliwiButton>
          </div>
          <TextField className="hidden-input" autoAdjustHeight={true} label={t("Name")} name="add-new-skill" onChange={handleChange} value={hiddenInputValue} />
        </div>
      </form>
    </>
  );
};
