import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconEdit = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.1813 0.92494C14.0291 -0.286901 12.1047 -0.31118 10.9222 0.87121L1.54741 10.2456C1.21958 10.5734 0.99204 10.9879 0.89148 11.4405L0.0138698 15.3904C-0.0232202 15.5573 0.0275396 15.7316 0.14844 15.8524C0.26934 15.9733 0.44362 16.024 0.6105 15.9869L4.53689 15.1138C5.00432 15.0098 5.43243 14.7748 5.77103 14.4362L15.129 5.07808C16.27 3.93704 16.2933 2.09436 15.1813 0.92494ZM11.6293 1.57834C12.4143 0.793429 13.6917 0.80955 14.4566 1.614C15.1948 2.3903 15.1793 3.61352 14.4219 4.37098L13.7507 5.04223L10.958 2.2496L11.6293 1.57834ZM10.2509 2.95669L13.0436 5.74934L5.06391 13.7291C4.85976 13.9332 4.60164 14.0749 4.31982 14.1376L1.1605 14.8402L1.86768 11.6574C1.92698 11.3905 2.06117 11.146 2.2545 10.9527L10.2509 2.95669Z" fill="#242424" />
      </svg>
    </span>
  );
};
