import { useRef, useState, FormEvent } from "react";
import { t } from "i18next";
import { IPersonaProps } from "@fluentui/react/lib/Persona";
import { PeoplePicker, PeoplePickerRef } from "components/ControlPanel/PeoplePicker";
import { DepartmentPicker } from "components/ControlPanel/DepartmentPicker";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import { IUser, IUserData } from "interfaces/IUser";
import { IAnalysisResults } from "interfaces/IControlPanel";
import { SingleSkillPicker } from "components/ControlPanel/SingleSkillPicker";
import { StepIndicator } from "components/ControlPanel/StepIndicator";
import { SkillsChart } from "components/ControlPanel/SkillsChart";
import { SurveyReport } from "components/ControlPanel/SurveyReport";
import { SkillRateTable } from "components/ControlPanel/SkillRateTable";
import { getAnalysisResults } from "services/apiService";
import { IconBackArrow } from "components/Icons/IconBackArrow";
import { IconRunAnalysis } from "components/Icons/IconRunAnalysis";
import { DeliwiButton } from "components/DeliwiButton";
import { SurveyPreview } from "components/ControlPanel/SurveyPreview";

// Icons imports
import IconAddUsers from "assets/icons/icon-add-users.svg";
import IconPie from "assets/icons/icon-pie.svg";
import IconHat from "assets/icons/icon-analyses-skillhat.svg";
import { IconPieBtn } from "components/Icons/IconPieBtn";
import { IconAdd } from "components/Icons/IconAdd";

interface activeSurveyReportsProps {
  makeSurveyReportsActive: () => void;
}

export const RunAnalysis = ({ makeSurveyReportsActive }: activeSurveyReportsProps) => {
  const peoplePickerRef = useRef<PeoplePickerRef>(null);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [peopleTotal, setPeopleTotal] = useState<number>(0);
  const [analysisType, setAnalysisType] = useState<string>("all");
  const [analysisResults, setAnalysisResults] = useState<IAnalysisResults | null>(null);
  const [selectedAnalyticsUsers, setSelectedAnalyticsUsers] = useState<IUser[]>([]);
  const [selectedSurveyUsers, setSelectedSurveyUsers] = useState<IUser[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const [selectedSkill, setSelectedSkill] = useState<string>("");
  const [isAnalysisRunning, setIsAnalysisRunning] = useState<boolean>(false);
  const [isSurveyBuilding, setIsSurveyBuilding] = useState<boolean>(false);
  const [createdSurveyId, setCreatedSurveyId] = useState<string>("");
  const [openSurveySendModal, setOpenSurveySendModal] = useState<boolean>(false);

  const totalSteps = 3;

  const handleUserSelection = (users: IPersonaProps[]) => {
    const newSelectedAnalyticsUsers: IUser[] = users.map((user) => ({
      DisplayName: user.text || "",
      Id: user.key as string,
      Type: user.secondaryText || "",
    }));
    setSelectedAnalyticsUsers(newSelectedAnalyticsUsers);
  };

  const handleDepartmentSelection = (department: string) => {
    setSelectedDepartment(department);
  };

  const handleRadioChange = (_: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption) => {
    if (option) {
      setAnalysisType(option.key);
    }
  };

  const handleReturnToTable = () => {
    makeSurveyReportsActive();
  };

  const radioOptions: IChoiceGroupOption[] = [
    { key: "all", text: "All users" },
    { key: "department", text: "By department" },
    { key: "users", text: "Specific users" },
  ];

  const fetchAnalysisResults = async (skillName: string, userIds: string[] = [], department: string = ""): Promise<IAnalysisResults> => {
    try {
      const analysisResults = await getAnalysisResults(skillName, userIds, department); // Assuming Unrated and NoInformation are arrays, we concatenate them.
      // If they are not arrays, you will need to adjust this logic accordingly.
      const combinedNoInformation = (analysisResults.NoInformation || []).concat(analysisResults.Unrated || []);
      // const combinedNoInformationTotal = (analysisResults.NoInformationTotal || 0) + (analysisResults.UnratedTotal || 0);

      const seenIds: { [key: string]: boolean } = {};
      const cleanedCombined = combinedNoInformation.filter((item: { id: string }) => {
        if (seenIds[item.id]) {
          return false;
        } else {
          seenIds[item.id] = true;
          return true;
        }
      });

      // Update the analysisResults with combined values
      analysisResults.NoInformation = cleanedCombined;
      analysisResults.NoInformationTotal = cleanedCombined.length;

      // Remove the Unrated and UnratedTotal as they are now combined into NoInformation
      delete analysisResults.Unrated;
      delete analysisResults.UnratedTotal;

      return analysisResults;
    } catch (error) {
      console.error("Failed to fetch analysis results:", error);
      return {
        Expert: null,
        ExpertTotal: 0,
        Advanced: null,
        AdvancedTotal: 0,
        Novice: null,
        NoviceTotal: 0,
        NoInformation: null,
        NoInformationTotal: 0,
      };
    }
  };

  const handleRunAnalysisClick = async (): Promise<void> => {
    setIsAnalysisRunning(true);
    const minimumLoadingTime = new Promise<void>((resolve) => setTimeout(resolve, 1000));

    let userIds: string[] = [];
    if (analysisType === "users") {
      userIds = selectedAnalyticsUsers.map((user) => user.Id);
    }

    const analysisResults = await fetchAnalysisResults(selectedSkill, userIds, selectedDepartment);
    setAnalysisResults(analysisResults);

    const count = analysisResults.ExpertTotal + analysisResults.AdvancedTotal + analysisResults.NoviceTotal + analysisResults.NoInformationTotal;

    setPeopleTotal(count);

    await minimumLoadingTime;
    setIsAnalysisRunning(false);
    setCurrentStep(3);
  };

  const handleRunSurveyClick = async (): Promise<void> => {
    if (analysisResults?.NoInformation) {
      const transformedNoInformation = analysisResults.NoInformation.map((userProfile) => ({
        DisplayName: userProfile.FirstName && userProfile.LastName ? `${userProfile.FirstName} ${userProfile.LastName}` : userProfile.Email || "",
        Id: userProfile.id,
        Type: "",
      }));

      const uniqueUsersMap = new Map<string, IUser>(transformedNoInformation.map((user) => [user.Id, user]));
      const uniqueUsers = Array.from(uniqueUsersMap.values());
      setSelectedSurveyUsers(uniqueUsers);

      setIsSurveyBuilding(true);

      setOpenSurveySendModal(true);

      setIsSurveyBuilding(false);
    }
  };

  const isContinueEnabled = analysisType === "all" || selectedAnalyticsUsers.length > 0 || selectedDepartment.length > 0;
  const stepTitles = ["SelectUsers", "ChooseSkillAndRunAnalysis", "Results"];

  if (isSurveyBuilding) {
    return <div className="deliwi-spinner"></div>;
  }

  const renderDialogContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="admin-box__iconset mb-24">
              <img src={IconAddUsers} className="icon-size-62 icon-shadow" alt="" />
              <h4 className="text-style--Title2 text-style--semibold">{t("SelectTheUsersToAnalyze")}</h4>
            </div>
            <div className="info-text mb-40">
              <p className="text-style--Subtitle2 text-style--semibold" dangerouslySetInnerHTML={{ __html: t("AnalysisExplanation") }} />
            </div>
            <ChoiceGroup className="admin-analyses__radios mb-24" selectedKey={analysisType} options={radioOptions} onChange={handleRadioChange} />

            {analysisType === "users" && (
              <div className="mb-40">
                <p className="text-style--Body2 text-color--grey44 mb-8">{t("SelectUsers")} *</p>
                <PeoplePicker ref={peoplePickerRef} onUserSelect={handleUserSelection} pickerType="analysisusers" />
              </div>
            )}

            {analysisType === "department" && (
              <div className="mb-40">
                <p className="text-style--Body2 text-color--grey44 mb-8">{t("SelectDepartment")} *</p>
                <DepartmentPicker onDepartmentSelect={handleDepartmentSelection} />
              </div>
            )}
            <div className="admin-analyses__buttons">
              <DeliwiButton disabled={!isContinueEnabled} className="button-style--primary button-size--large ml-auto" onClick={() => setCurrentStep(2)}>
                <IconPieBtn className="icon-size--20" />
                {t("Continue")}
              </DeliwiButton>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="admin-box__iconset mb-40">
              <img src={IconHat} className="icon-size-62 icon-shadow" alt="" />
              <h4 className="text-style--Title2 text-style--semibold">{t("ChooseTheSkillToAnalyze")}</h4>
            </div>
            <div className="admin-analyses__steps--skills mb-40">
              <SingleSkillPicker mode="analysis" onSkillSelect={setSelectedSkill} />
              <div className="info-text">
                <p className="text-style--semibold">{t("StartTypingSkillNameAndSelect")}</p>
                <p className="text-style--semibold">{t("Or")}</p>
                <p className="text-style--semibold">{t("TypeTheSkillNameIfItIsNotFound")}</p>
              </div>
            </div>
            <div className="admin-analyses__buttons">
              <DeliwiButton className="button-style--outline button-size--large" onClick={() => setCurrentStep(1)}>
                <IconBackArrow className="icon-size--18" />
                {t("Back")}
              </DeliwiButton>
              {isAnalysisRunning ? (
                <div className="ml-auto">
                  <div className="deliwi-spinner"></div>
                </div>
              ) : (
                <DeliwiButton className="button-style--primary button-size--large ml-auto" onClick={handleRunAnalysisClick} disabled={!selectedSkill}>
                  <IconRunAnalysis className="icon-size--18" />
                  {t("RunAnalysis")}
                </DeliwiButton>
              )}
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="admin-box__iconset mb-32">
              <img src={IconPie} className="icon-size-62 icon-shadow" alt="" />
              <h4 className="text-style--Title2 text-style--semibold">{t("AnalyzeTitle")}</h4>
            </div>
            <div className="admin-analyses__results mb-40">
              <div className="admin-analyses__graph">{analysisResults && <SkillsChart analysisResults={analysisResults} />}</div>
              <div className="admin-analyses__info">
                <div className="admin-box">
                  <h4 className="text-style--Subtitle1 mb-32 admin-analyses__info--title">Analysis info</h4>
                  <p className="text-style--Body2 text-color--grey44 mb-4">Users in analysis:</p>
                  <p className="text-style--Title3 text-style--semibold mb-24">{peopleTotal} users</p>
                  <p className="text-style--Body2 text-color--grey44 mb-4">The skill under survey:</p>
                  <p className="text-style--Title3 text-style--semibold mb-24">{selectedSkill}</p>
                  {(analysisResults?.NoInformation?.length ?? 0) > 0 && (
                    <>
                      <p className="text-style--Body1 text-color--neutral-foreground mb-16">Total users who have no data available on analyzed skill: {analysisResults?.NoInformationTotal}. It is advised to create survey for a more comprehensive understanding and send it to these users.</p>

                      <DeliwiButton
                        className="button-style--secondary button-size--medium"
                        onClick={() => {
                          handleRunSurveyClick();
                        }}
                      >
                        {t("Create a targeted survey")}
                      </DeliwiButton>
                      <SurveyPreview isOpen={openSurveySendModal} setIsOpen={setOpenSurveySendModal} setCreatedSurveyId={setCreatedSurveyId} surveyTitle={"Survey: " + selectedSkill} surveySkill={selectedSkill} selectedSurveyUsers={selectedSurveyUsers} nextStep={4} setNextStep={setCurrentStep} />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="admin-analyses__table mb-40">{analysisResults && <SkillRateTable analysisResults={analysisResults} />}</div>
            <div className="admin-analyses__buttons">
              <DeliwiButton
                className="button-style--primary button-size--large ml-auto"
                onClick={() => {
                  setCurrentStep(1);
                  setIsAnalysisRunning(false);
                  setSelectedSkill("");
                  setSelectedDepartment("");
                  setSelectedAnalyticsUsers([]);
                }}
              >
                <IconAdd className="icon-size--18" />
                {t("RunNewAnalysis")}
              </DeliwiButton>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <SurveyReport surveyId={createdSurveyId} surveySkill={selectedSkill} surveyStatus={0} surveyAnswerCount={0} surveyPersonCount={selectedSurveyUsers.length} onReturnToTable={handleReturnToTable} />
          </>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <StepIndicator currentStep={Math.min(currentStep, totalSteps)} totalSteps={totalSteps} stepTitles={stepTitles} />

      {renderDialogContent()}
    </>
  );
};
