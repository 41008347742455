import React, { Component } from "react";

class ServiceFormChat extends Component {
  componentDidMount() {
    var s = document.createElement("script");
    s.async = true;
    s.innerHTML = 'var tD=(new Date).toISOString().slice(0,10);window.sf3pid = "ot6Mg9TSz3XYqaOn2ScI";var u="https://dash.serviceform.com/embed/sf-pixel.js?"+tD,t=document.createElement("script");t.setAttribute("type","text/javascript"),t.setAttribute("src",u),t.async=!0,(document.getElementsByTagName("head")[0]||document.documentElement).appendChild(t);';
    document.head.appendChild(s);
  }

  render() {
    return <div className="service-form-chat-container" />;
  }
}

export default ServiceFormChat;
