import { TextField, Checkbox } from "@fluentui/react";
import DatePicker from "react-datepicker";
import { DeliwiButton } from "components/DeliwiButton";
// The icons are in use but the way IIconProps is setting them is causing this
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { IAIRewrittenFieldsOriginalValues, IBackground, IUserProfile, IUserProfileEditForm } from "interfaces/IUserProfile";
import * as Yup from "yup";
import { FieldArray, Form, Formik, FormikErrors } from "formik";
import { format } from "date-fns";
import { t } from "i18next";
import { useState, FormEvent, Fragment } from "react";
import { IconAdd } from "../../Icons/IconAdd";
import { IconTrash } from "../../Icons/IconTrash";
import { IconDismiss } from "../../Icons/IconDismiss";
import { ConfirmDeleteRow } from "../../ConfirmDeleteRow";
import { IconEdit } from "../../Icons/IconEdit";
import { IconEditOff } from "../../Icons/IconEditOff";
import "react-datepicker/dist/react-datepicker.css";
import { IconCheckmark } from "components/Icons/IconCheckmark";
import { IconClose } from "../../Icons/IconClose";
import { rewriteTextWithAI } from "../../../services/apiService";
import { IconShape } from "../../Icons/IconShape";
import { IconUndo } from "../../Icons/IconUndo";
import { HelpDialog } from "../../HelpDialog";

export interface IWorkHistoryFormProps {
  Organization: string;
  Since: string;
  Until: string;
  Title: string;
  Description: string;
}

export const UserWorkHistoryEdit = (props: IUserProfileEditForm) => {
  const shadowUserProfile = props.userProfileData;
  const [visibleIndex, setVisibleIndex] = useState<number | null>(null);
  const [newItemIndex, setNewItemIndex] = useState<number | null>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [indexToDelete, setIndexToDelete] = useState<number | null>(null);
  const [aiRewrittenFields, setAiRewrittenFields] = useState<IAIRewrittenFieldsOriginalValues[]>([]);
  const [fieldUnderRewriting, setFieldUnderRewriting] = useState<string>("");
  const getItemFromRewrittenFields = (fieldName: string): IAIRewrittenFieldsOriginalValues | undefined => aiRewrittenFields?.find((item) => item.fieldName === fieldName);

  const toggleGroupVisibility = (index: number | null) => {
    if (visibleIndex === null || visibleIndex !== index) {
      setVisibleIndex(index);
    } else {
      setVisibleIndex(null);
    }
  };

  const validationSchema = Yup.object().shape({
    workHistory: Yup.array().of(
      Yup.object().shape({
        Organization: Yup.string().min(2, "Too short name").required("Company name is required"),
        Title: Yup.string().required("Job title is required"),
        Since: Yup.string().required("Work start month and year is required"),
      }),
    ),
  });

  const saveNewValues = async (values: IWorkHistoryFormProps[]) => {
    const sortedValues = values.sort((a, b) => new Date(b.Since).valueOf() - new Date(a.Since).valueOf());
    const updatedProfile = {
      ...shadowUserProfile,
      WorkExperience: sortedValues,
    } as IUserProfile;

    try {
      await props.saveProfile(updatedProfile);
      props.userProfileSetter(updatedProfile);
      props.hidePopup();
    } catch (error) {
      console.error("Failed to save changes:", error);
    }
  };

  return (
    <>
      <div className="edit-dialog--header form--heading--wrapper">
        <h2 className="form--heading">{t("WorkExperience")}</h2>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-edit-popup" onClick={() => props.hidePopup()}></ChromeCloseIcon>
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          workHistory: shadowUserProfile.WorkExperience || [],
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveNewValues(values.workHistory);
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, errors, validateForm, isValid }) => (
          <Form>
            <div>
              <FieldArray
                name="workHistory"
                render={(arrayHelpers) => (
                  <div>
                    {values?.workHistory !== undefined && values.workHistory !== null && values?.workHistory.length > 0 && (
                      <div className="form--container large mb-16">
                        {values.workHistory.map((item: IBackground, index: number) => {
                          const sinceDate = !!item.Since && new Date(item.Since);
                          const sinceDateFormatted = !!sinceDate ? format(sinceDate, "LLL yyyy") : "";
                          const untilDate = !!item.Until && new Date(item.Until);
                          const untilDateFormatted = !!untilDate ? format(untilDate, "LLL yyyy") : !!sinceDateFormatted && !untilDate ? "Present" : "";
                          const isCurrent = item.isCurrent || false;
                          const handleIsCurrentChange = (ev?: FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                            // Ensure checked is not undefined before using it
                            if (checked !== undefined) {
                              setFieldValue(`workHistory.${index}.isCurrent`, checked);
                              setFieldValue(`workHistory.${index}.Until`, undefined);
                            }
                          };
                          const fieldNameForDescriptionRewrite = "work-history-" + index + "-description";
                          return (
                            <Fragment key={index}>
                              <div className="form--fields-container item-row" key={index}>
                                <div className="form--fields--header">
                                  <div className="item--preview-data">
                                    <p className="text-style--Body1 fw-600 text-color--brand80">{item.Organization || ""}</p>
                                    <p className="text-style--Body1 fw-600 text-color--brand10">{item.Title}</p>
                                    {!!sinceDateFormatted && !!untilDateFormatted && (
                                      <p className="text-style--Body1 text-color--brand10 mb-16">
                                        {sinceDateFormatted} - {untilDateFormatted}
                                      </p>
                                    )}
                                    <p className="line-clamp-2 text-style--Body1 text-color--brand10">{item.Description || ""}</p>
                                  </div>
                                  {newItemIndex !== index && (
                                    <div className="form--item--actions">
                                      <DeliwiButton
                                        className={visibleIndex === index ? "button-style--outline button-size--medium bg-white active" : "button-style--outline button-size--medium bg-white"}
                                        onClick={() => {
                                          // Triggering form validation
                                          validateForm().then((errors) => {
                                            toggleGroupVisibility(index);
                                          });
                                        }}
                                        disabled={!isValid}
                                      >
                                        {visibleIndex === index ? (
                                          <>
                                            <IconEditOff className="icon-size--16" />
                                            {t("CloseEdit")}
                                          </>
                                        ) : (
                                          <>
                                            <IconEdit className="icon-size--16" />
                                            {t("Edit")}
                                          </>
                                        )}
                                      </DeliwiButton>
                                      <DeliwiButton
                                        className="button-style--outline button-size--medium bg-white"
                                        onClick={() => {
                                          setIndexToDelete(index);
                                          setConfirmDialogOpen(true);
                                        }}
                                      >
                                        <IconTrash className="icon-size--16" />
                                        {t("Delete")}
                                      </DeliwiButton>
                                    </div>
                                  )}
                                </div>
                                {visibleIndex === index && (
                                  <div className="form--fields-container-grid">
                                    <div className="form--field--wrapper">
                                      <TextField label={t("CompanyName")} required={true} name={`workHistory.${index}.Organization`} defaultValue={item.Organization} onChange={handleChange} onBlur={handleBlur} />
                                      {(errors?.workHistory as FormikErrors<IWorkHistoryFormProps>[])?.[index]?.Organization && <div className="input-error">{(errors?.workHistory as FormikErrors<IWorkHistoryFormProps>[])?.[index]?.Organization}</div>}
                                    </div>
                                    <div className="form--field--wrapper">
                                      <TextField label={t("JobTitle")} required={true} name={`workHistory.${index}.Title`} defaultValue={item.Title} onChange={handleChange} onBlur={handleBlur} />
                                      {(errors?.workHistory as FormikErrors<IWorkHistoryFormProps>[])?.[index]?.Title && <div className="input-error">{(errors?.workHistory as FormikErrors<IWorkHistoryFormProps>[])?.[index]?.Title}</div>}
                                    </div>
                                    <div className="form--field--wrapper">
                                      <label className="field-label">{t("StartDate")} *</label>
                                      <DatePicker
                                        selected={!!item.Since ? new Date(item.Since) : undefined}
                                        onChange={(date) => {
                                          if (date instanceof Date) {
                                            const dateValue = format(date, "yyyy-MM-dd'T'HH:mm:ss");
                                            setFieldValue(`workHistory.${index}.Since`, dateValue);
                                          }
                                        }}
                                        dateFormat="MM/yyyy"
                                        showMonthYearPicker
                                        onBlur={() => setFieldTouched(`workHistory.${index}.Since`, true)}
                                      />
                                      {(errors?.workHistory as FormikErrors<IWorkHistoryFormProps>[])?.[index]?.Since && <div className="input-error">{(errors?.workHistory as FormikErrors<IWorkHistoryFormProps>[])?.[index]?.Since}</div>}
                                    </div>
                                    {!isCurrent && (
                                      <div className="form--field--wrapper">
                                        <label className="field-label">{t("EndDate")}</label>
                                        <DatePicker
                                          selected={!!item.Until ? new Date(item.Until) : undefined}
                                          onChange={(date) => {
                                            if (date instanceof Date) {
                                              const dateValue = format(date, "yyyy-MM-dd'T'HH:mm:ss");
                                              setFieldValue(`workHistory.${index}.Until`, dateValue);
                                            }
                                          }}
                                          dateFormat="MM/yyyy"
                                          showMonthYearPicker
                                          onBlur={() => setFieldTouched(`workHistory.${index}.Until`, true)}
                                        />
                                      </div>
                                    )}
                                    <div className="form--field--wrapper full-width mb-8">
                                      <Checkbox className="edit-dialog--checkbox" label={t("CurrentlyWorkingInThisPosition")} checked={isCurrent} onChange={handleIsCurrentChange} />
                                    </div>
                                    <div className="form--field--wrapper full-width mb-24">
                                      <div className="field-with-ai-rewrite field-with-ai-rewrite--wrapper">
                                        <TextField label={t("ShortDescription")} placeholder={t("ShortDescriptionDots")} multiline autoAdjustHeight rows={2} name={`workHistory.${index}.Description`} value={item.Description} onChange={handleChange} onBlur={handleBlur} />
                                        {fieldUnderRewriting === fieldNameForDescriptionRewrite && (
                                          <div className="search-loader--wrapper">
                                            <span className="deliwi-spinner mb-16"></span>
                                          </div>
                                        )}
                                      </div>
                                      <div className="field-with-ai-rewrite--actions">
                                        {!getItemFromRewrittenFields(fieldNameForDescriptionRewrite) ? (
                                          <HelpDialog className="" dialogContent={t("Help.EditForms.RewriteWithAI.Content")} title={t("Help.EditForms.RewriteWithAI.Title")}>
                                            <DeliwiButton
                                              className="button-style--candy-border button-size--medium bg-white"
                                              disabled={!item.Description || item.Description.length < 5}
                                              onClick={async () => {
                                                setFieldUnderRewriting(fieldNameForDescriptionRewrite);
                                                const response = await rewriteTextWithAI("WorkExperienceDescription", item.Description);
                                                if (!!response?.RewrittenText) {
                                                  setAiRewrittenFields([
                                                    ...aiRewrittenFields,
                                                    {
                                                      fieldName: fieldNameForDescriptionRewrite,
                                                      value: item.Description,
                                                    },
                                                  ]);
                                                  setFieldValue(`workHistory.${index}.Description`, response.RewrittenText);
                                                }
                                                setFieldUnderRewriting("");
                                              }}
                                            >
                                              <IconShape />
                                              {t("RewriteWithAI")}
                                            </DeliwiButton>
                                          </HelpDialog>
                                        ) : (
                                          <DeliwiButton
                                            className="button-style--outline button-size--medium"
                                            onClick={() => {
                                              const originalValueItem = getItemFromRewrittenFields(fieldNameForDescriptionRewrite);
                                              setFieldValue(`workHistory.${index}.Description`, originalValueItem?.value);
                                              const updatedRewrittenFields = aiRewrittenFields.filter((fieldItem) => fieldItem.fieldName !== fieldNameForDescriptionRewrite);
                                              setAiRewrittenFields(updatedRewrittenFields);
                                            }}
                                          >
                                            <IconUndo />
                                            {t("Undo")}
                                          </DeliwiButton>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {newItemIndex === index && (
                                  <div className="new-item--actions">
                                    <DeliwiButton
                                      className="button-style--secondary button-size--medium"
                                      onClick={() => {
                                        setNewItemIndex(null);
                                        arrayHelpers.remove(index);
                                        toggleGroupVisibility(null);
                                      }}
                                    >
                                      <IconDismiss className="icon-size--12" />
                                      {t("Cancel")}
                                    </DeliwiButton>
                                    <DeliwiButton
                                      className="button-style--primary button-size--medium"
                                      onClick={() => {
                                        setNewItemIndex(null);
                                        toggleGroupVisibility(index);
                                      }}
                                      disabled={!isValid}
                                    >
                                      <IconAdd className="icon-size--16" />
                                      {t("Add")}
                                    </DeliwiButton>
                                  </div>
                                )}
                                {confirmDialogOpen && index === indexToDelete && (
                                  <ConfirmDeleteRow
                                    confirmDialogVisible={confirmDialogOpen}
                                    setConfirmDialogVisible={(visible) => {
                                      setConfirmDialogOpen(visible);
                                      if (!visible) {
                                        setIndexToDelete(null);
                                      }
                                    }}
                                    onConfirm={() => {
                                      arrayHelpers.remove(indexToDelete);
                                      setConfirmDialogOpen(false);
                                      setIndexToDelete(null);
                                    }}
                                  />
                                )}
                              </div>
                            </Fragment>
                          );
                        })}
                      </div>
                    )}
                    <DeliwiButton
                      className="button-style--newitem mb-40 ml-24"
                      onClick={() => {
                        // NewIndexToOpen is length of the array before pushing a new item because first
                        // item key is 0
                        let newIndexToOpen;
                        if (values.workHistory === null) {
                          newIndexToOpen = 0;
                        } else {
                          newIndexToOpen = values.workHistory.length;
                        }
                        setNewItemIndex(newIndexToOpen);
                        arrayHelpers.push({ Organization: "", Since: "", Until: null, Title: "" });
                        toggleGroupVisibility(newIndexToOpen);
                      }}
                    >
                      <IconAdd />
                      {t("AddNewPosition")}
                    </DeliwiButton>
                  </div>
                )}
              />
            </div>
            <div className="edit-dialog--buttons">
              <DeliwiButton className="button-style--outline button-size--large" onClick={() => props.hidePopup()}>
                <IconClose className="icon-size--20" />
                {t("Cancel")}
              </DeliwiButton>
              <DeliwiButton
                type="submit"
                className="button-style--primary button-size--large"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={props.saving}
              >
                <IconCheckmark />
                {t("SaveChanges")}
              </DeliwiButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
