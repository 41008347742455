import { IUserProfile } from "../interfaces/IUserProfile";
import { DeliwiButton } from "components/DeliwiButton";

import { MatchMeter } from "./MatchMeter";

import { t } from "i18next";

import "../assets/styles/components/ProfileCard.scss";
import { IconArrowForward } from "./Icons/IconArrowForward";
import avatarSvg from "assets/images/avatar.svg";

export interface IProfileCardProps {
  userProfile: IUserProfile;
  index: number;

  triggerProfileToPreview(userProfile: IUserProfile): void;
}

export const ProfileCard = (props: IProfileCardProps) => {
  const { userProfile } = props;
  const fullName = `${userProfile.FirstName} ${userProfile.LastName}`;
  const profilePictureUrl = userProfile?.ProfilePictureUrl || avatarSvg;

  return (
    <div key={userProfile.id} className="profile-card" onClick={() => props.triggerProfileToPreview(userProfile)}>
      <div className="profile-card--content--top">
        <div className="profile-card--avatar--wrapper">
          <img className="profile-card--avatar" alt={fullName} src={profilePictureUrl} />
        </div>
        <div className="profile-card--text--container">
          <span className="profile-card--text--primary text-style--Body1 text-style--bold">{fullName}</span>
        </div>
      </div>
      <div className="profile-card--content--middle">
        <div className="profile-card--text--container">
          <span className="profile-card--text--primary job-title text-style--Subtitle2">{userProfile?.JobTitle}</span>
          <span className="profile-card--text--secondary department">{userProfile?.Company?.Department ? userProfile.Company.Department : ""}</span>
          <span className="profile-card--text--secondary country">{userProfile?.Company?.Location?.Country ? userProfile.Company.Location.Country : ""}</span>
        </div>
      </div>
      <div className="profile-card--content--bottom">
        <div className="profile-card--match-meter--container">
          <MatchMeter />
        </div>
        <div className="profile-card--seemore--wrapper">
          <DeliwiButton className="button-style--primary button-size--small" onClick={() => props.triggerProfileToPreview(userProfile)}>
            <IconArrowForward className="icon-size--12" />
            {t("SeeProfileButtonLabel")}
          </DeliwiButton>
        </div>
      </div>
    </div>
  );
};
