import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconDismiss = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.692569 1.43075L1.03458 1.03458C2.2886 -0.219456 4.25096 -0.333459 5.63377 0.692569L6.02995 1.03458L36.5 31.5031L66.9703 1.03458C68.3497 -0.344856 70.5859 -0.344856 71.9653 1.03458C73.3448 2.41401 73.3448 4.65053 71.9653 6.02995L41.497 36.5L71.9653 66.9703C73.2195 68.224 73.3335 70.1865 72.3073 71.5693L71.9653 71.9653C70.7116 73.2195 68.7491 73.3335 67.3663 72.3073L66.9703 71.9653L36.5 41.497L6.02995 71.9653C4.65053 73.3448 2.41401 73.3448 1.03458 71.9653C-0.344856 70.5859 -0.344856 68.3497 1.03458 66.9703L31.5031 36.5L1.03458 6.02995C-0.219456 4.7759 -0.333459 2.81356 0.692569 1.43075Z" fill="#242424" />
      </svg>
    </span>
  );
};
