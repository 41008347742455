import { TextField } from "@fluentui/react";
import DatePicker from "react-datepicker";
import { DeliwiButton } from "components/DeliwiButton";
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { IUserProfile, IUserProfileEditForm } from "../../../interfaces/IUserProfile";
import * as Yup from "yup";
import { FieldArray, Form, Formik, FormikErrors } from "formik";
import { t } from "i18next";
import { format } from "date-fns";
import { useState, Fragment } from "react";
import { IconTrash } from "../../Icons/IconTrash";
import { IconAdd } from "../../Icons/IconAdd";
import { IconDismiss } from "../../Icons/IconDismiss";
import { ConfirmDeleteRow } from "../../ConfirmDeleteRow";
import { IconEditOff } from "../../Icons/IconEditOff";
import { IconEdit } from "../../Icons/IconEdit";
import "react-datepicker/dist/react-datepicker.css";
import { IconCheckmark } from "components/Icons/IconCheckmark";
import { IconClose } from "../../Icons/IconClose";

export interface ITrainingsFormProps {
  Name: string;
  Issuer: string;
  Since: string;
}

export const UserTrainingsEdit = (props: IUserProfileEditForm) => {
  const shadowUserProfile = props.userProfileData;
  const [visibleIndex, setVisibleIndex] = useState<number | null>(null);
  const [newItemIndex, setNewItemIndex] = useState<number | null>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [indexToDelete, setIndexToDelete] = useState<number | null>(null);
  const [itemNameToDelete, setItemNameToDelete] = useState<string | null>(null);
  const [itemTypeToDelete, setItemTypeToDelete] = useState<string | null>(null);
  const [itemTypeToDeletePlural, setItemTypeToDeletePlural] = useState<string | null>(null);

  const toggleGroupVisibility = (index: number | null) => {
    if (visibleIndex === null || visibleIndex !== index) {
      setVisibleIndex(index);
    } else {
      setVisibleIndex(null);
    }
  };

  const validationSchema = Yup.object().shape({
    trainings: Yup.array().of(
      Yup.object().shape({
        Name: Yup.string().min(2, "Too short name").required("Training/course name is required"),
        Since: Yup.string().required("Completed month and year is required"),
        Issuer: Yup.string().required("Organizer / trainer is required"),
      }),
    ),
  });

  const saveNewValues = async (values: ITrainingsFormProps[]) => {
    const sortedValues = values.sort((a, b) => new Date(b.Since).valueOf() - new Date(a.Since).valueOf());
    const updatedProfile = {
      ...shadowUserProfile,
      Trainings: sortedValues,
    } as IUserProfile;

    try {
      await props.saveProfile(updatedProfile);
      props.userProfileSetter(updatedProfile);
      props.hidePopup();
    } catch (error) {
      console.error("Failed to save changes:", error);
    }
  };

  let trainings = shadowUserProfile.Trainings;

  return (
    <>
      <div className="edit-dialog--header form--heading--wrapper">
        <h2 className="form--heading">{t("TrainingsAndCourses")}</h2>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-edit-popup" onClick={() => props.hidePopup()}></ChromeCloseIcon>
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          trainings: trainings || [],
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveNewValues(values.trainings);
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, isValid, errors }) => (
          <Form>
            <div>
              <FieldArray
                name="trainings"
                render={(arrayHelpers) => (
                  <div>
                    {values?.trainings !== undefined && values.trainings !== null && values?.trainings.length > 0 && (
                      <div className="form--container large mb-16">
                        {values.trainings.map((item, index) => {
                          const sinceDate = !!item.Since && new Date(item.Since);
                          const sinceDateFormatted = !!sinceDate ? format(sinceDate, "LLL yyyy") : "";

                          return (
                            <Fragment key={index}>
                              <div className="form--fields-container item-row" key={index}>
                                <div className="form--fields--header">
                                  <div className="item--preview-data">
                                    <p className="text-style--Body1 fw-600 text-color--brand80">{item.Name || ""}</p>
                                    <p className="text-style--Body1 fw-600 text-color--brand10">{item.Issuer}</p>
                                    {!!sinceDateFormatted && <p className="text-style--Body1 text-color--brand10 mb-16">{sinceDateFormatted}</p>}
                                  </div>
                                  {newItemIndex !== index && (
                                    <div className="form--item--actions">
                                      <DeliwiButton className={visibleIndex === index ? "button-style--outline button-size--medium bg-white active" : "button-style--outline button-size--medium bg-white"} onClick={() => toggleGroupVisibility(index)} disabled={!isValid}>
                                        {visibleIndex === index ? (
                                          <>
                                            <IconEditOff className="icon-size--16" />
                                            {t("CloseEdit")}
                                          </>
                                        ) : (
                                          <>
                                            <IconEdit className="icon-size--16" />
                                            {t("Edit")}
                                          </>
                                        )}
                                      </DeliwiButton>
                                      <DeliwiButton
                                        className="button-style--outline button-size--medium bg-white"
                                        onClick={() => {
                                          setIndexToDelete(index);
                                          setItemNameToDelete(item.Name);
                                          setItemTypeToDelete("Training");
                                          setItemTypeToDeletePlural("Trainings");
                                          setConfirmDialogOpen(true);
                                        }}
                                      >
                                        <IconTrash className="icon-size--16" />
                                        {t("Delete")}
                                      </DeliwiButton>
                                    </div>
                                  )}
                                </div>
                                {visibleIndex === index && (
                                  <div className="form--fields-container-grid">
                                    <div className="form--field--wrapper">
                                      <TextField label={t("Name")} required={true} name={`trainings.${index}.Name`} defaultValue={item.Name} onChange={handleChange} onBlur={handleBlur} />
                                      {(errors?.trainings as FormikErrors<ITrainingsFormProps>[])?.[index]?.Name && <div className="input-error">{(errors?.trainings as FormikErrors<ITrainingsFormProps>[])?.[index]?.Name}</div>}
                                    </div>
                                    <div className="form--field--wrapper">
                                      <TextField label={t("OrganizerOrTrainer")} required={true} name={`trainings.${index}.Issuer`} defaultValue={item.Issuer} onChange={handleChange} onBlur={handleBlur} />
                                      {(errors?.trainings as FormikErrors<ITrainingsFormProps>[])?.[index]?.Issuer && <div className="input-error">{(errors?.trainings as FormikErrors<ITrainingsFormProps>[])?.[index]?.Issuer}</div>}
                                    </div>

                                    <div className="form--field--wrapper">
                                      <label className="field-label">{t("CompletedDate")} *</label>
                                      <DatePicker
                                        selected={!!item.Since ? new Date(item.Since) : undefined}
                                        onChange={(date) => {
                                          if (date instanceof Date) {
                                            const dateValue = format(date, "yyyy-MM-dd'T'HH:mm:ss");
                                            setFieldValue(`trainings.${index}.Since`, dateValue);
                                          }
                                        }}
                                        dateFormat="MM/yyyy"
                                        showMonthYearPicker
                                        onBlur={() => setFieldTouched(`trainings.${index}.Since`, true)}
                                      />
                                      {(errors?.trainings as FormikErrors<ITrainingsFormProps>[])?.[index]?.Since && <div className="input-error">{(errors?.trainings as FormikErrors<ITrainingsFormProps>[])?.[index]?.Since}</div>}
                                    </div>
                                  </div>
                                )}
                                {newItemIndex === index && (
                                  <div className="new-item--actions">
                                    <DeliwiButton
                                      className="button-style--secondary button-size--medium"
                                      onClick={() => {
                                        setNewItemIndex(null);
                                        arrayHelpers.remove(index);
                                        toggleGroupVisibility(null);
                                      }}
                                    >
                                      <IconDismiss className="icon-size--12" />
                                      {t("Cancel")}
                                    </DeliwiButton>
                                    <DeliwiButton
                                      className="button-style--primary button-size--medium"
                                      onClick={() => {
                                        setNewItemIndex(null);
                                        toggleGroupVisibility(index);
                                      }}
                                      disabled={!isValid}
                                    >
                                      <IconAdd className="icon-size--16" />
                                      {t("Add")}
                                    </DeliwiButton>
                                  </div>
                                )}
                                {confirmDialogOpen && index === indexToDelete && (
                                  <ConfirmDeleteRow
                                    confirmDialogVisible={confirmDialogOpen}
                                    setConfirmDialogVisible={(visible) => {
                                      setConfirmDialogOpen(visible);
                                      if (!visible) {
                                        setIndexToDelete(null);
                                      }
                                    }}
                                    onConfirm={() => {
                                      arrayHelpers.remove(indexToDelete);
                                      setConfirmDialogOpen(false);
                                      setIndexToDelete(null);
                                    }}
                                  />
                                )}
                              </div>
                            </Fragment>
                          );
                        })}
                      </div>
                    )}
                    <DeliwiButton
                      className="button-style--newitem mb-40 ml-24"
                      onClick={() => {
                        let newIndexToOpen;
                        if (values.trainings === null) {
                          newIndexToOpen = 0;
                        } else {
                          newIndexToOpen = values.trainings.length;
                        }
                        setNewItemIndex(newIndexToOpen);
                        arrayHelpers.push({ Name: "", Issuer: "", Since: "" });
                        toggleGroupVisibility(newIndexToOpen);
                      }}
                    >
                      <IconAdd />
                      {t("AddTrainingOrCourse")}
                    </DeliwiButton>
                  </div>
                )}
              />
            </div>
            <div className="edit-dialog--buttons">
              <DeliwiButton className="button-style--outline button-size--large" onClick={() => props.hidePopup()}>
                <IconClose className="icon-size--20" />
                {t("Cancel")}
              </DeliwiButton>
              <DeliwiButton
                type="submit"
                className="button-style--primary button-size--large"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={props.saving}
              >
                <IconCheckmark />
                {t("SaveChanges")}
              </DeliwiButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
