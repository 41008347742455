import { useRef, useState, FormEvent, ChangeEvent } from "react";
import { t } from "i18next";
import { IPersonaProps } from "@fluentui/react/lib/Persona";
import { PeoplePicker, PeoplePickerRef } from "components/ControlPanel/PeoplePicker";
import { DepartmentPicker } from "components/ControlPanel/DepartmentPicker";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import { IUser, IUserData } from "interfaces/IUser";
import { SingleSkillPicker } from "components/ControlPanel/SingleSkillPicker";
import { getUserProfiles, getUserProfilesByDepartment } from "services/apiService";
import { IconBackArrow } from "components/Icons/IconBackArrow";
import { IconRunAnalysis } from "components/Icons/IconRunAnalysis";
import { DeliwiButton } from "components/DeliwiButton";
import { HelpDialog } from "components/HelpDialog";
import { SurveyReport } from "components/ControlPanel/SurveyReport";
import { StepIndicator } from "components/ControlPanel/StepIndicator";
import { SurveyPreview } from "components/ControlPanel/SurveyPreview";
import { SurveyMessagePreview } from "components/ControlPanel/SurveyMessagePreview";

// Icons imports
import IconUserCheckmark from "assets/icons/icon-user-checkmark.svg";
import IconHat from "assets/icons/icon-analyses-skillhat.svg";
import { IconPieBtn } from "components/Icons/IconPieBtn";
import { IconAdd } from "components/Icons/IconAdd";

interface activeSurveyReportsProps {
  makeSurveyReportsActive: () => void;
}

export const CreateSurvey = ({ makeSurveyReportsActive }: activeSurveyReportsProps) => {
  const peoplePickerRef = useRef<PeoplePickerRef>(null);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [surveyType, setSurveyType] = useState<string>("all");
  const [surveyTitle, setSurveyTitle] = useState<string>("");
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const [selectedSkill, setSelectedSkill] = useState<string>("");
  const [selectedSurveyUsers, setSelectedSurveyUsers] = useState<IUser[]>([]);
  const [openSurveySendModal, setOpenSurveySendModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createdSurveyId, setCreatedSurveyId] = useState<string>("");

  const handleUserSelection = (users: IPersonaProps[]) => {
    const newSelectedSurveyUsers: IUser[] = users.map((user) => ({
      DisplayName: user.text || "",
      Id: user.key as string,
      Type: user.secondaryText || "",
    }));
    setSelectedSurveyUsers(newSelectedSurveyUsers);
  };

  const handleReturnToStep1 = () => {
    setSelectedSurveyUsers([]);
    setSelectedDepartment("");
    setSelectedSkill("");
    setSurveyTitle("");
    setCurrentStep(1);
  };

  const handleDepartmentSelection = (department: string) => {
    setSelectedDepartment(department);
  };

  const handleReturnToTable = () => {
    makeSurveyReportsActive();
  };

  const handleRadioChange = (_: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption) => {
    if (option) {
      setSurveyType(option.key);
    }
  };

  const radioOptions: IChoiceGroupOption[] = [
    { key: "all", text: "All users" },
    { key: "department", text: "By department" },
    { key: "users", text: "Specific users" },
  ];

  const fetchAllUserProfiles = async (): Promise<IUser[]> => {
    let offset = 0;
    const usersPerPage = 50;
    let allUsers: IUser[] = [];

    try {
      let response = await getUserProfiles(offset, usersPerPage);
      if (!response || !response.Data) {
        throw new Error("Initial fetch failed");
      }

      const totalPages = Math.ceil(response.TotalCount / usersPerPage);
      allUsers.push(...mapUserProfiles(response.Data));

      for (let page = 1; page < totalPages; page++) {
        offset = page * usersPerPage;
        response = await getUserProfiles(offset, usersPerPage);
        if (response && response.Data) {
          allUsers.push(...mapUserProfiles(response.Data));
        } else {
          throw new Error(`Failed to fetch user profiles at offset ${offset}`);
        }
      }
    } catch (error) {
      console.error("Failed to fetch user profiles:", error);
    }

    return allUsers;
  };

  const fetchDepartmentUserProfiles = async (): Promise<IUser[]> => {
    let allUsers: IUser[] = [];

    console.log(selectedDepartment);

    try {
      let response = await getUserProfilesByDepartment(selectedDepartment);
      if (!response || !response.Data) {
        throw new Error("Initial fetch failed");
      }
      allUsers.push(...mapUserProfiles(response.Data));
    } catch (error) {
      console.error("Failed to fetch user profiles:", error);
    }

    return allUsers;
  };

  const mapUserProfiles = (users: IUserData[]): IUser[] => {
    return users.map((user) => ({
      DisplayName: user.FirstName && user.LastName ? `${user.FirstName} ${user.LastName}` : user.Email,
      Id: user.Id,
      Type: "", // Assuming Type is always an empty string as per the given mapping example
    }));
  };

  const handleSetUsers = async () => {
    setIsLoading(true);

    if (surveyType === "all") {
      const allUsers = await fetchAllUserProfiles();
      const uniqueUsersMap = new Map<string, IUser>(allUsers.map((user) => [user.Id, user]));
      const uniqueUsers = Array.from(uniqueUsersMap.values());
      setSelectedSurveyUsers(uniqueUsers);
    } else if (surveyType === "department") {
      const allUsers = await fetchDepartmentUserProfiles();
      const uniqueUsersMap = new Map<string, IUser>(allUsers.map((user) => [user.Id, user]));
      const uniqueUsers = Array.from(uniqueUsersMap.values());
      setSelectedSurveyUsers(uniqueUsers);
    }

    setOpenSurveySendModal(true);

    setIsLoading(false);
  };

  const handleSurveyTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSurveyTitle(event.target.value);
  };

  const isContinueEnabled = surveyType === "all" || selectedSurveyUsers.length > 0 || selectedDepartment.length > 0;

  const renderStepsContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="admin-box__iconset mb-24">
              <img src={IconUserCheckmark} className="icon-size-62 icon-shadow" alt="" />
              <h4 className="text-style--Title2 text-style--semibold">{t("SelectSurveyUsers")}</h4>
            </div>
            <div className="info-text mb-40">
              <p className="text-style--Subtitle2 text-style--semibold" dangerouslySetInnerHTML={{ __html: t("SurveyExplanation") }} />
            </div>
            <ChoiceGroup className="admin-survey__radios mb-24" selectedKey={surveyType} options={radioOptions} onChange={handleRadioChange} />

            {surveyType === "users" && (
              <div className="mb-40">
                <p className="text-style--Body2 text-color--grey44 mb-8">{t("SelectUsers")} *</p>
                <PeoplePicker ref={peoplePickerRef} onUserSelect={handleUserSelection} pickerType="analysisusers" />
              </div>
            )}

            {surveyType === "department" && (
              <div className="mb-40">
                <p className="text-style--Body2 text-color--grey44 mb-8">{t("SelectDepartment")} *</p>
                <DepartmentPicker onDepartmentSelect={handleDepartmentSelection} />
              </div>
            )}
            <div className="admin-survey__buttons">
              <DeliwiButton disabled={!isContinueEnabled} className="button-style--primary button-size--large ml-auto" onClick={() => setCurrentStep(2)}>
                <IconPieBtn className="icon-size--20" />
                {t("Continue")}
              </DeliwiButton>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="admin-survey">
              <div className="admin-box__iconset mb-40">
                <img src={IconHat} className="icon-size-62 icon-shadow" alt="" />
                <h4 className="text-style--Title2 text-style--semibold">{t("EnterTheSurveySkill")}</h4>
              </div>
              <div className="admin-survey__steps--skills mb-40">
                <div className="admin-survey__steps--skills-select">
                  <HelpDialog className="mb-24" dialogContent={t("Help.SurveyTitle.Content")} title={t("Help.SurveyTitle.Title")}>
                    <h4 className="text-style--Subtitle1 text-color--brand80">{t("EnterTitleAndSkill")}</h4>
                  </HelpDialog>
                  <div className="survey-title-field">
                    <label htmlFor="surveytitle" className="field-label mb-8">
                      {t("SurveyTitle")}*
                    </label>
                    <input type="text" name="surveytitle" placeholder={t("Title")} value={surveyTitle} onChange={handleSurveyTitleChange} className="mb-24" />
                  </div>
                  <SingleSkillPicker mode="survey" onSkillSelect={setSelectedSkill} />
                  <div className="info-text mt-40">
                    <p className="text-style--semibold">{t("StartTypingSkillNameAndSelect")}</p>
                    <p className="text-style--semibold">{t("Or")}</p>
                    <p className="text-style--semibold">{t("TypeTheSkillNameIfItIsNotFound")}</p>
                  </div>
                </div>
                <div className="admin-survey__steps--skills-divider"></div>
                <div className="admin-survey__steps--skills-msg">
                  <HelpDialog className="mb-24" dialogContent={t("Help.SurveyTitle.Content")} title={t("Help.SurveyTitle.Title")}>
                    <h4 className="text-style--Subtitle1 text-color--brand80">{t("MessagePreview")}</h4>
                  </HelpDialog>
                  <SurveyMessagePreview skillName={selectedSkill} />
                </div>
              </div>
              <div className="admin-survey__buttons">
                <DeliwiButton className="button-style--outline button-size--large" onClick={handleReturnToStep1}>
                  <IconBackArrow className="icon-size--18" />
                  {t("Back")}
                </DeliwiButton>
                {isLoading ? (
                  <div className="ml-auto">
                    <div className="deliwi-spinner"></div>
                  </div>
                ) : (
                  <DeliwiButton className="button-style--primary button-size--large ml-auto" onClick={handleSetUsers} disabled={!selectedSkill || !surveyTitle}>
                    <IconRunAnalysis className="icon-size--18" />
                    {t("PreviewSurvey")}
                  </DeliwiButton>
                )}
              </div>
            </div>
            <SurveyPreview isOpen={openSurveySendModal} setIsOpen={setOpenSurveySendModal} setCreatedSurveyId={setCreatedSurveyId} surveyTitle={surveyTitle} surveySkill={selectedSkill} selectedSurveyUsers={selectedSurveyUsers} nextStep={3} setNextStep={setCurrentStep} />
          </>
        );
      case 3:
        return (
          <>
            <SurveyReport surveyId={createdSurveyId} surveySkill={selectedSkill} surveyStatus={0} surveyAnswerCount={0} surveyPersonCount={selectedSurveyUsers.length} onReturnToTable={handleReturnToTable} />
            <div className="admin-survey__buttons">
              <DeliwiButton
                className="button-style--primary button-size--large ml-auto"
                onClick={() => {
                  setCurrentStep(1);
                  setCreatedSurveyId("");
                  setSurveyType("all");
                  setSelectedSkill("");
                  setSurveyTitle("");
                  setSelectedDepartment("");
                  setSelectedSurveyUsers([]);
                }}
              >
                <IconAdd className="icon-size--18" />
                {t("RunNewSurvey")}
              </DeliwiButton>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return <div className="deliwi-spinner"></div>;
  }

  return (
    <>
      <StepIndicator currentStep={currentStep} totalSteps={3} stepTitles={[t("SelectUsers"), t("EnterSkillAndRunSurvey"), t("Results")]} />
      {renderStepsContent()}
    </>
  );
};
