import { t } from "i18next";

const noResultsImage = require("../../assets/images/no-results.png");

export const NoResults = () => {
  return (
    <div className="no-results--wrapper">
      <img alt="No results" src={noResultsImage} className="no-results--image" />
      <div className="no-results--text-content">
        <h3 className="text-style--Subtitle1 text-color--black">{t("NoResultsLabel")}</h3>
        <p className="text-style--Body1">
          {t("NoResultsText1")}
          {t("NoResultsText2")}
        </p>
      </div>
    </div>
  );
};
