import { Label, TextField } from "@fluentui/react";
import { DeliwiButton } from "components/DeliwiButton";
import { IAIRewrittenFieldsOriginalValues, IUserProfile, IUserProfileEditForm } from "../../../interfaces/IUserProfile";
import { t } from "i18next";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { IconCheckmark } from "components/Icons/IconCheckmark";
import { IconClose } from "../../Icons/IconClose";
import { useState } from "react";
import { rewriteTextWithAI } from "../../../services/apiService";
import { IconShape } from "../../Icons/IconShape";
import { IconUndo } from "../../Icons/IconUndo";
import { HelpDialog } from "../../HelpDialog";

export interface ICurrentWorkFormProps {
  JobDescription: string;
}

export const UserCurrentWorkEdit = (props: IUserProfileEditForm) => {
  const shadowUserProfile = props.userProfileData;
  const [aiRewrittenFields, setAiRewrittenFields] = useState<IAIRewrittenFieldsOriginalValues[]>([]);
  const [fieldUnderRewriting, setFieldUnderRewriting] = useState<string>("");

  const getItemFromRewrittenFields = (fieldName: string): IAIRewrittenFieldsOriginalValues | undefined => aiRewrittenFields?.find((item) => item.fieldName === fieldName);

  const validationSchema = Yup.object({});

  const saveNewValues = async (values: ICurrentWorkFormProps) => {
    console.log("saving values");
    const updatedProfile = {
      ...shadowUserProfile,
      JobDescription: values.JobDescription,
    } as IUserProfile;

    try {
      await props.saveProfile(updatedProfile);
      props.userProfileSetter(updatedProfile);
      props.hidePopup();
    } catch (error) {
      console.error("Failed to save changes:", error);
    }
  };

  return (
    <>
      <div className="edit-dialog--header form--heading--wrapper large">
        <h2 className="form--heading">{t("JobDescription")}</h2>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-edit-popup" onClick={() => props.hidePopup()}></ChromeCloseIcon>
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          JobDescription: shadowUserProfile?.JobDescription || "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveNewValues(values);
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, isValid, errors }) => (
          <Form>
            <div className="form--container mb-40">
              <div className="form--fields-container form--fields-container-flexcol">
                <div className="form--fields-container flex-column">
                  <div className="form--field--wrapper">
                    <div className="field-with-ai-rewrite field-with-ai-rewrite--wrapper">
                      <TextField label={t("ShortJobDescription")} id="JobDescription" name="JobDescription" multiline autoAdjustHeight rows={2} value={values.JobDescription} onChange={handleChange} onBlur={handleBlur} />
                      {fieldUnderRewriting === "JobDescription" && (
                        <div className="search-loader--wrapper">
                          <span className="deliwi-spinner mb-16"></span>
                        </div>
                      )}
                    </div>

                    <div className="field-with-ai-rewrite--actions">
                      {!getItemFromRewrittenFields("JobDescription") ? (
                        <HelpDialog className="" dialogContent={t("Help.EditForms.RewriteWithAI.Content")} title={t("Help.EditForms.RewriteWithAI.Title")}>
                          <DeliwiButton
                            className="button-style--candy-border button-size--medium bg-white"
                            disabled={!values.JobDescription || values.JobDescription.length < 5}
                            onClick={async () => {
                              setFieldUnderRewriting("JobDescription");
                              const response = await rewriteTextWithAI("JobDescription", values.JobDescription);
                              if (!!response?.RewrittenText) {
                                setAiRewrittenFields([
                                  ...aiRewrittenFields,
                                  {
                                    fieldName: "JobDescription",
                                    value: values.JobDescription,
                                  },
                                ]);
                                setFieldValue("JobDescription", response.RewrittenText);
                              }
                              setFieldUnderRewriting("");
                            }}
                          >
                            <IconShape />
                            {t("RewriteWithAI")}
                          </DeliwiButton>
                        </HelpDialog>
                      ) : (
                        <DeliwiButton
                          className="button-style--outline button-size--medium"
                          onClick={() => {
                            const originalValueItem = getItemFromRewrittenFields("JobDescription");
                            setFieldValue("JobDescription", originalValueItem?.value);
                            const updatedRewrittenFields = aiRewrittenFields.filter((fieldItem) => fieldItem.fieldName !== "JobDescription");
                            setAiRewrittenFields(updatedRewrittenFields);
                          }}
                        >
                          <IconUndo />
                          {t("Undo")}
                        </DeliwiButton>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-dialog--buttons">
              <DeliwiButton className="button-style--outline button-size--large" onClick={() => props.hidePopup()}>
                <IconClose className="icon-size--20" />
                {t("Cancel")}
              </DeliwiButton>
              <DeliwiButton type="submit" className="button-style--primary button-size--large" disabled={props.saving}>
                <IconCheckmark />
                {t("SaveChanges")}
              </DeliwiButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
