import { useNavigate } from "react-router";
import { t } from "i18next";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Link, Stack } from "@fluentui/react";
import deliwiLogo from "../assets/images/deliwi.png";
import { loginRequest } from "../msalConfig";
import { IconAiSearch } from "./Icons/IconAiSearch";
import { IconMyProfile } from "./Icons/IconMyProfile";
import { IconLogout } from "./Icons/IconLogout";
import { IconControlPanel } from "./Icons/IconControlPanel";
import { useUserRole } from "contexts/UserRoleContext";
import { useLocation } from "react-router-dom";
import "assets/styles/components/SideBar.scss";
import { IconHelpCenter } from "./Icons/IconHelpCenter";

export const SideBar = () => {
  const { instance } = useMsal();
  const { userRole } = useUserRole();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const navigateTo =
    (path: string, isLogout: boolean = false) =>
    () => {
      if (isLogout) {
        instance.logoutRedirect({ postLogoutRedirectUri: "/" });
      } else {
        navigate(path);
      }
    };

  const links = [
    {
      path: "/",
      icon: <IconAiSearch className="sidebar-nav__links--link-icon ai-search" />,
      textKey: t("Nav.AISearch"),
      roles: ["any"],
    },
    {
      path: "/my-profile",
      icon: <IconMyProfile className="sidebar-nav__links--link-icon" />,
      textKey: t("Nav.MyProfile"),
      roles: ["user", "superuser", "admin"],
    },
    {
      path: "/control-panel",
      icon: <IconControlPanel className="sidebar-nav__links--link-icon" />,
      textKey: t("Nav.ControlPanel"),
      roles: ["superuser", "admin"],
    },
    {
      path: "/help-center",
      icon: <IconHelpCenter className="sidebar-nav__links--link-icon" />,
      textKey: t("Nav.HelpCenter"),
      roles: ["user", "superuser", "admin"],
    },
    {
      path: "/logout",
      icon: <IconLogout className="sidebar-nav__links--link-icon" />,
      textKey: t("Nav.LogOut"),
      roles: ["any"],
      isLogout: true,
    },
  ];

  const isRoleAllowed = (roles: string[]) => roles.some((role: string) => role === "any" || userRole.includes(role));

  return (
    <Stack className="sidebar-nav">
      <a href="/" className="sidebar-logo">
        <img src={deliwiLogo} alt="Deliwi AI" />
      </a>
      <AuthenticatedTemplate>
        <Stack enableScopedSelectors className="sidebar-nav__links">
          {links
            .filter((link) => isRoleAllowed(link.roles))
            .map((link) => (
              <Link key={link.path} className={`sidebar-nav__links--link ${currentPath === link.path ? "active" : ""} ${link.isLogout ? "link-logout" : ""}`} onClick={navigateTo(link.path, link.isLogout)}>
                {link.icon}
                <span>{link.textKey}</span>
              </Link>
            ))}
        </Stack>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Link className="sidebar-nav__links--link link-logout" onClick={() => instance.loginRedirect(loginRequest)}>
          <IconLogout className="sidebar-nav__links--link-icon" />
          <span>{t("SignIn")}</span>
        </Link>
      </UnauthenticatedTemplate>
    </Stack>
  );
};
