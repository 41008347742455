import Size32ThemeRegular4 from "../assets/icons/Size32ThemeRegular4";
import "assets/styles/components/MatchMeter.scss";

export interface IMatchMeterProps {}

export const MatchMeter = (props: IMatchMeterProps) => {
  return (
    <div className="match-meter">
      <div className="match-meter-label">
        <span>It's a match</span> <Size32ThemeRegular4 className="match-meter-icon" color="#008379" />
      </div>
      <div className="match-meter-slider" />
    </div>
  );
};
