export const isNullOrEmpty = (value: any) => {
  return value === null || value === undefined || (typeof value === "string" && value === "") || (Array.isArray(value) && value.length === 0);
}

export const isValidPhoneNumber = (value: string) => {
  return RegExp(/^\+?\d*$/).exec(value);
}

export const isValidEmailAddress = (value: string) => {
  return RegExp(/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/i).exec(value);
}

export const isValidImageUrl = (value: string) => {
  return RegExp(/^http(s)?:\/{2}.*\.(jpeg|jpg|gif|png)$/).exec(value) != null;
}