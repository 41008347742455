import { useRef, useState, useEffect } from "react";

import { t } from "i18next";

import { IPersonaProps } from "@fluentui/react/lib/Persona";
import { PeoplePicker, PeoplePickerRef } from "components/ControlPanel/PeoplePicker";
import { AddUsersButton } from "components/ControlPanel/AddUsersButton";
import { Collapsible } from "components/Collapsible";
import { Dialog, DialogFooter } from "@fluentui/react";
import { IUser, FailedInvite } from "interfaces/IUser";
import { DeliwiButton } from "components/DeliwiButton";
import { IconPlus } from "components/Icons/IconPlus";

import IconAddUsers from "assets/icons/icon-add-users.svg";
import { IconArrowForward } from "components/Icons/IconArrowForward";
import "assets/styles/components/UsersListPagination.scss";
import { HelpDialog } from "../HelpDialog";

interface PeoplePickerDialogProps {
  simpleView?: boolean; // true means only show the "Add New Users" button
}

export const PeoplePickerDialog = ({ simpleView = false }: PeoplePickerDialogProps) => {
  const [currentStep, setCurrentStep] = useState(1); // Step tracking
  const [fillWidth, setFillWidth] = useState("0%"); // State for the progress bar's fill width
  const [isAddUsersModalOpen, setIsAddUsersModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [failedInvites, setFailedInvites] = useState<FailedInvite[]>([]);

  const peoplePickerRef = useRef<PeoplePickerRef>(null);
  const totalSteps = 3;

  const calculateFillWidth = (currentStep: number, totalSteps: number): string => {
    // If it's the last step, return 100%
    if (currentStep === totalSteps) {
      return "100%";
    }
    // For other steps, calculate to stop in the middle of the step,
    // subtract 0.5 from the current step
    return `${((currentStep - 0.5) / totalSteps) * 100}%`;
  };

  useEffect(() => {
    const newFillWidth = calculateFillWidth(currentStep, totalSteps);
    setFillWidth(newFillWidth); // Update the fill width state
  }, [currentStep]); // Dependency array ensures this effect runs only when currentStep changes

  const openModal = () => setIsAddUsersModalOpen(true);

  const closeModal = () => {
    setIsAddUsersModalOpen(false); // Close the modal
    setCurrentStep(1); // Reset the step to 1
    if (peoplePickerRef.current) {
      peoplePickerRef.current.clearSelection(); // Clear PeoplePicker selection if needed
    }
  };

  const clearPeoplePicker = (failedInvitesParam?: FailedInvite[]) => {
    if (failedInvitesParam && failedInvitesParam.length > 0) {
      console.log(failedInvites);

      setFailedInvites(failedInvitesParam);
      // Handle the display of failed invites here
      // Example: Set state to show an error message with the details of failed invites
    }

    if (peoplePickerRef.current) {
      peoplePickerRef.current.clearSelection();
    }
  };

  const handleUserSelection = (users: IPersonaProps[]) => {
    const selectedUsers = users.map((user) => ({
      DisplayName: user.text as string,
      Id: user.key as string,
      Type: user.optionalText as string,
      UserPrincipalName: user.secondaryText as string,
    }));
    setSelectedUsers(selectedUsers);
  };

  const renderStepIndicator = () => {
    const stepTitles = [t("SelectUsers"), t("SendingInvitations"), t("Success")];

    return (
      <div>
        <div className="admin-addusers__steps">
          {stepTitles.map((title, index) => {
            const isCompleted = index < currentStep;
            const isActive = index === currentStep - 1;
            return (
              <div key={title} className={`text-style--Subtitle2 text-style--bold step ${isActive ? "active" : ""} ${isCompleted ? "completed" : ""}`}>
                <span className="step-label">{title}</span>
              </div>
            );
          })}
        </div>
        <div className="admin-addusers__bar">
          <div className="admin-addusers__bar--fill" style={{ width: fillWidth }}></div>
        </div>
      </div>
    );
  };

  const renderDialogContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="box-gray mb-32">
              <HelpDialog dialogContent={t("Help.AddUsers.Content")} title={t("Help.AddUsers.Title")} className="mb-16">
                <p className="text-style--Body1 text-style--semibold">{t("SelectUserGroupsOrPeople")}</p>
              </HelpDialog>
              <PeoplePicker ref={peoplePickerRef} onUserSelect={handleUserSelection} />
            </div>
            <DialogFooter>
              <AddUsersButton users={selectedUsers} onUsersAdded={clearPeoplePicker} setCurrentStep={setCurrentStep} />
            </DialogFooter>
          </>
        );
      case 2:
        return (
          <div className="box-gray mb-32 admin-addusers__steps--invitation">
            <HelpDialog dialogContent={t("Help.InvitationMessages.Content")} title={t("Help.InvitationMessages.Title")} className="mb-48 mr-auto">
              <p className="text-style--Body1 text-style--semibold">{t("Help.InvitationMessages.Title")}</p>
            </HelpDialog>
            <h2 className="text-style--Subtitle1 mb-16">{t("AddingUsers")}</h2>
            <div className="deliwi-spinner"></div>
          </div>
        );
      case 3:
        return (
          <>
            <div className="admin-addusers__success--top text-align--center mt-32">
              <h2 className="text-style--Main-title text-color--brand80 mb-16">{t("UsersAdded")}</h2>
              <p className="text-style--Body1 text-color--body2 mb-24">{t("UsersAddedAndEmailsSent")}</p>
              {failedInvites.length > 0 && (
                <div className="failed-invites-info">
                  <h3 className="text-style--Body1  mb-8">{t("FailedInvitesTitle")}</h3>
                  <ul className="text-style--Body2 mb-40">
                    {failedInvites.map((invite) => (
                      <li key={invite.Id}>{`${invite.DisplayName || t("name not set")} (${invite.UserPrincipalName || t("email not set")}) - ${t("FailedInviteReason")}`}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="box-gray mt-16">
              <h3 className="text-style--Title3 fw-600 mb-16 text-color--brand10">{t("ManageAccountsInList")}</h3>
              <div className="admin-addusers__success--buttonrow mb-24">
                <p className="text-style--Body1 text-color--brand10">{t("UsersAreNowListed")}</p>
                <DeliwiButton onClick={closeModal} className="button-style--primary button-size--large ml-auto">
                  <IconArrowForward />
                  {t("CheckTheUserList")}
                </DeliwiButton>
              </div>
              <div className="admin-addusers__success--info">
                <p className="text-style--Body1 fw-600 text-color--white">{t("AddedUserLevelInfo")}</p>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  if (simpleView) {
    return (
      <>
        <DeliwiButton className="button-style--primary button-size--large" text={t("AddNewUsers")} onClick={openModal}>
          <IconPlus />
        </DeliwiButton>
        {isAddUsersModalOpen && (
          <Dialog
            hidden={!isAddUsersModalOpen}
            onDismiss={closeModal}
            minWidth={640}
            dialogContentProps={{
              showCloseButton: true,
              title: t("AddUsersToDeliwi"),
            }}
            modalProps={{
              className: "admin-dialog admin-addusers",
            }}
          >
            {renderStepIndicator()}
            {renderDialogContent()}
          </Dialog>
        )}
      </>
    );
  }

  return (
    <>
      <div className="admin-box__graywrap mt-48 mb-24">
        <div className="admin-box__iconset admin-box__iconset-alignstart">
          <img src={IconAddUsers} className="icon-size-62 icon-shadow" alt="" />
          <Collapsible title={t("HowAndWhyAddPoepleToDeliwi")}>
            <p className="text-style--Body1">{t("AddNewUsersInfo1")}</p>
            <p className="text-style--Body1">{t("AddNewUsersInfo2")}</p>
            <p className="text-style--Body1">{t("AddNewUsersInfo3")}</p>
          </Collapsible>
        </div>
      </div>
      <DeliwiButton className="button-style--primary button-size--large" text={t("AddNewUsers")} onClick={openModal}>
        <IconPlus />
      </DeliwiButton>

      {isAddUsersModalOpen && (
        <Dialog
          hidden={!isAddUsersModalOpen}
          onDismiss={closeModal}
          minWidth={640}
          dialogContentProps={{
            showCloseButton: true,
            title: t("AddUsersToDeliwi"),
          }}
          modalProps={{
            className: "admin-dialog admin-addusers",
          }}
        >
          {renderStepIndicator()}
          {renderDialogContent()}
        </Dialog>
      )}
    </>
  );
};
