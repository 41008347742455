import { FocusTrapZone, Layer, Overlay, Popup } from "@fluentui/react";
import { IUserProfile } from "../../interfaces/IUserProfile";
import { UserProfileInformationEdit } from "./UserEditForms/UserProfileInformationEdit";
import { UserCompanyInformationEdit } from "./UserEditForms/UserCompanyInformationEdit";
import { UserCurrentWorkEdit } from "./UserEditForms/UserCurrentWorkEdit";
import { UserWorkHistoryEdit } from "./UserEditForms/UserWorkHistoryEdit";
import { UserFutureDevelopmentEdit } from "./UserEditForms/UserFutureDevelopmentEdit";
import { UserRecentProjectsEdit } from "./UserEditForms/UserRecentProjectsEdit";
import { UserSkillsEdit } from "./UserEditForms/UserSkillsEdit";
import { UserEducationEdit } from "./UserEditForms/UserEducationEdit";
import { UserCertificationsEdit } from "./UserEditForms/UserCertificationsEdit";
import { UserAwardsEdit } from "./UserEditForms/UserAwardsEdit";
import { UserTrainingsEdit } from "./UserEditForms/UserTrainingsEdit";
import { UserLanguageSkillsEdit } from "./UserEditForms/UserLanguageSkillsEdit";
import "../../assets/styles/components/Forms.scss";
import { UserMyInterestsEdit } from "./UserEditForms/UserMyInterestsEdit";
import { UserMyCareerEdit } from "./UserEditForms/UserMyCareerEdit";
import { UserLinkedInEdit } from "./UserEditForms/UserLinkedInEdit";

export interface IUserProfileEditProps {
  userProfile: IUserProfile;

  userProfileSetter(data: IUserProfile): void;

  hidePopup(): void;

  showPopup(): void;

  fieldGroupToEdit: string | undefined;

  saveProfile(data: IUserProfile): void;

  saving: boolean;
}

export const UserProfileEdit = (props: IUserProfileEditProps) => {
  const fieldGroupToEdit = props.fieldGroupToEdit;

  const editForm = () => {
    switch (fieldGroupToEdit) {
      case "personal-information":
        return <UserProfileInformationEdit userProfileData={props.userProfile} userProfileSetter={props.userProfileSetter} showPopup={props.showPopup} hidePopup={props.hidePopup} saveProfile={props.saveProfile} saving={props.saving} />;
      case "company-information":
        return <UserCompanyInformationEdit userProfileData={props.userProfile} userProfileSetter={props.userProfileSetter} showPopup={props.showPopup} hidePopup={props.hidePopup} saveProfile={props.saveProfile} saving={props.saving} />;
      case "future-development":
        return <UserFutureDevelopmentEdit userProfileData={props.userProfile} userProfileSetter={props.userProfileSetter} showPopup={props.showPopup} hidePopup={props.hidePopup} saveProfile={props.saveProfile} saving={props.saving} />;
      case "current-work":
        return <UserCurrentWorkEdit userProfileData={props.userProfile} userProfileSetter={props.userProfileSetter} showPopup={props.showPopup} hidePopup={props.hidePopup} saveProfile={props.saveProfile} saving={props.saving} />;
      case "my-career":
        return <UserMyCareerEdit userProfileData={props.userProfile} userProfileSetter={props.userProfileSetter} showPopup={props.showPopup} hidePopup={props.hidePopup} saveProfile={props.saveProfile} saving={props.saving} />;
      case "recent-projects":
        return <UserRecentProjectsEdit userProfileData={props.userProfile} userProfileSetter={props.userProfileSetter} showPopup={props.showPopup} hidePopup={props.hidePopup} saveProfile={props.saveProfile} saving={props.saving} />;
      case "work-history":
        return <UserWorkHistoryEdit userProfileData={props.userProfile} userProfileSetter={props.userProfileSetter} showPopup={props.showPopup} hidePopup={props.hidePopup} saveProfile={props.saveProfile} saving={props.saving} />;
      case "skills":
        return <UserSkillsEdit userProfileData={props.userProfile} userProfileSetter={props.userProfileSetter} showPopup={props.showPopup} hidePopup={props.hidePopup} saveProfile={props.saveProfile} saving={props.saving} />;
      case "language-skills":
        return <UserLanguageSkillsEdit userProfileData={props.userProfile} userProfileSetter={props.userProfileSetter} showPopup={props.showPopup} hidePopup={props.hidePopup} saveProfile={props.saveProfile} saving={props.saving} />;
      case "education":
        return <UserEducationEdit userProfileData={props.userProfile} userProfileSetter={props.userProfileSetter} showPopup={props.showPopup} hidePopup={props.hidePopup} saveProfile={props.saveProfile} saving={props.saving} />;
      case "certifications":
        return <UserCertificationsEdit userProfileData={props.userProfile} userProfileSetter={props.userProfileSetter} showPopup={props.showPopup} hidePopup={props.hidePopup} saveProfile={props.saveProfile} saving={props.saving} />;
      case "awards":
        return <UserAwardsEdit userProfileData={props.userProfile} userProfileSetter={props.userProfileSetter} showPopup={props.showPopup} hidePopup={props.hidePopup} saveProfile={props.saveProfile} saving={props.saving} />;
      case "trainings":
        return <UserTrainingsEdit userProfileData={props.userProfile} userProfileSetter={props.userProfileSetter} showPopup={props.showPopup} hidePopup={props.hidePopup} saveProfile={props.saveProfile} saving={props.saving} />;
      case "my-interests":
        return <UserMyInterestsEdit userProfileData={props.userProfile} userProfileSetter={props.userProfileSetter} showPopup={props.showPopup} hidePopup={props.hidePopup} saveProfile={props.saveProfile} saving={props.saving} />;
      case "connect-linkedin":
        return <UserLinkedInEdit userProfileData={props.userProfile} userProfileSetter={props.userProfileSetter} showPopup={props.showPopup} hidePopup={props.hidePopup} saveProfile={props.saveProfile} saving={props.saving} />;
      default:
        return <p>No data to edit</p>;
    }
  };

  return (
    <Layer eventBubblingEnabled={true}>
      <Popup className="edit-dialog--root" role="dialog" aria-modal="true" onDismiss={props.hidePopup} enableAriaHiddenSiblings={true}>
        <Overlay onClick={props.hidePopup} />
        <FocusTrapZone>
          <div role="document" className={`edit-dialog ${fieldGroupToEdit === "skills" || fieldGroupToEdit === "language-skills" ? "edit-dialog--lg" : ""}`}>
            <div>{editForm()}</div>
          </div>
        </FocusTrapZone>
      </Popup>
    </Layer>
  );
};
