import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconArrowForward = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.0252 11.3568C12.7 11.6825 12.7003 12.2101 13.026 12.5354C13.3517 12.8606 13.8793 12.8601 14.2046 12.5345L19.7563 6.97512C20.0813 6.64965 20.0812 6.12237 19.756 5.79708L14.2042 0.244139C13.8788 -0.081332 13.3511 -0.0813866 13.0257 0.244017C12.7002 0.569425 12.7001 1.09706 13.0256 1.42252L17.1589 5.55556H8.44581C6.6738 5.55556 5.32239 5.82441 4.12464 6.40496L3.85084 6.54449C2.6192 7.20318 1.64762 8.17476 0.988931 9.4064C0.311804 10.6725 0 12.093 0 14.0014C0 14.4616 0.373097 14.8347 0.833333 14.8347C1.29357 14.8347 1.66667 14.4616 1.66667 14.0014C1.66667 12.3526 1.91961 11.2002 2.45862 10.1924C2.96198 9.2512 3.69566 8.51754 4.63684 8.01418C5.56718 7.51664 6.62065 7.26284 8.07399 7.22672L8.44581 7.22222H17.1533L13.0252 11.3568Z" fill="white" />
      </svg>
    </span>
  );
};
