import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { IconButton, Persona, PersonaSize } from "@fluentui/react";
import "assets/styles/components/SkillRateTable.scss";
import { IUserProfile } from "interfaces/IUserProfile";
import { IAnalysisResults } from "interfaces/IControlPanel";
import { DeliwiButton } from "components/DeliwiButton";
import { IconArrowForward } from "../Icons/IconArrowForward";
// import { getProfilePicture } from "services/apiService";

// Adjusted IUserGroup to use IUserProfile instead of IUser
interface IUserGroup {
  skillRate: string;
  count: number;
  users: IUserProfile[]; // Now expects IUserProfile objects
}

interface SkillsChartProps {
  analysisResults: IAnalysisResults;
  analysisType?: string;
}

export const SkillRateTable = ({ analysisResults, analysisType }: SkillsChartProps) => {
  const [expandedGroup, setExpandedGroup] = useState<string | null>(null);
  const [peopleTotal, setPeopleTotal] = useState<number>(0);

  useEffect(() => {
    let total = analysisResults.ExpertTotal || 0;
    total += analysisResults.AdvancedTotal || 0;
    total += analysisResults.NoviceTotal || 0;
    total += analysisResults.NoInformationTotal || 0;
    if (analysisType === "survey") {
      total += analysisResults.NoAnswerTotal ?? 0;
    }
    setPeopleTotal(total);
  }, [analysisResults, analysisType]);

  const userGroups: IUserGroup[] = [
    {
      skillRate: t("SpecialExpertise"),
      count: analysisResults.ExpertTotal || 0,
      users: analysisResults.Expert || [],
    },
    {
      skillRate: t("Professional"),
      count: analysisResults.AdvancedTotal || 0,
      users: analysisResults.Advanced || [],
    },
    {
      skillRate: t("Basic"),
      count: analysisResults.NoviceTotal || 0,
      users: analysisResults.Novice || [],
    },
    {
      skillRate: t("NotFamiliar"),
      count: analysisResults.NoInformationTotal || 0,
      users: analysisResults.NoInformation || [],
    },
  ];

  // Check if analysisType is 'survey' and modify the array accordingly
  if (analysisType === "survey") {
    userGroups.push({
      skillRate: t("NoAnswerYet"),
      count: analysisResults.NoAnswerTotal || 0,
      users: analysisResults.NoAnswer || [],
    });
  }

  const toggleGroup = (groupName: string): void => {
    setExpandedGroup(expandedGroup === groupName ? null : groupName);
  };

  const renderUserIcons = (users: IUserProfile[]) => {
    const visibleUsers = users.slice(0, 4); // Get up to the first 4 users
    const additionalCount = users.length > 4 ? users.length - 4 : 0; // Determine if there are more than 4 users

    return (
      <>
        {visibleUsers.map((user, index) => (
          <Persona key={index} imageUrl={user.ProfilePictureUrl} size={PersonaSize.size24} hidePersonaDetails />
        ))}
        {additionalCount > 0 && <div>+{additionalCount}</div>} {/* Show additional count if more than 4 */}
      </>
    );
  };

  return (
    <div className="skilltable">
      <div className="skilltable__row skilltable__row--head">
        <div className="skilltable__cols">
          <div className="skilltable__cols--icon"></div>
          <div className="skilltable__cols--rate">
            <p>{t("SkillRate")}</p>
          </div>
          <div className="skilltable__cols--count">
            <p>{t("UserCount")}</p>
          </div>
          <div className="skilltable__cols--usericons"></div>
          <div className="skilltable__cols--button"></div>
        </div>
      </div>
      {userGroups.map((group, index) => (
        <div key={group.skillRate} className={`skilltable__row ${group.count === 0 ? "no-users" : ""}`} onClick={group.count > 0 ? () => toggleGroup(group.skillRate) : undefined}>
          <div className="skilltable__cols">
            <div className="skilltable__cols--icon">
              <IconButton
                iconProps={{
                  iconName: group.count > 0 ? (expandedGroup === group.skillRate ? "ChevronDown" : "ChevronRight") : "PeopleBlock",
                }}
                ariaLabel={group.count > 0 ? t("ShowUsers") : t("NoUsers")}
              />

              <p>{group.count > 0 ? t("ShowUsers") : t("NoUsers")}</p>
            </div>
            <div className="skilltable__cols--rate">
              <div className="legend-label">
                <div className={`stars stars-${index}`}></div>
                <div className="title">{group.skillRate}</div>
              </div>
            </div>
            <div className="skilltable__cols--count">
              <p>
                {group.count} / {peopleTotal}
              </p>
            </div>
            <div className="skilltable__cols--usericons">
              <div className="skilltable__cols--usericons--wrap">{renderUserIcons(group.users)}</div>
            </div>
            <div className="skilltable__cols--button">{/*{index === userGroups.length - 1 && <button className="button-style--secondary">{t("CreateSurvey")}</button>}*/}</div>
          </div>
          {expandedGroup === group.skillRate && (
            <div className="skilltable__collapse">
              {group.users.map((user, userIndex) => (
                <div key={userIndex} className={`skilltable__collapse--person count-${userIndex}`}>
                  <Persona imageUrl={user.ProfilePictureUrl} text={user.FirstName || user.LastName ? `${user.FirstName} ${user.LastName}` : user.AccountName} size={PersonaSize.size16} />
                  <Link to={"/user-profile/" + user.id} target="_blank">
                    <DeliwiButton className="button-style--outline button-size--small">
                      <IconArrowForward />
                      {t("TalentCard")}
                    </DeliwiButton>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
