import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconUnknown = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="Shape" d="M14 2.80073C7.81482 2.80073 2.80073 7.81482 2.80073 14C2.80073 20.1852 7.81482 25.1993 14 25.1993C20.1852 25.1993 25.1993 20.1852 25.1993 14C25.1993 7.81482 20.1852 2.80073 14 2.80073ZM0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z" fill="#C50F1F" />
      </svg>
    </span>
  );
};
