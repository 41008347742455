import { useState, useEffect } from "react";
import { t } from "i18next";
import { DeliwiButton } from "components/DeliwiButton";
import { getAdminReport } from "services/apiService";
import { PeoplePickerDialog } from "components/ControlPanel/PeoplePickerDialog";
import { ReportData } from "interfaces/IControlPanel";
import "assets/styles/components/UserReports.scss";

import IconDiamond from "assets/icons/icon-diamond.svg";
import Icon30 from "assets/icons/icon-30.svg";
import IconLog from "assets/icons/icon-log.svg";

export const UserReports = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reportData, setReportData] = useState<ReportData>({
    CurrentInvitedUsers: 0,
    CurrentPendingActivations: 0,
    ActivatedUsers: 0,
    ProfileFillRateAverage: 0,
    AISearchAmount30Days: 0,
    AISearchAmount90Days: 0,
  });

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const response = await getAdminReport();
        // console.log(response);
        // Ensure response is of type ReportData
        if (response && typeof response === "object") {
          setReportData(response as ReportData);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Failed to fetch report", error);
        setIsLoading(false);
      }
    };
    fetchReport();
  }, []);

  if (isLoading) {
    return <div className="deliwi-spinner"></div>;
  }

  return (
    <>
      <div className="user-report">
        <h2 className="text-style--Title2 mt-48 mb-40">{t("User overview")}</h2>

        <div className="user-report__top pb-48 mb-60">
          <div className="user-report__row mb-24">
            <div className="user-report__row--title">
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.2117 1.23747C14.3427 0.844531 14.1304 0.419762 13.7375 0.288723C13.3445 0.157684 12.9198 0.369997 12.7887 0.762936L12.0387 3.01192C11.9077 3.40486 12.12 3.82963 12.5129 3.96067C12.9059 4.09171 13.3306 3.87939 13.4617 3.48645L14.2117 1.23747ZM18.7803 1.21988C19.0732 1.51278 19.0732 1.98765 18.7803 2.28054L16.2803 4.78054C15.9874 5.07344 15.5126 5.07344 15.2197 4.78054C14.9268 4.48765 14.9268 4.01278 15.2197 3.71988L17.7197 1.21988C18.0126 0.92699 18.4874 0.92699 18.7803 1.21988ZM9.87055 2.74109C8.75471 1.54566 6.78611 1.84518 6.07629 3.31838L0.178482 15.559C-0.162049 16.2657 -0.0056262 17.1116 0.565125 17.6499L2.00386 19.0067C2.53118 19.504 3.30531 19.6365 3.96799 19.3429L5.38545 18.7149C6.0275 20.0661 7.40483 21.0002 9.00035 21.0002C11.2095 21.0002 13.0003 19.2094 13.0003 17.0002C13.0003 16.46 12.8932 15.9447 12.6991 15.4746L16.119 13.9594C17.5809 13.3117 17.9679 11.4159 16.8769 10.2471L9.87055 2.74109ZM11.3266 16.0826C11.4387 16.3667 11.5003 16.6763 11.5003 17.0002C11.5003 18.3809 10.3811 19.5002 9.00035 19.5002C8.01695 19.5002 7.16616 18.9324 6.75795 18.1068L11.3266 16.0826ZM16 7.24948C16 6.83527 16.3358 6.49948 16.75 6.49948H18.75C19.1642 6.49948 19.5 6.83527 19.5 7.24948C19.5 7.66369 19.1642 7.99948 18.75 7.99948H16.75C16.3358 7.99948 16 7.66369 16 7.24948Z" fill="#0072E3" />
              </svg>
              <h3 className="text-style--Title3">{t("Invited users")}</h3>
            </div>
            <div className="user-report__row--bar">
              <div className="user-report__row--bar-bg user-report__row--bar-bg-none"></div>
              <div className="text-style--Title3">{reportData.CurrentInvitedUsers}</div>
            </div>
            <div className="user-report__row--button">
              <PeoplePickerDialog simpleView={true} />
            </div>
          </div>
          <div className="user-report__row mb-24">
            <div className="user-report__row--title">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.25 11.5H9.25C8.836 11.5 8.5 11.164 8.5 10.75V4.75C8.5 4.336 8.836 4 9.25 4C9.664 4 10 4.336 10 4.75V10H13.25C13.664 10 14 10.336 14 10.75C14 11.164 13.664 11.5 13.25 11.5ZM10 0C4.478 0 0 4.478 0 10C0 15.522 4.478 20 10 20C15.522 20 20 15.522 20 10C20 4.478 15.522 0 10 0Z" fill="#EAA300" />
              </svg>
              <h3 className="text-style--Title3">{t("Pending activation")}</h3>
            </div>
            <div className="user-report__row--bar">
              <div className="user-report__row--bar-bg">
                <div
                  className="user-report__row--bar-inner"
                  style={{
                    background: `linear-gradient(90deg, rgba(234, 163, 0, 1) ${(reportData.CurrentPendingActivations / reportData.CurrentInvitedUsers) * 100}%, rgba(255, 255, 255, 1) ${(reportData.CurrentPendingActivations / reportData.CurrentInvitedUsers) * 100}%)`,
                  }}
                ></div>
              </div>
              <div className="text-style--Title3">{reportData.CurrentPendingActivations}</div>
            </div>
            <div className="user-report__row--button">{/*<DeliwiButton className="button-style--primary button-size--medium">{t("AddUsers")}</DeliwiButton>*/}</div>
          </div>
          <div className="user-report__row mb-24">
            <div className="user-report__row--title">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0ZM13.2197 6.96967L8.75 11.4393L6.78033 9.46967C6.48744 9.17678 6.01256 9.17678 5.71967 9.46967C5.42678 9.76256 5.42678 10.2374 5.71967 10.5303L8.21967 13.0303C8.51256 13.3232 8.98744 13.3232 9.28033 13.0303L14.2803 8.03033C14.5732 7.73744 14.5732 7.26256 14.2803 6.96967C13.9874 6.67678 13.5126 6.67678 13.2197 6.96967Z" fill="#13A10E" />
              </svg>
              <h3 className="text-style--Title3">{t("Activated users")}</h3>
            </div>
            <div className="user-report__row--bar">
              <div className="user-report__row--bar-bg">
                <div
                  className="user-report__row--bar-inner"
                  style={{
                    background: `linear-gradient(90deg, rgba(19, 161, 14, 1) ${(reportData.ActivatedUsers / reportData.CurrentInvitedUsers) * 100}%, rgba(255, 255, 255, 1) ${(reportData.ActivatedUsers / reportData.CurrentInvitedUsers) * 100}%)`,
                  }}
                ></div>
              </div>
              <div className="text-style--Title3">{reportData.ActivatedUsers}</div>
            </div>
            <div className="user-report__row--button">{/*<DeliwiButton className="button-style--primary button-size--medium">{t("AddUsers")}</DeliwiButton>*/}</div>
          </div>
        </div>

        <div className="user-report__bottom">
          <h2 className="text-style--Title2 mb-40">{t("DeliwiAI usage statistics")}</h2>
          <div className="user-report__cards">
            <div className="admin-box user-report__cards--card">
              <img src={IconDiamond} className="icon-size-62 icon-shadow" alt="" />
              <h3 className="text-style--Body1 fw-700">{t("Profile Fill Rate (avg)")}</h3>
              <p className="text-style--Title2">{reportData.ProfileFillRateAverage.toFixed(2)}%</p>
            </div>
            <div className="admin-box user-report__cards--card">
              <img src={Icon30} className="icon-size-62 icon-shadow" alt="" />
              <h3 className="text-style--Body1 fw-700">{t("AI Search (last 30 days)")}</h3>
              <p className="text-style--Title2">{reportData.AISearchAmount30Days}</p>
            </div>
            <div className="admin-box user-report__cards--card">
              <img src={IconLog} className="icon-size-62 icon-shadow" alt="" />
              <h3 className="text-style--Body1 fw-700">{t("AI Search (last 90 days)")}</h3>
              <p className="text-style--Title2">{reportData.AISearchAmount90Days}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
