import React from "react";

import { t } from "i18next";

import { DeliwiButton } from "components/DeliwiButton";
import { inviteUsers } from "services/apiService";
import { IUser, FailedInvite } from "interfaces/IUser";

interface AddUsersButtonProps {
  users: IUser[];
  onUsersAdded: (failedInvites?: FailedInvite[]) => void;
  setCurrentStep: (step: number) => void;
}

export const AddUsersButton = ({ users, onUsersAdded, setCurrentStep }: AddUsersButtonProps) => {
  const handleAddUsers = async () => {
    setCurrentStep(2); // Move to step 2

    const delayPromise = new Promise((resolve) => setTimeout(resolve, 5000)); // 5 seconds delay
    const invitePromise = inviteUsers(users);

    try {
      const [, inviteResponse] = await Promise.all([delayPromise, invitePromise]);

      // Assuming inviteResponse is directly the JSON object you've mentioned.
      // Adjust this part if inviteResponse needs to be parsed from JSON.

      setCurrentStep(3); // Move to step 3 after both promises resolve

      if (inviteResponse.FailedInvites && inviteResponse.FailedInvites.length > 0) {
        // Pass the failed invites to the parent component
        onUsersAdded(inviteResponse.FailedInvites);
      } else {
        // No failed invites, proceed normally
        onUsersAdded();
      }
    } catch (error) {
      console.error("Error inviting users:", error);
      setCurrentStep(1); // Optionally reset to step 1 in case of error
    }
  };

  const isButtonDisabled = users.length === 0;

  return <DeliwiButton text={t("AddSelectedUsers")} onClick={handleAddUsers} disabled={isButtonDisabled} className="button-style--primary button-size--large" />;
};
