import { t } from "i18next";
import { IconTetris } from "components/Icons/IconTetris";
import { IconAddUser } from "components/Icons/IconAddUser";
import { IconStarUser } from "components/Icons/IconStarUser";
import { IconSpeed } from "components/Icons/IconSpeed";

const searchInstructionsContent = [
  {
    label: t("SearchInstructions1Label"),
    icon: <IconSpeed className="icon-size--20" />,
    content: t("SearchInstructions1Content"),
  },
  {
    label: t("SearchInstructions2Label"),
    icon: <IconAddUser className="icon-size--20" />,
    content: t("SearchInstructions2Content"),
  },
  {
    label: t("SearchInstructions3Label"),
    icon: <IconStarUser className="icon-size--20" />,
    content: t("SearchInstructions3Content"),
  },
  {
    label: t("SearchInstructions4Label"),
    icon: <IconTetris className="icon-size--20" />,
    content: t("SearchInstructions4Content"),
  },
];

export const SearchInstructions = () => {
  return (
    <div className="search-instructions search-instructions--container">
      <h2 className="text-style--Subtitle1 text-color--grey44 mb-24">{t("SearchInstructionsHeading")}</h2>
      <div className="search-instructions--boxes">
        {searchInstructionsContent.map((instruction, index) => {
          return (
            <div key={index} className="search-instructions--box search-instruction">
              <div className="search-instructions--box--container">
                <div className="search-instruction--label--wrapper">
                  {instruction.icon}
                  <h4 className="search-instruction--label--text text-style--Subtitle2 text-color--brand10">{instruction.label}</h4>
                </div>
                <div className="search-instruction--content text-style--Body2 text-color--grey38">{instruction.content}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
