import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconStarEmphasis = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Star Emphasis">
          <path
            id="Shape"
            d="M18.8432 4.65632C18.1478 3.24737 16.1387 3.24738 15.4433 4.65633L12.1323 11.3652L4.72858 12.441C3.1737 12.667 2.55285 14.5778 3.67797 15.6745L9.03534 20.8966L7.77064 28.2704C7.50503 29.819 9.13045 31 10.5212 30.2688L17.1433 26.7874L23.7653 30.2688C25.1561 31 26.7815 29.819 26.5159 28.2704L25.2512 20.8966L30.6085 15.6745C31.7337 14.5778 31.1128 12.667 29.5579 12.441L22.1542 11.3652L18.8432 4.65632ZM13.9721 12.3967L17.1433 5.97122L20.3144 12.3967C20.5906 12.9562 21.1243 13.344 21.7418 13.4337L28.8327 14.4641L23.7016 19.4656C23.2549 19.9011 23.051 20.5286 23.1565 21.1436L24.3677 28.2059L18.0254 24.8715C17.4731 24.5812 16.8134 24.5812 16.2611 24.8715L9.91878 28.2059L11.1301 21.1436C11.2355 20.5286 11.0317 19.9011 10.5849 19.4656L5.45381 14.4641L12.5448 13.4337C13.1622 13.344 13.696 12.9562 13.9721 12.3967ZM1.93004 5.6096C1.56669 6.06379 1.64033 6.72653 2.09451 7.08988L5.60502 9.89829C6.05921 10.2616 6.72195 10.188 7.0853 9.73381C7.44865 9.27963 7.37501 8.61689 6.92082 8.25354L3.41031 5.44513C2.95613 5.08178 2.29338 5.15542 1.93004 5.6096ZM32.361 25.8822C32.7244 25.428 32.6507 24.7652 32.1966 24.4019L28.686 21.5935C28.2319 21.2301 27.5691 21.3038 27.2058 21.758C26.8424 22.2121 26.916 22.8749 27.3702 23.2382L30.8808 26.0466C31.3349 26.41 31.9977 26.3364 32.361 25.8822ZM2.09451 24.4019C1.64033 24.7652 1.56669 25.428 1.93004 25.8822C2.29338 26.3364 2.95613 26.41 3.41031 26.0466L6.92082 23.2382C7.37501 22.8749 7.44865 22.2121 7.0853 21.758C6.72195 21.3038 6.05921 21.2301 5.60502 21.5935L2.09451 24.4019ZM32.361 5.6096C32.7244 6.06379 32.6507 6.72653 32.1966 7.08988L28.686 9.89829C28.2319 10.2616 27.5691 10.188 27.2058 9.73381C26.8424 9.27963 26.916 8.61689 27.3702 8.25354L30.8808 5.44513C31.3349 5.08178 31.9977 5.15542 32.361 5.6096Z"
            fill="#020404"
          />
        </g>
      </svg>
    </span>
  );
};
