import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconFeedBack = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.625 12.5C11.6605 12.5 12.5 13.3395 12.5 14.375V15C12.5 17.4643 10.1756 20 6.25 20C2.32439 20 0 17.4643 0 15V14.375C0 13.3395 0.839466 12.5 1.875 12.5H10.625ZM10.625 13.75H1.875C1.52982 13.75 1.25 14.0299 1.25 14.375V15C1.25 16.797 3.0402 18.75 6.25 18.75C9.4598 18.75 11.25 16.797 11.25 15V14.375C11.25 14.0299 10.9702 13.75 10.625 13.75ZM6.25 4.375C8.14847 4.375 9.6875 5.91403 9.6875 7.8125C9.6875 9.71097 8.14847 11.25 6.25 11.25C4.35153 11.25 2.8125 9.71097 2.8125 7.8125C2.8125 5.91403 4.35153 4.375 6.25 4.375ZM17.5 0C18.818 0 19.8978 1.01985 19.9931 2.31342L20 2.5V5C20 6.31795 18.9801 7.39771 17.6866 7.49314L17.5 7.5H15.6237L14.1262 9.49974C13.4645 10.3825 12.1207 10.0249 11.9054 9.03032L11.8832 8.8908L11.876 8.75L11.875 7.41875L11.778 7.39415C10.8712 7.12106 10.1815 6.34691 10.0308 5.3935L10.0069 5.18658L10 5V2.5C10 1.18205 11.0199 0.102294 12.3134 0.00685721L12.5 0H17.5ZM6.25 5.625C5.04188 5.625 4.0625 6.60438 4.0625 7.8125C4.0625 9.02062 5.04188 10 6.25 10C7.45812 10 8.4375 9.02062 8.4375 7.8125C8.4375 6.60438 7.45812 5.625 6.25 5.625ZM17.5 1.25H12.5C11.859 1.25 11.3306 1.73255 11.2584 2.35423L11.25 2.5V5C11.25 5.64105 11.7325 6.16939 12.3542 6.24159L12.5 6.25H13.126V8.75L15 6.25H17.5C18.141 6.25 18.6694 5.76745 18.7416 5.14578L18.75 5V2.5C18.75 1.85895 18.2675 1.33061 17.6458 1.25841L17.5 1.25Z" fill="#242424" />
      </svg>
    </span>
  );
};
