import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconAddSmall = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0789 1.57895C12.0789 0.706918 11.372 0 10.5 0C9.62798 0 8.92105 0.706918 8.92105 1.57895V8.42105H2.07895C1.20692 8.42105 0.5 9.12798 0.5 10C0.5 10.872 1.20692 11.5789 2.07895 11.5789H8.92105V18.4211C8.92105 19.2931 9.62798 20 10.5 20C11.372 20 12.0789 19.2931 12.0789 18.4211V11.5789H18.9211C19.7931 11.5789 20.5 10.872 20.5 10C20.5 9.12798 19.7931 8.42105 18.9211 8.42105H12.0789V1.57895Z" fill="white" />
      </svg>
    </span>
  );
};
