import { useState, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { t } from "i18next";
import { Stack, Dialog, DialogFooter } from "@fluentui/react";
import { DeliwiButton } from "components/DeliwiButton";
import { IPersonaProps } from "@fluentui/react/lib/Persona";
import { PeoplePicker, PeoplePickerRef } from "components/ControlPanel/PeoplePicker";
import { HelpDialog } from "components/HelpDialog";
import { ITeamsApp } from "interfaces/ITeamsApp";
import { getInitialSetupStatus, postInitialSetupConfigurations, postInitialSetupAdmins, addTeamsApp } from "services/apiService";
import { useSetup } from "contexts/SetupContext";

import deliwiLogoRound from "assets/images/deliwi-round.svg";
import stepIcon1 from "assets/icons/icon-step-1.svg";
import stepIcon2 from "assets/icons/icon-step-2.svg";
import "assets/styles/components/InstallDeliwi.scss";

export const InstallDeliwi = () => {
  const [, setTeamsApp] = useState<ITeamsApp | null>(null);
  const { setupStatus, setSetupStatus } = useSetup();
  const { instance } = useMsal();
  const [isLoadingToggleTeams, setIsLoadingToggleTeams] = useState(false);
  const [isLoadingSetAdmins, setIsLoadingSetAdmins] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAdminUsers, setSelectedAdminUsers] = useState<string[]>([]);
  const peoplePickerRef = useRef<PeoplePickerRef>(null);

  const handleUserSelection = (users: IPersonaProps[]) => {
    const selectedIds = users.map((user) => user.key as string);
    setSelectedAdminUsers(selectedIds);
  };

  const handleToggleTeams = async () => {
    setIsLoadingToggleTeams(true);

    const delayPromise = new Promise((resolve) => setTimeout(resolve, 1000));

    try {
      const response = await fetch("/teams/package.zip");
      const file = await response.blob();
      const app = await addTeamsApp(file);

      // Check if app is truthy
      if (!app) {
        throw new Error("Failed to add Teams app");
      }

      setTeamsApp(app);

      const data = {
        InviteMessageTemplate: "Custom invite message template",
        TeamsBotEnabled: true,
        TeamsBotApplicationID: app != null ? app.id : null
      };

      await Promise.all([delayPromise, postInitialSetupConfigurations(data)]);
      const updatedSetup = await getInitialSetupStatus();

      setSetupStatus(updatedSetup);
    } catch (error) {
      console.error("Error updating Teams bot status:", error);
    } finally {
      setIsLoadingToggleTeams(false);
    }
  };

  const handleSetAdmins = async () => {
    setIsLoadingSetAdmins(true);
    const delayPromise = new Promise((resolve) => setTimeout(resolve, 1000));
    try {
      await Promise.all([delayPromise, postInitialSetupAdmins(selectedAdminUsers)]);
    } catch (error) {
      console.error("Error inviting users:", error);
    } finally {
      setIsLoadingSetAdmins(false);
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    localStorage.setItem("hasViewedAdminWelcome", "false");
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  };

  return (
    <Stack>
      <Stack.Item align="stretch" className="install-wrapper">
        <h1 className="text-style--Large-title mb-40">{t("Setup")}</h1>

        <div className="install-intro mb-40">
          <img src={deliwiLogoRound} className="icon-shadow" alt="Deliwi AI" />
          <div className="install-intro--texts">
            <div className="install-intro--top">
              <h2 className="text-style--Subtitle1 text-color--brand10 install-intro--title">{t("WelcomeToDeliwiAI")}</h2>
              <p className="text-style--Body1">{t("TheVeryFirstThingToDo")}</p>
            </div>
            <div className="install-intro--bottom">
              <p className="text-style--Body1 text-style--bold">{t("Why")}</p>
              <p className="text-style--Body1">{t("WhyDeliwiAI")}</p>
            </div>
          </div>
        </div>
        <div className="admin-box">
          <h2 className="text-style--Main-title mb-24">{t("FirstSteps")}</h2>

          <div className="install__steps">
            <div className="install__steps--step">
              <div className="install__steps--step-icon">
                <img src={stepIcon1} className="icon-size-62 icon-shadow" alt="" />
              </div>
              <div className="install__steps--step-content">
                <h3 className="text-style--Title2 mb-8 text-color--black">{t("EnableChatbot")}</h3>
                <div className="install__steps--step-content--why mb-40">
                  <p className="text-style--Body1 text-color--brand10">
                    <strong>{t("Why")}</strong>
                  </p>
                  <p className="text-style--Body1 text-color--brand10">{t("EnableChatbotWhy")}</p>
                </div>
                <HelpDialog title={t("Help.EnableChatbot.Title")} dialogContent={t("Help.EnableChatbot.Content")} className="mb-16">
                  <p className="text-style--Body1 text-color--grey44">{t("EnableChatbot")}</p>
                </HelpDialog>
                <div className="install__steps--step-content--btnspin">
                  <DeliwiButton className={`button-style--primary button-size--large text-style--Subtitle2`} disabled={setupStatus.TeamsBotEnabled} onClick={handleToggleTeams}>
                    {setupStatus.TeamsBotEnabled ? "Chatbot for Teams enabled" : "Enable Chatbot for Teams"}
                  </DeliwiButton>
                  {isLoadingToggleTeams ? <div className="deliwi-spinner" /> : ""}
                </div>
              </div>
            </div>
            <div className={`install__steps--step ${setupStatus.TeamsBotEnabled ? "" : "disabled"}`}>
              <div className="install__steps--step-icon">
                <img src={stepIcon2} className="icon-size-62 icon-shadow" alt="" />
              </div>
              <div className="install__steps--step-content pb-0">
                <h3 className="text-style--Title2 mb-8 text-color--black">{t("AddAdminUsersDeliwiAi")}</h3>
                <div className="install__steps--step-content--why mb-40">
                  <p className="text-style--Body1 text-color--brand10">
                    <strong>{t("Why")}</strong>
                  </p>
                  <p className="text-style--Body1 text-color--brand10">{t("AddAdminUsersDeliwiAiWhy")}</p>
                </div>
                <HelpDialog title={t("Help.AddAdmins.Title")} dialogContent={t("Help.AddAdmins.Content")} className="mb-24">
                  <p className="text-style--Body1 text-color--grey44">{t("SelectAdminUserOrUsers")}</p>
                </HelpDialog>{" "}
                <div className="mb-40">
                  <PeoplePicker ref={peoplePickerRef} onUserSelect={handleUserSelection} pickerType="setup" />
                </div>
                <div className="install__steps--step-content--btnspin">
                  <DeliwiButton className={`button-style--primary button-size--large text-style--Subtitle2 ${setupStatus.AdminsAdded ? "enabled" : "disabled"} ${selectedAdminUsers.length === 0 ? "not-active" : ""}`} onClick={selectedAdminUsers.length === 0 ? undefined : handleSetAdmins}>
                    {t("AddSelectedUsersAsAdmins")}
                  </DeliwiButton>

                  {isLoadingSetAdmins ? <div className="deliwi-spinner" /> : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Stack.Item>
      <Dialog
        hidden={!isModalOpen}
        minWidth={640}
        dialogContentProps={{
          showCloseButton: true,
          title: t("SetupCompleted"),
        }}
        modalProps={{
          isBlocking: true,
          className: "admin-dialog",
        }}
      >
        <div className="box-gray mb-32">
          <p className="text-style--Body1 text-style--semibold mb-16">{t("SetupHasBeenCompletedSuccessfully")}</p>
          <div className="info-text">
            <p className="text-style--semibold">{t("AdminUsersHaveBeenAddedAndInvitationsSent")}</p>
          </div>
        </div>
        <DialogFooter>
          <DeliwiButton onClick={handleModalClose} text={t("CloseTheSetup")} className="button-style--primary button-size--large" />
        </DialogFooter>
      </Dialog>
    </Stack>
  );
};
