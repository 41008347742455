import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconLink = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Link Multiple">
          <path id="Shape" d="M6.5 4C3.46243 4 1 6.46243 1 9.5C1 11.8359 2.45624 13.8317 4.51041 14.6291C4.54977 13.9293 4.69984 13.2591 4.94339 12.6357C3.79151 12.0627 3 10.8738 3 9.5C3 7.567 4.567 6 6.5 6H12.5C14.433 6 16 7.567 16 9.5C16 11.433 14.433 13 12.5 13H12C11.4477 13 11 13.4477 11 14C11 14.5523 11.4477 15 12 15H12.5C15.5376 15 18 12.5376 18 9.5C18 6.46243 15.5376 4 12.5 4H6.5ZM19.1135 12.2134C20.219 12.6556 21 13.7366 21 15C21 16.6569 19.6569 18 18 18H11C9.34315 18 8 16.6569 8 15C8 13.3431 9.34315 12 11 12H12C12.5523 12 13 11.5523 13 11C13 10.4477 12.5523 10 12.0001 10H11C8.23858 10 6 12.2386 6 15C6 17.7614 8.23858 20 11 20H18C20.7614 20 23 17.7614 23 15C23 12.7598 21.5267 10.8636 19.4962 10.2277C19.4722 10.9229 19.3391 11.5903 19.1135 12.2134Z" fill="#242424" />
        </g>
      </svg>
    </span>
  );
};
