import { IUserProfile } from "interfaces/IUserProfile";
import "assets/styles/components/ProfilePageOverview.scss";
import { IconMail } from "../Icons/IconMail";
import { IconCall } from "../Icons/IconCall";
import { t } from "i18next";
import { IconLocation } from "../Icons/IconLocation";
import { useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { useMsal } from "@azure/msal-react";
import { IconEditImage } from "../Icons/IconEditImage";
import { UserProfileEditPicture } from "./UserProfileEditPicture";
import { IconTeams } from "../Icons/IconTeams";
import { DeliwiButton } from "components/DeliwiButton";
import { UserAvailability } from "./UserAvailability";
import avatarSvg from "assets/images/avatar.svg";
import { FillRateIndicator } from "./FillRateIndicator";
import { FillRateInfo } from "./FillRateInfo";

export interface IUserProfileOverviewProps {
  userProfile: IUserProfile;
  displayMode: string;
  userAvailability?: string;
}

export const UserProfileOverview = (props: IUserProfileOverviewProps) => {
  const shadowUserProfile = props.userProfile;
  const currentDisplayMode = props.displayMode;
  const firstName = shadowUserProfile?.FirstName || "";
  const lastName = shadowUserProfile?.LastName || "";
  const fullName = `${firstName} ${lastName}`;
  const jobTitle = shadowUserProfile?.JobTitle || "";
  const city = shadowUserProfile.Location?.City || "";
  const country = shadowUserProfile?.Location?.Country || "";
  const userLocation = !!city ? city + ", " + country : country;
  const email = shadowUserProfile?.Email || "";
  const phone = shadowUserProfile?.PhoneNumber || "";
  const [updatedProfilePicture, setUpdatedProfilePicture] = useState<string | undefined>();
  const profilePictureUrl = updatedProfilePicture || shadowUserProfile?.ProfilePictureUrl || avatarSvg;
  const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] = useBoolean(false);
  const { accounts } = useMsal();
  const userAvailability = props.userAvailability;

  let canEdit;
  // Check if current user has access to edit the visible profile

  if (currentDisplayMode === "preview") {
    canEdit = false;
  }
  // This check might be unredundant because there shouldn't be EXT accounts
  // But at least we developers have EXT accounts so for testing purposes this is needed
  else if (shadowUserProfile?.AccountName?.includes("#EXT#")) {
    // NEED TO HAVE EMAIL field filled with correct email for this to work
    canEdit = !!accounts && accounts[0].username === shadowUserProfile?.Email;
  } else {
    canEdit = !!accounts && accounts[0].username === shadowUserProfile?.AccountName;
  }

  return (
    <div className="user-profile-overview">
      {isPopupVisible && <UserProfileEditPicture userProfile={shadowUserProfile} showPopup={showPopup} hidePopup={hidePopup} updatedProfilePictureSetter={setUpdatedProfilePicture} updatedProfilePicture={updatedProfilePicture} />}
      <div className="user-profile-overview--container">
        <div className="user-profile-overview--content--island">
          <div className="user-profile-overview--avatar--container">
            <img className="" alt={fullName} src={profilePictureUrl} />
            {canEdit ? (
              <DeliwiButton
                className="button-style--outline button-size--largeicon button--edit-image"
                value="profile-picture"
                onClick={() => {
                  showPopup();
                }}
              >
                <IconEditImage className="icon-edit-image" />
              </DeliwiButton>
            ) : userAvailability ? (
              <UserAvailability availability={props.userAvailability} />
            ) : null}
          </div>
          <div className="user-profile-overview--contact-info">
            <span className="text-style--Title2 text-style--semibold">{fullName}</span>
            <span className="text-style--Title3 text-style--semibold">{jobTitle}</span>
            <div className="location--wrapper">
              <IconLocation className="icon--location" />
              <span className="text-style--Body1 country">{userLocation}</span>
            </div>
          </div>
        </div>

        <div className="user-profile-overview--content--floating">
          <div className={`profile--quick-links ${currentDisplayMode}`}>
            {currentDisplayMode === "my-profile" ? (
              <>
                <FillRateIndicator fillRate={shadowUserProfile?.FillRate || 0} />
                <FillRateInfo />
              </>
            ) : (
              <>
                <a className="profile--quick-links--link" target="_blank" rel="noreferrer" href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`}>
                  <IconTeams className="icon-size--20" />
                  <span className="link-text text-style--Body3">{t("Teams")}</span>
                </a>
                <a className="profile--quick-links--link" href={`mailto:${email}`}>
                  <IconMail className="icon-size--20" />
                  <span className="link-text text-style--Body3">{t("Email")}</span>
                </a>
                <a className="profile--quick-links--link" href={`tel:${phone}`}>
                  <IconCall className="icon-size--20" />
                  <span className="link-text text-style--Body3">{t("Call")}</span>
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
