import { TextField } from "@fluentui/react";
import { DeliwiButton } from "components/DeliwiButton";
import { IAIRewrittenFieldsOriginalValues, IUserProfile, IUserProfileEditForm } from "../../../interfaces/IUserProfile";
import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { IconCheckmark } from "components/Icons/IconCheckmark";
import { IconClose } from "../../Icons/IconClose";
import { useState } from "react";
import { rewriteTextWithAI } from "../../../services/apiService";
import { IconShape } from "../../Icons/IconShape";
import { IconUndo } from "../../Icons/IconUndo";
import { HelpDialog } from "../../HelpDialog";

export interface IMyCareerFormProps {
  WorkBio: string;
}

export const UserMyCareerEdit = (props: IUserProfileEditForm) => {
  const shadowUserProfile = props.userProfileData;

  const [aiRewrittenFields, setAiRewrittenFields] = useState<IAIRewrittenFieldsOriginalValues[]>([]);
  const [fieldUnderRewriting, setFieldUnderRewriting] = useState<string>("");
  const getItemFromRewrittenFields = (fieldName: string): IAIRewrittenFieldsOriginalValues | undefined => aiRewrittenFields?.find((item) => item.fieldName === fieldName);

  const validationSchema = Yup.object({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      WorkBio: shadowUserProfile?.WorkBio || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      saveNewValues(values);
    },
  });

  const saveNewValues = async (values: IMyCareerFormProps) => {
    const updatedProfile = {
      ...shadowUserProfile,
      WorkBio: values.WorkBio,
    } as IUserProfile;

    try {
      await props.saveProfile(updatedProfile);
      props.userProfileSetter(updatedProfile);
      props.hidePopup();
    } catch (error) {
      console.error("Failed to save changes:", error);
    }
  };

  return (
    <>
      <div className="edit-dialog--header form--heading--wrapper">
        <h2 className="form--heading">{t("MyCareer")}</h2>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-edit-popup" onClick={() => props.hidePopup()}></ChromeCloseIcon>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="form--container mb-40">
          <div className="form--fields-container form--fields-container-flexcol">
            <div className="form--field--wrapper">
              <div className="field-with-ai-rewrite field-with-ai-rewrite--wrapper">
                <TextField label={t("ShortCareerDescription")} id="WorkBio" name="WorkBio" multiline autoAdjustHeight rows={2} value={formik.values.WorkBio} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                {fieldUnderRewriting === "WorkBio" && (
                  <div className="search-loader--wrapper">
                    <span className="deliwi-spinner mb-16"></span>
                  </div>
                )}
              </div>
              <div className="field-with-ai-rewrite--actions">
                {!getItemFromRewrittenFields("WorkBio") ? (
                  <HelpDialog className="" dialogContent={t("Help.EditForms.RewriteWithAI.Content")} title={t("Help.EditForms.RewriteWithAI.Title")}>
                    <DeliwiButton
                      className="button-style--candy-border button-size--medium bg-white"
                      disabled={!formik.values.WorkBio || formik.values.WorkBio.length < 5}
                      onClick={async () => {
                        setFieldUnderRewriting("WorkBio");
                        const response = await rewriteTextWithAI("WorkBio", formik.values.WorkBio);
                        if (!!response?.RewrittenText) {
                          setAiRewrittenFields([
                            ...aiRewrittenFields,
                            {
                              fieldName: "WorkBio",
                              value: formik.values.WorkBio,
                            },
                          ]);
                          formik.setFieldValue("WorkBio", response.RewrittenText);
                        }
                        setFieldUnderRewriting("");
                      }}
                    >
                      <IconShape />
                      {t("RewriteWithAI")}
                    </DeliwiButton>
                  </HelpDialog>
                ) : (
                  <DeliwiButton
                    className="button-style--outline button-size--medium"
                    onClick={() => {
                      const originalValueItem = getItemFromRewrittenFields("WorkBio");
                      formik.setFieldValue("WorkBio", originalValueItem?.value);
                      const updatedRewrittenFields = aiRewrittenFields.filter((fieldItem) => fieldItem.fieldName !== "WorkBio");
                      setAiRewrittenFields(updatedRewrittenFields);
                    }}
                  >
                    <IconUndo />
                    {t("Undo")}
                  </DeliwiButton>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="edit-dialog--buttons">
          <DeliwiButton className="button-style--outline button-size--large" onClick={() => props.hidePopup()}>
            <IconClose className="icon-size--20" />
            {t("Cancel")}
          </DeliwiButton>
          <DeliwiButton type="submit" className="button-style--primary button-size--large" disabled={props.saving}>
            <IconCheckmark />
            {t("SaveChanges")}
          </DeliwiButton>
        </div>
      </form>
    </>
  );
};
