import { Pivot, PivotItem } from "@fluentui/react";
import { useLocation, useNavigate } from "react-router-dom";
import React, { MouseEvent } from "react";
import { t } from "i18next";
import { TabUserManagement } from "./ControlPanelTabs/TabUserManagement";
import { TabSkillAnalysis } from "./ControlPanelTabs/TabSkillAnalysis";
import { TabMessageTemplates } from "./ControlPanelTabs/TabMessageTemplates";
import { TabTeamsSettings } from "./ControlPanelTabs/TabTeamsSettings";
import { useUserRole } from "contexts/UserRoleContext";

export const ControlPanelTabs = () => {
  const { userRole } = useUserRole();
  const location = useLocation();
  const navigate = useNavigate();
  const currentTab = location.hash.substring(1) || "user-management";

  const onTabClick = (item?: PivotItem, ev?: MouseEvent<HTMLElement>) => {
    if (item) {
      const newActiveTab = item.props.itemKey;
      const newPath = `${location.pathname}#${newActiveTab}`;
      navigate(newPath);
    }
  };

  const controlPanelTabs = [
    {
      headerText: t("Admin.Tab.UserManagement"),
      className: "admin-page__tabs--tabcontent",
      tabComponent: <TabUserManagement />,
      roles: ["admin"],
      key: "user-management",
    },
    {
      headerText: t("Admin.Tab.SkillAnalysis"),
      className: "admin-page__tabs--tabcontent",
      tabComponent: <TabSkillAnalysis />,
      roles: ["superuser", "admin"],
      key: "skill-analysis",
    },
    {
      headerText: t("Admin.Tab.MessageTemplates"),
      className: "admin-page__tabs--tabcontent",
      tabComponent: <TabMessageTemplates />,
      roles: ["admin"],
      key: "message-templates",
    },
    {
      headerText: t("Admin.Tab.TeamsSettings"),
      className: "admin-page__tabs--tabcontent",
      tabComponent: <TabTeamsSettings />,
      roles: ["admin"],
      key: "teams-settings",
    },
  ];

  const isRoleAllowed = (roles: string[]) => roles.some((role: string) => role === "any" || userRole.includes(role));

  return (
    <Pivot className="admin-page__tabs" selectedKey={currentTab} onLinkClick={onTabClick}>
      {controlPanelTabs
        .filter((tab) => isRoleAllowed(tab.roles))
        .map((tab, index) => (
          <PivotItem headerText={tab.headerText} className={tab.className} itemKey={tab.key} key={index}>
            {tab.tabComponent}
          </PivotItem>
        ))}
    </Pivot>
  );
};
