import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconContactCard = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Contact Card">
          <path id="Shape" d="M28.3827 5.91797C30.1276 5.91797 31.5421 7.33251 31.5421 9.07743V25.2322C31.5421 26.9772 30.1276 28.3917 28.3827 28.3917H6.61747C4.87255 28.3917 3.45801 26.9772 3.45801 25.2322V9.07743C3.45801 7.33251 4.87255 5.91797 6.61747 5.91797H28.3827ZM28.3827 8.02428H6.61747C6.03583 8.02428 5.56432 8.49579 5.56432 9.07743V25.2322C5.56432 25.8139 6.03583 26.2854 6.61747 26.2854H28.3827C28.9643 26.2854 29.4358 25.8139 29.4358 25.2322V9.07743C29.4358 8.49579 28.9643 8.02428 28.3827 8.02428ZM14.3406 17.8537C14.9222 17.8537 15.3938 18.3252 15.3938 18.9069V19.5988L15.3827 19.7512C15.1541 21.314 13.8459 22.0676 11.8832 22.0676C9.92035 22.0676 8.61211 21.3132 8.38378 19.7496L8.37273 19.5974V18.9069C8.37273 18.3252 8.84424 17.8537 9.42588 17.8537H14.3406ZM19.2585 18.5504H25.5742C26.1559 18.5504 26.6274 19.0219 26.6274 19.6036C26.6274 20.1367 26.2312 20.5774 25.7171 20.6471L25.5742 20.6567H19.2585C18.6769 20.6567 18.2054 20.1852 18.2054 19.6036C18.2054 19.0704 18.6016 18.6297 19.1156 18.56L19.2585 18.5504H25.5742H19.2585ZM11.8832 12.24C13.0465 12.24 13.9895 13.183 13.9895 14.3462C13.9895 15.5095 13.0465 16.4525 11.8832 16.4525C10.72 16.4525 9.77697 15.5095 9.77697 14.3462C9.77697 13.183 10.72 12.24 11.8832 12.24ZM19.2585 13.6411H25.5742C26.1559 13.6411 26.6274 14.1126 26.6274 14.6943C26.6274 15.2274 26.2312 15.6681 25.7171 15.7378L25.5742 15.7474H19.2585C18.6769 15.7474 18.2054 15.2759 18.2054 14.6943C18.2054 14.1611 18.6016 13.7204 19.1156 13.6507L19.2585 13.6411H25.5742H19.2585Z" fill="#020404" />
        </g>
      </svg>
    </span>
  );
};
