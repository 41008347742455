import { useEffect, useState, KeyboardEvent } from "react";
import { t } from "i18next";
import { useLocation } from "react-router-dom";
import { Modal } from "@fluentui/react";

import { useBoolean } from "@fluentui/react-hooks";
import { getUserProfile } from "../services/apiService";

import "../assets/styles/components/FirstVisitSteps.scss";
import { UserFirstVisitPersonalInformationEdit } from "./UserProfile/UserEditForms/UserFirstVisitPersonalInformationEdit";
import { UserFirstVisitLinkedInEdit } from "./UserProfile/UserEditForms/UserFirstVisitLinkedInEdit";
import { IUserProfile } from "../interfaces/IUserProfile";
import { DeliwiButton } from "./DeliwiButton";
import { IconArrowForward } from "components/Icons/IconArrowForward";

import Firstvisit1Image from "assets/images/Firstvisit_1.png";
import Firstvisit2Image from "assets/images/Firstvisit_2.png";
import Firstvisit3Image from "assets/images/Firstvisit_3.png";
import Firstvisit4Image from "assets/images/Firstvisit_4.png";

export const FirstVisitPopup = () => {
  const [isFirstVisitPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] = useBoolean(false);
  const [userProfile, setUserProfile] = useState<IUserProfile>();
  const [isOpen, setIsOpen] = useState(true); // Controls if the modal is open
  const [currentStep, setCurrentStep] = useState(1); // Tracks the current step

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/control-panel")) {
      return;
    }

    (async () => {
      const userProfile = await getUserProfile();
      const showIntro = localStorage.getItem("showintro");
      const shouldShowIntro = showIntro === "true";

      if (userProfile) setUserProfile(userProfile);

      if ((!!userProfile && !userProfile.IsProfileInitialized) || shouldShowIntro) {
        setUserProfile(userProfile);
        setCurrentStep(1);
        showPopup();
      }
    })();
  }, []);

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const skipIntro = () => {
    setCurrentStep(5);
  };

  const startApp = (ev?: KeyboardEvent<HTMLElement>) => {
    // Prevent modal from closing when Escape key is pressed
    if (ev && ev.keyCode === 27) {
      // 27 is the keyCode for the Escape key
      ev.stopPropagation();
    } else {
      setIsOpen(false);
      hidePopup();
    }
  };

  const renderIntroSteps = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="firstvisit__step firstvisit__step--step1">
            <div className="tag--candy text-style--Subtitle2 mb-40">{t("Welcome")}</div>
            <div className="firstvisit__step--content mb-40">
              <div className="firstvisit__step--content-col1">
                <h1 className="firstvisit--heading mb-40">{t("FirstVisit.Step1.Title")}</h1>
                <p className="text-style--Title1 text-color--brand10" dangerouslySetInnerHTML={{ __html: t("FirstVisit.Step1.Content") }} />
              </div>
              <div className="firstvisit__step--content-col2">
                <img src={Firstvisit1Image} alt="" />
              </div>
            </div>
            <DeliwiButton className="button-style--primary button-size--large ml-auto" text={t("LetsSeeHowItWorks")} onClick={nextStep}>
              <IconArrowForward />
            </DeliwiButton>
          </div>
        );
      case 2:
        return (
          <div className="firstvisit__step firstvisit__step--step2">
            <div className="firstvisit__steps--indicator mb-40">
              <div className="tag--candy text-style--Subtitle2 ">{t("Search")}</div>
              <div className="tag--inactive text-style--Subtitle2">{t("Get results")}</div>
              <div className="tag--inactive text-style--Subtitle2 ">{t("Contact")}</div>
            </div>
            <div className="firstvisit__step--content mb-40">
              <div className="firstvisit__step--content-col1">
                <h1 className="firstvisit--heading mb-40">{t("FirstVisit.Step2.Title")}</h1>
                <p className="text-style--Title1 text-color--brand10" dangerouslySetInnerHTML={{ __html: t("FirstVisit.Step2.Content") }} />
              </div>
              <div className="firstvisit__step--content-col2">
                <img src={Firstvisit2Image} alt="" />
              </div>
            </div>
            <div className="firstvisit__buttons">
              <DeliwiButton className="button-style--outline button-size--large" text="Skip Intro" onClick={skipIntro} />
              <DeliwiButton className="button-style--primary button-size--large" text={t("Continue")} onClick={nextStep}>
                <IconArrowForward />
              </DeliwiButton>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="firstvisit__step firstvisit__step--step3">
            <div className="firstvisit__steps--indicator mb-40">
              <div className="tag--inactive text-style--Subtitle2 ">{t("Search")}</div>
              <div className="tag--candy text-style--Subtitle2">{t("Get results")}</div>
              <div className="tag--inactive text-style--Subtitle2 ">{t("Contact")}</div>
            </div>
            <div className="firstvisit__step--content mb-40">
              <div className="firstvisit__step--content-col1">
                <h1 className="firstvisit--heading mb-40">{t("FirstVisit.Step3.Title")}</h1>
                <p className="text-style--Title1 text-color--brand10" dangerouslySetInnerHTML={{ __html: t("FirstVisit.Step3.Content") }} />
              </div>
              <div className="firstvisit__step--content-col2">
                <img src={Firstvisit3Image} alt="" />
              </div>
            </div>
            <div className="firstvisit__buttons">
              <DeliwiButton className="button-style--outline button-size--large" text="Skip Intro" onClick={skipIntro} />
              <DeliwiButton className="button-style--primary button-size--large" text={t("Continue")} onClick={nextStep}>
                <IconArrowForward />
              </DeliwiButton>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="firstvisit__step firstvisit__step--step4">
            <div className="firstvisit__steps--indicator mb-40">
              <div className="tag--inactive text-style--Subtitle2 ">{t("Search")}</div>
              <div className="tag--inactive text-style--Subtitle2">{t("Get results")}</div>
              <div className="tag--candy text-style--Subtitle2 ">{t("Contact")}</div>
            </div>
            <div className="firstvisit__step--content mb-40">
              <div className="firstvisit__step--content-col1">
                <h1 className="firstvisit--heading mb-40">{t("FirstVisit.Step4.Title")}</h1>
                <p className="text-style--Title1 text-color--brand10" dangerouslySetInnerHTML={{ __html: t("FirstVisit.Step4.Content") }} />
              </div>
              <div className="firstvisit__step--content-col2">
                <img src={Firstvisit4Image} alt="" />
              </div>
            </div>
            <div className="firstvisit__buttons">
              <DeliwiButton className="button-style--outline button-size--large" text="Skip Intro" onClick={skipIntro} />
              <DeliwiButton className="button-style--primary button-size--large" text={t("StartToUseApp")} onClick={skipIntro} />
            </div>
          </div>
        );
      case 5:
        return (
          <div className="firstvisit__step firstvisit__step--step5">
            <div className="generalmodal__title mb-24">
              <h2 className="text-style--Title2 mb-16">{t("Please, check and enrich your profile data")}</h2>
            </div>
            <div className="text-align--center">
              <div className="firstvisit__steps--indicator-large mb-24">
                <div className="tag--candy text-style--Subtitle2 ">{t("PersonalInformation")}</div>
                <div className="tag--inactive text-style--Subtitle2">{t("ConnectWithLinkedIn")}</div>
              </div>
            </div>
            <UserFirstVisitPersonalInformationEdit userProfileData={userProfile} userProfileSetter={setUserProfile} goToNextStep={nextStep} />
          </div>
        );
      case 6:
        return (
          <div className="firstvisit__step firstvisit__step--step6">
            <div className="generalmodal__title mb-24">
              <h2 className="text-style--Title2 mb-16">{t("Please, check and enrich your profile data")}</h2>
            </div>
            <div className="text-align--center">
              <div className="firstvisit__steps--indicator mb-24">
                <div className="tag--inactive tag--checkmark text-style--Subtitle2 ">{t("PersonalInformation")}</div>
                <div className="tag--candy text-style--Subtitle2">{t("ConnectWithLinkedIn")}</div>
              </div>
            </div>
            <UserFirstVisitLinkedInEdit userProfileData={userProfile} userProfileSetter={setUserProfile} finishSteps={startApp} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isFirstVisitPopupVisible && location.pathname !== "/control-panel" && (
        <Modal isOpen={isOpen} onDismiss={() => startApp} isBlocking={true} className="firstvisit text-color--black" containerClassName={currentStep > 4 ? "firstvisit__modal--small" : "firstvisit__modal"}>
          <div className="firstvisit__modal--small-padding">{renderIntroSteps()}</div>
        </Modal>
      )}
    </>
  );
};
