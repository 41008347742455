import { InteractionRequiredAuthError, IPublicClientApplication } from "@azure/msal-browser";
import { apis } from "msalConfig";
import { IUserProfile } from "interfaces/IUserProfile";
import { IUser, IUserResults, IUserProfilesResponse } from "interfaces/IUser";
import { ReportData } from "interfaces/IControlPanel";
import { InitialSetup, InitialSetupConfig } from "interfaces/InitialSetup";
import { IApi } from "interfaces/IApi";
import { ITeamsApp } from "interfaces/ITeamsApp";

export interface IGraphCollectionResponse<T> {
  value: T[];
}

// Initialization

let msalInstance: IPublicClientApplication;

export const setMsalInstance = async (instance: IPublicClientApplication) => {
  msalInstance = instance;
  await msalInstance.initialize();
};

// Private methods

const getAccessToken = async (scopes: string[]): Promise<string | null> => {
  let authResult = null;

  const account = msalInstance.getActiveAccount() ?? msalInstance.getAllAccounts()[0];

  try {
    authResult = await msalInstance.acquireTokenSilent({ scopes: scopes, account: account });
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // Initiate the redirect; the function will not return a token immediately
      msalInstance.acquireTokenRedirect({ scopes: scopes });
      return null; // Since redirect will happen, there's no token to return immediately.
    } else {
      throw error; // Rethrow any other errors
    }
  }

  return authResult ? authResult.accessToken : null;
};

const getHeaders = async (scopes: string[], contentType?: string) => {
  const headers = new Headers();
  const accessToken = await getAccessToken(scopes);
  headers.append("Authorization", `Bearer ${accessToken}`);
  if (contentType !== "remove-content-type") {
    headers.append("Content-Type", contentType ?? "application/json");
  }

  return headers;
};

const makeRequest = async (api: IApi, url: string, method: string, body?: any, contentType?: string, customHeaders?: Record<string, string>) => {
  url = `${api.endpoint}${url}`;
  let headers = await getHeaders(api.scopes, contentType);

  // Correctly add custom headers if provided
  if (customHeaders) {
    Object.keys(customHeaders).forEach((key) => {
      headers.set(key, customHeaders[key]);
    });
  }

  let options;
  switch (contentType) {
    case "application/zip": {
      options = {
        method: method,
        headers: headers,
        body: body,
      };
      break;
    }

    default: {
      options = {
        method: method,
        headers: headers,
        body: body && contentType !== "remove-content-type" ? JSON.stringify(body) : body,
      };
      break;
    }
  }

  const response = await fetch(url, options);

  try {
    // Check if the response is ok and has content
    if (response && response.ok && response.headers.get("Content-Type")?.includes("application/json")) {
      const data = await response.json();
      return data;
    } else {
      // Handle non-JSON responses or responses with no content
      // console.log("Received a non-JSON response or no content");
      // console.log(response);
      return response;
    }
  } catch (e) {
    console.error("Error making request:", e);
    return null;
  }
};

const get = async (api: IApi, url: string, customHeaders?: Record<string, string>) => {
  return await makeRequest(api, url, "GET", undefined, undefined, customHeaders);
};

const patch = async (api: IApi, url: string, body: any) => {
  return await makeRequest(api, url, "PATCH", body);
};

const post = async (api: IApi, url: string, body: any, contentType?: string) => {
  return await makeRequest(api, url, "POST", body, contentType);
};

const del = async (api: IApi, url: string) => {
  return await makeRequest(api, url, "DELETE");
};

const put = async (api: IApi, url: string, body: any, contentType?: string) => {
  return await makeRequest(api, url, "PUT", body, contentType);
};

// Public methods

export const getUserProfile = async (): Promise<IUserProfile> => {
  return await get(apis.customerApi, "/UserProfile");
};

export const getUserProfileByIdentifier = async (identifier: string): Promise<IUserProfile> => {
  return await get(apis.customerApi, "/UserProfile?userIdOrPrincipalName=" + encodeURIComponent(identifier));
};

export const getUserProfiles = async (offset: number, limit: number, searchTerm?: string, orderByDescending: boolean = false, orderByColumn: number = 0): Promise<IUserProfilesResponse> => {
  let url = `/UserProfiles?offset=${offset}&limit=${limit}`;

  if (searchTerm && searchTerm.trim() !== "") {
    url += `&searchTerm=${encodeURIComponent(searchTerm)}`;
  }

  // Append orderByDescending and orderByColumn to the URL
  url += `&orderByDescending=${orderByDescending}&orderByColumn=${orderByColumn}`;

  return await get(apis.customerApi, url);
};

export const getUserProfilesByDepartment = async (department: string): Promise<IUserProfilesResponse> => {
  return await get(apis.customerApi, `/UserProfiles?departmentName=${encodeURIComponent(department)}`);
};

export const getAnalysisUsers = async (offset: number, limit: number, searchTerm?: string): Promise<IUserProfilesResponse> => {
  let url = `/UserProfiles?offset=${offset}&limit=${limit}`;
  if (searchTerm && searchTerm.trim() !== "") {
    url += `&searchTerm=${encodeURIComponent(searchTerm)}`;
  }
  return await get(apis.customerApi, url);
};

export const saveUserProfile = async (userProfile: IUserProfile): Promise<void> => {
  return await patch(apis.customerApi, "/UserProfile", userProfile);
};

export const deleteUserProfile = async (Id: string): Promise<void> => {
  return await del(apis.customerApi, `/UserProfile?id=${Id}`);
};

export const getAnalysisResults = async (skillName: string, userIds?: string[], department?: string) => {
  // Construct the URL with query parameters for skillName
  let url = `/Analysis?skillName=${encodeURIComponent(skillName)}`;

  // Add department to the URL only if it's provided
  if (department) {
    url += `&department=${encodeURIComponent(department)}`;
  }

  // Prepare the request body. Include userIds if provided and not empty
  const hasUserIds = userIds && userIds.length > 0;
  const requestBody = hasUserIds ? userIds : [];

  // Assuming `post` is a custom method for making POST requests.
  // Modify the method call according to how your `post` method is defined, especially regarding handling of `undefined` requestBody
  return await post(apis.customerApi, url, requestBody);
};

export const getUserProfilesDepartments = async (searchString: string): Promise<string[]> => {
  return await get(apis.customerApi, `/UserProfiles/Departments?searchTerm=${searchString}`);
};

export const searchUsersGroups = async (searchString: string): Promise<IUserResults> => {
  return await get(apis.customerApi, `/UsersAndGroupsSearch?searchString=${searchString}`);
};

export const searchUserProfiles = async (searchString: string): Promise<IUserProfile[]> => {
  return await get(apis.customerApi, `/UserSearch?searchString=${searchString}`);
};

export const getUserRole = async (): Promise<string[]> => {
  return await get(apis.customerApi, "/UserRole");
};

export const getAdminReport = async (): Promise<ReportData> => {
  return await get(apis.customerApi, "/AdminReport");
};

export const sendUserLoginTime = async (): Promise<string[]> => {
  const customHeaders = {
    UpdateLastLogIn: "true",
  };

  return await get(apis.customerApi, "/UserRole", customHeaders);
};

export const inviteUsers = async (users: IUser[]): Promise<any> => {
  return await post(apis.customerApi, `/Invite`, users);
};

export const getTeamsBotStatus = async (): Promise<{ TeamsBotEnabled: boolean }> => {
  return await get(apis.customerApi, "/AdminConfiguration");
};

export const updateTeamsBotStatus = async (data: { InviteMessageTemplate: string; TeamsBotEnabled: boolean }) => {
  return await post(apis.customerApi, "/AdminConfiguration", data);
};

export const updateProfilePicture = async (data: FormData) => {
  return await post(apis.customerApi, "/ProfilePicture", data, "remove-content-type");
};

export const getProfilePicture = async (userid: string): Promise<string[]> => {
  return await get(apis.customerApi, `/ProfilePicture?userProfileId=${userid}`);
};

export const searchSkills = async (searchString: string): Promise<string[]> => {
  return await get(apis.customerApi, `/Skills?query=${searchString}`);
};

export const searchInterests = async (searchString: string): Promise<string[]> => {
  return await get(apis.customerApi, `/InterestKeyword?query=${searchString}`);
};

export const sendUsersLinkedInData = async (linkedInConsent: boolean, profileUrl: string) => {
  return await post(apis.customerApi, `/LinkedIn?giveLinkedInSearchConsent=${linkedInConsent}&linkedInProfileUrl=${profileUrl}`, "");
};

export const adminUpdatePermissions = async (userProfileId: string, permissions: number[]): Promise<void> => {
  return await patch(apis.customerApi, `/AdminConfiguration/UserProfiles/${userProfileId}/Permissions`, permissions);
};

export const adminUpdateVisibility = async (userProfileId: string, visibility: boolean): Promise<void> => {
  return await patch(apis.customerApi, `/AdminConfiguration/UserProfiles/${userProfileId}/Visibility?visibility=${visibility}`, { visibility });
};

export const getInitialSetupStatus = async (): Promise<InitialSetup> => {
  return await get(apis.customerApi, "/InitialSetup/Status");
};

export const postInitialSetupConfigurations = async (config: InitialSetupConfig) => {
  // console.log(config);
  return await post(apis.customerApi, `/InitialSetup/Configurations`, config);
};

export const postInitialSetupAdmins = async (userIds: string[]): Promise<any> => {
  // console.log(userIds);
  return await post(apis.customerApi, `/InitialSetup/Admins`, userIds);
};

export const getInitialSetupUsers = async (searchTerm: string): Promise<IUserResults> => {
  return await get(apis.customerApi, `/InitialSetup/Users?searchTerm=${searchTerm}`);
};

export const getUserAvailability = async (userId: string): Promise<any> => {
  return await get(apis.graphApi, `/users/${userId}/presence`);
};

export const rewriteTextWithAI = async (fieldName: string, text: string): Promise<any> => {
  return await post(apis.customerApi, `/Rewrite/${fieldName}`, { Text: text });
};

export const postSurvey = async (userIds: string[], skill: string, title: string): Promise<any> => {
  return await post(apis.customerApi, `/Surveys`, { UserIDs: userIds, Skill: skill, Title: title });
};

export const getSurveyResults = async (surveyId: string): Promise<any> => {
  return await get(apis.customerApi, `/Surveys/${surveyId}`);
};

export const getSurveys = async (): Promise<any> => {
  return await get(apis.customerApi, `/Surveys?count=100`);
};

export const updateSurvey = async (surveyId: string, title: string, status: number): Promise<any> => {
  return await put(apis.customerApi, `/Surveys/${surveyId}`, { Title: title, Status: status });
};

export const deleteSurvey = async (surveyId: string): Promise<void> => {
  return await del(apis.customerApi, `/Surveys/${surveyId}`);
};

export const getTeamsApps = async (displayName: string): Promise<ITeamsApp[]> => {
  const response: IGraphCollectionResponse<ITeamsApp> = await get(apis.graphApi, `/appCatalogs/teamsApps?$filter=displayName eq '${displayName}'`);
  return response?.value;
};

export const addTeamsApp = async (zipFile: any): Promise<ITeamsApp> => {
  return await post(apis.graphApi, `/appCatalogs/teamsApps`, zipFile, "application/zip");
};

export const removeTeamsApp = async (appId: string): Promise<void> => {
  return await del(apis.graphApi, `/appCatalogs/teamsApps/${appId}`);
};
