import { useState } from "react";

import { t } from "i18next";

import { Modal, IconButton, IIconProps } from "@fluentui/react";

import IconDiamond from "assets/icons/icon-diamond.svg";
import IconUserQuestionmark from "assets/icons/icon-user-questionmark.svg";
import IconUserSearch from "assets/icons/icon-user-search.svg";
import { IconInfo } from "components/Icons/IconInfo";
import { IconClose } from "components/Icons/IconClose";
import "assets/styles/components/FillRateInfo.scss";
import { DeliwiButton } from "components/DeliwiButton";

const cancelIcon: IIconProps = { iconName: "Cancel" };

export const FillRateInfo = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleToggleDialog = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="fill-rate-info">
      <DeliwiButton className="button-style--outline bg-white button-size--mediumicon" onClick={handleToggleDialog}>
        <IconInfo />
      </DeliwiButton>

      <Modal isOpen={isModalOpen} onDismiss={handleToggleDialog} isBlocking={true} className="text-color--black" containerClassName="fill-rate-info__modal">
        <div className="fill-rate-info__modal--header mb-24">
          <div className="text-style--Title3">{t("FillRateInfo.Title")}</div>
          <IconButton iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={handleToggleDialog} />
        </div>
        <p className="text-style--Subtitle1">{t("FillRateInfo.Subtitle")}</p>
        <div className="fill-rate-info__fields-columns">
          <div className="column-left">
            <div className="field-group">
              <div className="text-style--Body1 mb-8">{t("PersonalInformation")}:</div>
              <ul className="fill-rate-info--fields-list">
                <li className="text-style--Body1">{t("LastName")}</li>
                <li className="text-style--Body1">{t("Email")}</li>
                <li className="text-style--Body1">{t("Language")}</li>
              </ul>
            </div>
            <div className="field-group">
              <div className="text-style--Body1 mb-8">{t("CompanyDetails")}:</div>
              <ul className="fill-rate-info--fields-list">
                <li className="text-style--Body1">{t("CompanyName")}</li>
                <li className="text-style--Body1">{t("CompanyCity")}</li>
                <li className="text-style--Body1">{t("CompanyCountry")}</li>
                <li className="text-style--Body1">{t("CompanyDepartment")}</li>
                <li className="text-style--Body1">{t("JobTitle")}</li>
                <li className="text-style--Body1">{t("Supervisor")}</li>
              </ul>
            </div>
          </div>
          <div className="column-right">
            <div className="field-group">
              <div className="text-style--Body1 mb-8">{t("WorkExperience")}:</div>
              <ul className="fill-rate-info--fields-list">
                <li className="text-style--Body1">{t("RecentProjects")}</li>
                <li className="text-style--Body1">{t("WorkHistory")}</li>
                <li className="text-style--Body1">{t("CurrentWork")}</li>
              </ul>
            </div>
            <div className="field-group">
              <div className="text-style--Body1 mb-8">{t("EducationTrainings")}:</div>
              <ul className="fill-rate-info--fields-list">
                <li className="text-style--Body1">{t("Education")}</li>
              </ul>
            </div>
            <div className="field-group">
              <div className="text-style--Body1 mb-8">{t("Skills")}:</div>
              <ul className="fill-rate-info--fields-list">
                <li className="text-style--Body1">{t("Skills")}</li>
                <li className="text-style--Body1">{t("LanguageSkills")}</li>
              </ul>
            </div>
          </div>
        </div>

        <p className="text-style--Subtitle1 mb-24">{t("FillRateInfo.FooterText")}</p>

        <DeliwiButton onClick={handleToggleDialog} className="button-style--secondary button-size--medium ml-auto">
          <IconClose className="icon-size--16" />
          {t("Close")}
        </DeliwiButton>
      </Modal>
    </div>
  );
};
