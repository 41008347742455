import { Dispatch, SetStateAction, createContext, useContext, useState, useEffect, ReactNode } from "react";
import { getUserRole, getUserProfile } from "../services/apiService";

// Define the context type
interface UserContextType {
  userRole: string[];
  setUserRole: Dispatch<SetStateAction<string[]>>;
}

// Create the context
const UserRoleContext = createContext<UserContextType | undefined>(undefined);

// Create a provider component
export const UserRoleProvider = ({ children }: { children: ReactNode }) => {
  const [userRole, setUserRole] = useState<string[]>([]);

  function mapPermissionsToRoles(permissionsArray: number[]): string[] {
    // Define the roles in an object with the numeric permissions as keys
    const rolesMap: { [key: number]: string } = {
      1: "user",
      2: "superuser",
      3: "admin",
      10: "admin",
    };

    // Map each permission number to its corresponding role
    const roles = permissionsArray.map((permission) => rolesMap[permission] || "unknown");

    return roles;
  }

  useEffect(() => {
    const fetchRoles = async () => {
      // const response = await getUserRole();
      const profile = await getUserProfile();
      // console.log(profile);
      // console.log(profile.Permissions);
      setUserRole(mapPermissionsToRoles(profile.Permissions));
    };
    fetchRoles();
  }, []);

  // useEffect(() => {
  //   console.log(userRole); // This will log the updated state after changes
  // }, [userRole]); // This effect runs whenever `userRole` changes

  return <UserRoleContext.Provider value={{ userRole, setUserRole }}>{children}</UserRoleContext.Provider>;
};

// Hook to use the context
export const useUserRole = () => {
  const context = useContext(UserRoleContext);
  if (context === undefined) {
    throw new Error("useUserRole must be used within a UserRoleProvider");
  }
  return context;
};
