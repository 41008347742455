import { t } from "i18next";

import IconTools from "assets/icons/icon-tools.svg";
import "assets/styles/pages/HelpCenter.scss";
import PeopleAskingImage from "assets/images/people-asking.png";
import ServiceFormChat from "../ServiceFormChat";

export const TabContactSupport = () => {
  return (
    <>
      <div className="admin-box help-center--contact-support">
        <h2 className="text-style--Large-title text-color--white mb-32" dangerouslySetInnerHTML={{ __html: t("HelpCenter.ContactSupport.Title") }}></h2>
        <div className="chat-area--container">
          <ServiceFormChat />
          <div className="chat-friend-image">
            <img src={PeopleAskingImage} alt="Deliwi Contact Support" />
          </div>
        </div>
      </div>
    </>
  );
};
