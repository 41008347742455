import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_UI_CLIENT_ID}`,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_UI_TENANT_ID}`, // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: `${process.env.REACT_APP_UI_REDIRECT_URI}`, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: `${process.env.REACT_APP_UI_REDIRECT_URI}`, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.//
  },
  cache: {
    cacheLocation: `${process.env.REACT_APP_UI_CACHE_LOCATION}`, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          // case LogLevel.Error:
          //   console.error(message);
          //   return;
          // case LogLevel.Info:
          //   console.info(message);
          //   return;
          // case LogLevel.Verbose:
          //   console.debug(message);
          //   return;
          // case LogLevel.Warning:
          //   console.warn(message);
          //   return;
          default:
            return;
        }
      },
    },
  },
};

// Scopes you add here will be prompted for user consent during sign-in. By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
export const loginRequest = {
  scopes: [],
};

// The endpoints and scopes when obtaining an access token for protected web APIs.
export const apis = {
  customerApi: {
    endpoint: `${process.env.REACT_APP_API_ENDPOINT_URI}`,
    scopes: [`${process.env.REACT_APP_API_SCOPE_URI}/access_as_user`], // api://client_id/access_as_user
  },
  graphApi: {
    endpoint: "https://graph.microsoft.com/v1.0",
    scopes: ["User.Read", "Presence.Read.All", "AppCatalog.ReadWrite.All"],
  },
};
