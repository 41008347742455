import * as React from "react";
import { t } from "i18next";
import { DeliwiButton } from "components/DeliwiButton";

import { IconTrash } from "./Icons/IconTrash";
import { IconDismiss } from "./Icons/IconDismiss";

interface IConfirmRowProps {
  confirmDialogVisible: boolean; // Changed from confirmDialogOpen to confirmDialogVisible
  setConfirmDialogVisible(data: boolean): void; // Ensure this matches your component's props
  onConfirm(): void;
}

export const ConfirmDeleteRow = (props: IConfirmRowProps) => {
  if (!props.confirmDialogVisible) return null;

  return (
    <div className="delete-itemrow" role="dialog" aria-modal="true">
      <div role="document" className="">
        <div className="">
          <div className="">
            <div className="">
              <div className="">
                <h2 className="text-style--Subtitle1 text-color--neutral-foreground mb-16">{t("ConfirmRemoveTitle")}</h2>
              </div>
            </div>
          </div>
          <div className="edit-dialog--buttons">
            <DeliwiButton className="button-style--outline button-size--large" onClick={() => props.setConfirmDialogVisible(false)}>
              <IconDismiss className="icon-size--16" />
              {t("Cancel")}
            </DeliwiButton>
            <DeliwiButton
              className="button-style--delete button-size--large"
              onClick={() => {
                props.setConfirmDialogVisible(false);
                props.onConfirm();
              }}
            >
              <IconTrash className="icon-size--20" />
              {t("YesDelete")}
            </DeliwiButton>
          </div>
        </div>
      </div>
    </div>
  );
};
