import { useState, useEffect } from "react";

import { getTeamsBotStatus } from "services/apiService";
import { PeoplePickerDialog } from "components/ControlPanel/PeoplePickerDialog";

import "assets/styles/pages/ControlPanel.scss";

export const AddUsers = () => {
  const [teamsBotStatus, setTeamsBotStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchTeamsBotStatus = async () => {
      try {
        const response = await getTeamsBotStatus();
        if (response) {
          setTeamsBotStatus(response.TeamsBotEnabled);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch Teams Bot status", error);
        setIsLoading(false);
      }
    };
    fetchTeamsBotStatus();
  }, []);

  if (isLoading) {
    return <div className="deliwi-spinner"></div>;
  }

  return teamsBotStatus ? (
    <>
      <PeoplePickerDialog />
    </>
  ) : (
    // Your JSX for when teamsBotStatus is false
    <div>{/* Content to display when teamsBotStatus is false */}</div>
  );
};
