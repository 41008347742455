import { t } from "i18next";

// Icons imports
import DeliwiRound from "assets/images/deliwi-round.svg";

interface SurveyMessagePreviewProps {
  skillName: string;
}

export const SurveyMessagePreview = ({ skillName }: SurveyMessagePreviewProps) => {
  return (
    <>
      <div className="admin-survey__steps--skills-msg-box">
        <div>
          <img src={DeliwiRound} className="icon-size-62 icon-shadow logo" alt="" />
        </div>
        <div>
          <p className="text-style--Body1 text-color--brand15 fw-700 mb-16">{t("Survey.Msg.Hi")}</p>
          <p className="text-style--Body1 text-color--brand15 fw-700 mb-8">{t("Survey.Msg.TryingToSolve")}</p>
          <p className="text-style--Body1 text-style--italic text-color--brand80 fw-600 mb-24" id="skill-label">
            {skillName || "Skill you choose from the list or a new skill that you write"}
          </p>
          <p className="text-style--Body1 text-color--brand15 mb-16">{t("Survey.Msg.Describes")}</p>
          <div className="joku">
            <div className="survey-legend">
              <div className="legend-item mb-8" data-index="0">
                <div className="legend-label">
                  <div className="stars stars-0"></div>
                  <div className="title text-style--Body2">{t("SkillLevel.Expert")}</div>
                </div>
              </div>

              <div className="legend-item mb-8" data-index="1">
                <div className="legend-label">
                  <div className="stars stars-1"></div>
                  <div className="title text-style--Body2">{t("SkillLevel.Pro")}</div>
                </div>
              </div>

              <div className="legend-item mb-8" data-index="2">
                <div className="legend-label">
                  <div className="stars stars-2"></div>
                  <div className="title text-style--Body2">{t("SkillLevel.Basic")}</div>
                </div>
              </div>

              <div className="legend-item mb-8" data-index="3">
                <div className="legend-label">
                  <div className="title text-style--Body2">{t("SkillLevel.NotFamiliar")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
