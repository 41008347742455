import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconHelpCenter = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 16.5C6.58579 16.5 6.25 16.8358 6.25 17.25C6.25 17.6642 6.58579 18 7 18C7.41421 18 7.75 17.6642 7.75 17.25C7.75 16.8358 7.41421 16.5 7 16.5ZM12.5 8V7C12.5 3.96243 10.0376 1.5 7 1.5C3.96243 1.5 1.5 3.96243 1.5 7V8H4C4.55228 8 5 8.44772 5 9V13C5 13.5523 4.55228 14 4 14H1.5V14.25C1.5 15.4409 2.42516 16.4156 3.59595 16.4948L3.75 16.5L4.87812 16.4997C5.18707 15.626 6.02043 15 7 15C8.24264 15 9.25 16.0074 9.25 17.25C9.25 18.4926 8.24264 19.5 7 19.5C6.02005 19.5 5.18642 18.8735 4.87776 17.9992L3.75 18C1.74574 18 0.108726 16.4276 0.005198 14.4492L0 14.25V7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7V12C14 13.0544 13.1841 13.9182 12.1493 13.9945L12 14H10C9.48716 14 9.06449 13.614 9.00673 13.1166L9 13V9C9 8.48716 9.38604 8.06449 9.88338 8.00673L10 8H12.5ZM3.5 9.5H1.5V12.5H3.5V9.5ZM12.5 9.5H10.5V12.5H12C12.2455 12.5 12.4496 12.3231 12.4919 12.0899L12.5 12V9.5Z" fill="#707070" />
      </svg>
    </span>
  );
};
