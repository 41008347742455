import { useEffect, useState } from "react";
import { t } from "i18next";
import { searchUserProfiles } from "services/apiService";
import { IUserProfile } from "interfaces/IUserProfile";
import { ProfileCard } from "components/ProfileCard";
import { SearchField } from "components/SearchField";
import { UserProfilePreview } from "components/UserProfilePreview";
import { SearchInstructions } from "components/Search/SearchInstructions";
import { NoResults } from "components/Search/NoResults";
import { QuickTour } from "components/QuickTour";

import "../assets/styles/pages/Search.scss";
import SearchLoadingAnimation from "../assets/images/search-loading-animation-4.gif";

export const Search = () => {
  const [searchGreeting, setSearchGreeting] = useState<string>(t("DefaultSearchGreeting"));
  const [searchDescription, setSearchDescription] = useState<string>(t("DefaultSearchDescription"));
  const [searchResults, setSearchResults] = useState<IUserProfile[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [profileToPreview, setProfileToPreview] = useState<IUserProfile | undefined>(undefined);
  const [progress, setProgress] = useState(0);
  const [progressInterval, setProgressInterval] = useState<NodeJS.Timeout | null>(null);
  const [searchTime, setSearchTime] = useState(0);
  let interval: any = 0;
  let finalizingInterval: any = 0;

  const finalizeProgressBar = async (ms: number) => {
    if (progress < 100) {
      interval = setInterval(() => {
        setProgress((prev) => Math.min(prev + 2, 100));
      }, 10);
    }

    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const search = async (searchString: string) => {
    setLoading(true);
    setProfileToPreview(undefined);
    setSearchGreeting(t("ExecutedSearchGreeting"));
    setSearchDescription(t("ExecutedSearchDescription", { searchString: searchString }));
    setSearchResults(undefined);
    const searchResults = await searchUserProfiles(searchString);
    await finalizeProgressBar(1000);
    setSearchResults(searchResults);
    setLoading(false);
    clearInterval(interval);
    setProgress(0);
    setSearchTime(0);
  };

  useEffect(() => {
    if (loading) {
      interval = setInterval(() => {
        setProgress((prev) => Math.min(prev + 2, 100));
        setSearchTime((prevTime) => prevTime + 1);
      }, 1000);
      setProgressInterval(interval);
      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
  }, [loading]);

  const triggerProfileToPreview = (userProfile: IUserProfile) => {
    setProfileToPreview(userProfile);
  };

  const closePreview = () => {
    setProfileToPreview(undefined);
  };

  return (
    <>
      <div className="profile-search-page">
        <div className={`profile-search-page--container ${profileToPreview !== undefined ? "preview-state" : ""}`}>
          <div className={`profile-search--search-area--container ${profileToPreview !== undefined ? "preview-state" : ""}`}>
            <h1 className="profile-search--search-area--title">
              <span className={`d-block ${loading || searchResults ? "text-style--Title3 text-style--semibold mb-24" : "text-style--Main-title mb-32"}`}>{searchGreeting}</span>
              <span className={`d-block mb-40 ${loading || searchResults ? "text-style--Body1" : "text-style--Main-title"}`}>{searchDescription}</span>
            </h1>
            <div className="profile-search--search-area--search">
              <SearchField search={search} />
              <QuickTour />
            </div>
            <div className="profile-search--content">
              {searchResults && searchResults?.length > 0 ? (
                <div className="profile-search--results">
                  {searchResults?.map((userProfile, index) => {
                    return (
                      <div className="profile-search--results--result--wrapper" key={index}>
                        <div className="profile-search--results--result">
                          <ProfileCard userProfile={userProfile} index={index} triggerProfileToPreview={triggerProfileToPreview} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : loading ? (
                <div className="profile-search--loading">
                  <img className="profile-search--loading--animation" src={SearchLoadingAnimation} />
                  <div className="search-loader--wrapper">
                    <progress value={progress} max="100" />
                  </div>
                  <p className="text-style--Subtitle1 text-color--black mb-16">{t("SearchLoadingText1")}</p>
                  {searchTime > 5 ? <p className="text-style--Body1">{t("SearchLoadingText2LongSearch")}</p> : <p className="text-style--Body1 mb-0">{t("SearchLoadingText2")}</p>}

                  <p className="text-style--Body1 mb-8">{t("SearchLoadingText3")}</p>
                </div>
              ) : searchResults && searchResults.length === 0 ? (
                <div className="profile-search--no-results">
                  <NoResults />
                </div>
              ) : (
                <div className="profile-search--instructions">
                  <SearchInstructions />
                </div>
              )}
            </div>
          </div>
          {profileToPreview !== undefined && (
            <div className="profile-search--profile-preview">
              <UserProfilePreview key={profileToPreview.id} userProfile={profileToPreview} closePreview={closePreview} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
