import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useLocation } from "react-router-dom";

import { SideBar } from "./SideBar";
import { IconFeedBack } from "components/Icons/IconFeedBack";
import { DeliwiButton } from "components/DeliwiButton";

interface PageLayoutProps {
  children: any; // Any components specified within the PageLayout tags will be rendered as children
}

export const PageLayout = (props: PageLayoutProps) => {
  const location = useLocation(); // Get the current location

  const isSetupRoute = location.pathname === "/setup"; // Check if the current route is /setup

  return (
    <div className="app-wrapper">
      {!isSetupRoute && ( // Conditionally render the sidebar
        <div className="sidebar">
          <SideBar />
        </div>
      )}
      <div className="content">
        <div className="pilot-feedback">
          <a href="https://www.deliwi.ai/pilot-feedback" target="_blank" rel="noreferrer">
            <DeliwiButton className="button-style--outline button-size--small ml-auto bg-white" text="Pilot user, give us feedback">
              <IconFeedBack />
            </DeliwiButton>
          </a>
        </div>
        {props.children} {/* The visibility of children depends on their content (usage of Authenticated/UnauthenticatedTemplate tags) */}
        <AuthenticatedTemplate>{/* Visible on all pages if the user is authenticated */}</AuthenticatedTemplate>
        <UnauthenticatedTemplate>{/* Visible on all pages if the user is not logged in */}</UnauthenticatedTemplate>
      </div>
    </div>
  );
};
