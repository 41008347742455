import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconArrowUp = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.99792 4.70981L6.35355 7.35418C6.15829 7.54944 5.84171 7.54944 5.64645 7.35418C5.45118 7.15891 5.45118 6.84233 5.64645 6.64707L9.11477 3.17874C9.20649 3.06947 9.3441 3 9.49792 3H9.49959C9.57828 2.99998 9.65697 3.01841 9.72861 3.05531C9.77374 3.07847 9.81608 3.10895 9.85387 3.14675L9.86386 3.15701L13.3539 6.64709C13.5492 6.84235 13.5492 7.15893 13.3539 7.3542C13.1587 7.54946 12.8421 7.54946 12.6468 7.3542L9.99792 4.7053V16.5C9.99792 16.7761 9.77407 17 9.49792 17C9.22178 17 8.99792 16.7761 8.99792 16.5V4.70981Z" fill="#242424" />
      </svg>
    </span>
  );
};
