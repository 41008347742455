import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconMicrophone = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.25 9.5C13.6297 9.5 13.9435 9.78215 13.9932 10.1482L14 10.25V10.75C14 14.3094 11.245 17.2254 7.75098 17.4817L7.75 19.75C7.75 20.1642 7.41421 20.5 7 20.5C6.6203 20.5 6.30651 20.2178 6.25685 19.8518L6.25 19.75L6.25002 17.4818C2.83323 17.2316 0.122835 14.438 0.00406027 10.9863L0 10.75V10.25C0 9.83579 0.335786 9.5 0.75 9.5C1.1297 9.5 1.44349 9.78215 1.49315 10.1482L1.5 10.25V10.75C1.5 13.577 3.73445 15.8821 6.5336 15.9956L6.75 16H7.25C10.077 16 12.3821 13.7656 12.4956 10.9664L12.5 10.75V10.25C12.5 9.83579 12.8358 9.5 13.25 9.5ZM7 0.5C9.20914 0.5 11 2.29086 11 4.5V10.5C11 12.7091 9.20914 14.5 7 14.5C4.79086 14.5 3 12.7091 3 10.5V4.5C3 2.29086 4.79086 0.5 7 0.5Z" fill="#C2C2C2" />
      </svg>
    </span>
  );
};
