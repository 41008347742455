import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconDismissCircle = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2ZM10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3ZM7.80943 7.11372L7.87868 7.17157L10 9.29289L12.1213 7.17157C12.2949 6.99801 12.5643 6.97872 12.7592 7.11372L12.8284 7.17157C13.002 7.34514 13.0213 7.61456 12.8863 7.80943L12.8284 7.87868L10.7071 10L12.8284 12.1213C13.002 12.2949 13.0213 12.5643 12.8863 12.7592L12.8284 12.8284C12.6549 13.002 12.3854 13.0213 12.1906 12.8863L12.1213 12.8284L10 10.7071L7.87868 12.8284C7.70511 13.002 7.43569 13.0213 7.24082 12.8863L7.17157 12.8284C6.99801 12.6549 6.97872 12.3854 7.11372 12.1906L7.17157 12.1213L9.29289 10L7.17157 7.87868C6.99801 7.70511 6.97872 7.43569 7.11372 7.24082L7.17157 7.17157C7.34514 6.99801 7.61456 6.97872 7.80943 7.11372Z" fill="#08757A" />
      </svg>
    </span>
  );
};
