import { Dispatch, useState, SetStateAction } from "react";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react";
import { IUser } from "interfaces/IUser"; // Import IUser interface from your interfaces file
import { DeliwiButton } from "components/DeliwiButton";
import { HelpDialog } from "components/HelpDialog";
import { IconRunAnalysis } from "components/Icons/IconRunAnalysis";
import { SurveyMessagePreview } from "components/ControlPanel/SurveyMessagePreview";
import { postSurvey, getSurveyResults } from "services/apiService";
import { t } from "i18next";

import IconDismiss from "assets/icons/icon-dismiss.svg";
import { IconChevron } from "components/Icons/IconChevron";
import { IconClose } from "components/Icons/IconClose";

interface SurveyModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setCreatedSurveyId: Dispatch<SetStateAction<string>>;
  // onClose: () => void;
  // isLoading: boolean;
  // createdSurveyId: string;
  surveyTitle: string;
  surveySkill: string;
  selectedSurveyUsers: IUser[];
  nextStep?: number;
  setNextStep?: Dispatch<SetStateAction<number>>;
}

export const SurveyPreview = ({ isOpen, setIsOpen, setCreatedSurveyId, surveyTitle, surveySkill, selectedSurveyUsers, nextStep, setNextStep }: SurveyModalProps) => {
  const [visibleUserCount, setVisibleUserCount] = useState(10);
  const [, setSurveyUsers] = useState<IUser[]>([]);
  const [isSurveyRunning, setIsSurveyRunning] = useState<boolean>(false);
  // const [selectedSurveyId, setSelectedSurveyId] = useState<string>("");

  const removeUser = (userId: string) => {
    setSurveyUsers((prevUsers) => prevUsers.filter((user) => user.Id !== userId));
  };

  const handleSurveyCreation = async (): Promise<void> => {
    setIsSurveyRunning(true);
    const minimumLoadingTime = new Promise<void>((resolve) => setTimeout(resolve, 1000));

    let userIds: string[] = [];
    userIds = selectedSurveyUsers.map((user) => user.Id);

    try {
      const newSurveyId = await postSurvey(userIds, surveySkill, surveyTitle);

      try {
        const surveyResults = await getSurveyResults(newSurveyId);
        console.log(surveyResults);
        setCreatedSurveyId(surveyResults.Id);

        await minimumLoadingTime;
      } catch (error) {
        console.error("Failed to fetch survey:", error);
      }
    } catch (error) {
      console.error("Failed to create survey:", error);
    }

    setIsOpen(false);

    if (setNextStep && nextStep) {
      setNextStep(nextStep);
    }

    setIsSurveyRunning(false);
  };

  return (
    <Dialog
      hidden={!isOpen}
      onDismiss={handleSurveyCreation}
      dialogContentProps={{
        showCloseButton: true,
        title: t("SurveySummary"),
      }}
      modalProps={{
        isBlocking: true,
        className: "admin-dialog",
      }}
    >
      <div className="form--container mb-40">
        <HelpDialog className="mb-16" dialogContent={t("Help.SurveyUsers.Content")} title={t("Help.SurveyUsers.Title")}>
          <h4 className="text-style--Subtitle1 text-color--brand10">{t("UsersInSurvey")}</h4>
        </HelpDialog>
        <div className="admin-survey__steps--skills-msg-box mb-32">
          <div className="admin-survey__userslist">
            <p className="text-style--Body2 text-color--grey32 mb-4">{t("SurveyWillBeSentTo")}:</p>
            <p className="text-style--Title3 text-color--brand10 mb-12">
              {selectedSurveyUsers.length} {t("users")}
            </p>
            <div className="admin-survey__userslist-users mb-16">
              {selectedSurveyUsers.slice(0, visibleUserCount).map((user, index) => (
                <div key={user.Id} className="admin-survey__userslist-user">
                  <span className="text-style--Body2">{user.DisplayName}</span>
                  <button className="admin-survey__userslist-button" onClick={() => removeUser(user.Id)}>
                    <img src={IconDismiss} alt="Remove user" />
                  </button>
                </div>
              ))}
            </div>
            {visibleUserCount < selectedSurveyUsers.length && (
              <DeliwiButton
                className="button-style--showmore button-size--showmore"
                onClick={() => {
                  setVisibleUserCount((currentVisible) => currentVisible + 10);
                }}
              >
                {t("Show More")}
                <IconChevron />
              </DeliwiButton>
            )}
          </div>
        </div>
        <HelpDialog className="mb-16" dialogContent={t("Help.SurveyMessage.Content")} title={t("Help.SurveyMessage.Title")}>
          <h4 className="text-style--Subtitle1 text-color--brand10">{t("MessagePreview")}</h4>
        </HelpDialog>
        <p className="text-style--Body2 text-color--neutral-foreground-2 mb-16">
          {t("SkillUnderSurvey")}: <span className="text-style--Body1 fw-700 text-color--brand10">{surveySkill}</span>
        </p>
        <div className="admin-survey">
          <SurveyMessagePreview skillName={surveySkill} />
        </div>
      </div>
      <DialogFooter>
        <div className="edit-dialog--buttons">
          <DeliwiButton
            className="button-style--outline button-size--large"
            onClick={() => {
              setIsOpen(false);
              setVisibleUserCount(20);
            }}
          >
            <IconClose className="icon-size--20" />
            {t("Cancel")}
          </DeliwiButton>
          {isSurveyRunning ? (
            <div className="ml-auto">
              <div className="deliwi-spinner"></div>
            </div>
          ) : (
            <DeliwiButton type="submit" className="button-style--primary button-size--large" onClick={handleSurveyCreation} disabled={selectedSurveyUsers.length < 1}>
              <IconRunAnalysis className="icon-size--18" />
              {t("SendSurvey")}
            </DeliwiButton>
          )}
        </div>
      </DialogFooter>
    </Dialog>
  );
};
