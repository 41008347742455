import { TextField } from "@fluentui/react";
import { DeliwiButton } from "components/DeliwiButton";
// The icons are in use but the way IIconProps is setting them is causing this
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { IAIRewrittenFieldsOriginalValues, IUserProfile, IUserProfileEditForm } from "../../../interfaces/IUserProfile";
import { t } from "i18next";
import { useEffect, useState } from "react";
import "assets/styles/components/EditInterests.scss";
import { UserMyInterestsEditAddInterest } from "./UserMyInterestsEditAddInterest";
import { IconDismiss } from "../../Icons/IconDismiss";
import { IconCheckmark } from "components/Icons/IconCheckmark";
import { IconClose } from "../../Icons/IconClose";
import { rewriteTextWithAI } from "../../../services/apiService";
import { IconShape } from "../../Icons/IconShape";
import { IconUndo } from "../../Icons/IconUndo";
import { HelpDialog } from "../../HelpDialog";

export const UserMyInterestsEdit = (props: IUserProfileEditForm) => {
  const shadowUserProfile = props.userProfileData;
  const [aiRewrittenFields, setAiRewrittenFields] = useState<IAIRewrittenFieldsOriginalValues[]>([]);
  const [fieldUnderRewriting, setFieldUnderRewriting] = useState<string>("");
  const getItemFromRewrittenFields = (fieldName: string): IAIRewrittenFieldsOriginalValues | undefined => aiRewrittenFields?.find((item) => item.fieldName === fieldName);
  const [interests, setInterests] = useState<string[]>([] as string[]);
  const [interestsDescription, setInterestsDescription] = useState<string | undefined>(undefined);

  const saveNewValues = async () => {
    const interestsToSave = { Description: interestsDescription, Keywords: interests };

    const updatedProfile = {
      ...shadowUserProfile,
      Interests: interestsToSave,
    } as IUserProfile;

    try {
      await props.saveProfile(updatedProfile);
      props.userProfileSetter(updatedProfile);
      props.hidePopup();
    } catch (error) {
      console.error("Failed to save changes:", error);
    }
  };
  // console.log(shadowUserProfile);
  useEffect(() => {
    setInterests(shadowUserProfile.Interests?.Keywords || []);
    setInterestsDescription(shadowUserProfile.Interests?.Description || "");
  }, []);

  const handleRemove = (Name: string) => {
    const fSkills = interests.filter((i) => i !== Name);
    setInterests(fSkills);
  };

  const handleDescriptionChange = (newValue: string) => {
    // console.log(newValue);
    setInterestsDescription(newValue);
  };

  // console.log(interestsDescription);

  return (
    <>
      <div className="edit-dialog--header form--heading--wrapper">
        <h2 className="form--heading">{t("MyInterestsAndHobbies")}</h2>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-edit-popup" onClick={() => props.hidePopup()}></ChromeCloseIcon>
        </div>
      </div>
      <div className="form--container x-large mb-40">
        <div className="form--field--wrapper col-12 mb-32">
          {interestsDescription !== undefined && (
            <>
              <div className="field-with-ai-rewrite field-with-ai-rewrite--wrapper">
                <TextField label={t("ShortDescriptionOfInterestsEditLabel")} id="interests-description" name="interests-description" multiline rows={4} value={interestsDescription} onChange={(event, newValue) => newValue !== undefined && handleDescriptionChange(newValue)} />
                {fieldUnderRewriting === "InterestsDescription" && (
                  <div className="search-loader--wrapper">
                    <span className="deliwi-spinner mb-16"></span>
                  </div>
                )}
              </div>
              <div className="field-with-ai-rewrite--actions">
                {!getItemFromRewrittenFields("InterestsDescription") ? (
                  <HelpDialog className="" dialogContent={t("Help.EditForms.RewriteWithAI.Content")} title={t("Help.EditForms.RewriteWithAI.Title")}>
                    <DeliwiButton
                      className="button-style--candy-border button-size--medium bg-white"
                      disabled={!interestsDescription || interestsDescription.length < 5}
                      onClick={async () => {
                        setFieldUnderRewriting("InterestsDescription");
                        const response = await rewriteTextWithAI("InterestsDescription", interestsDescription);
                        if (!!response?.RewrittenText) {
                          setAiRewrittenFields([
                            ...aiRewrittenFields,
                            {
                              fieldName: "JobDescription",
                              value: interestsDescription,
                            },
                          ]);
                          setInterestsDescription(response.RewrittenText);
                        }
                        setFieldUnderRewriting("");
                      }}
                    >
                      <IconShape />
                      {t("RewriteWithAI")}
                    </DeliwiButton>
                  </HelpDialog>
                ) : (
                  <DeliwiButton
                    className="button-style--outline button-size--medium"
                    onClick={() => {
                      const originalValueItem = getItemFromRewrittenFields("InterestsDescription");
                      setInterestsDescription(originalValueItem?.value);
                      const updatedRewrittenFields = aiRewrittenFields.filter((fieldItem) => fieldItem.fieldName !== "InterestsDescription");
                      setAiRewrittenFields(updatedRewrittenFields);
                    }}
                  >
                    <IconUndo />
                    {t("Undo")}
                  </DeliwiButton>
                )}
              </div>
            </>
          )}
        </div>
        <UserMyInterestsEditAddInterest interests={interests} setInterests={setInterests} />
        {!!interests && (
          <div className="my-interests--list mt-16">
            {interests.map((interest, index) => {
              return (
                <div key={index} className="my-interests--list--item">
                  <span>{interest}</span>
                  <button className="remove-item" onClick={() => handleRemove(interest)}>
                    <IconDismiss className="icon-delete" />
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="edit-dialog--buttons">
        <DeliwiButton className="button-style--outline button-size--large" onClick={() => props.hidePopup()}>
          <IconClose className="icon-size--20" />
          {t("Cancel")}
        </DeliwiButton>
        <DeliwiButton type="submit" className="button-style--primary button-size--large" onClick={saveNewValues} disabled={props.saving}>
          <IconCheckmark />
          {t("SaveChanges")}
        </DeliwiButton>
      </div>
    </>
  );
};
