// StepIndicator.tsx
import { useEffect, useState } from "react";
import { t } from "i18next";

interface StepIndicatorProps {
  currentStep: number;
  totalSteps: number;
  stepTitles: string[];
}

export const StepIndicator = ({ currentStep, totalSteps, stepTitles }: StepIndicatorProps) => {
  const [fillWidth, setFillWidth] = useState<string>("0%");

  useEffect(() => {
    const newFillWidth = calculateFillWidth(currentStep, totalSteps);
    if (currentStep === 3) {
      setFillWidth("100%");
    } else {
      setFillWidth(newFillWidth);
    }
  }, [currentStep, totalSteps]);

  const calculateFillWidth = (currentStep: number, totalSteps: number): string => {
    return `${((currentStep - 0.5) / totalSteps) * 100}%`;
  };

  return (
    <>
      <div className="mb-48">
        <div className="admin-analyses__steps">
          {stepTitles.map((title, index) => {
            const isCompleted = index < currentStep;
            const isActive = index === currentStep - 1;
            return (
              <div key={title} className={`text-style--Subtitle2 text-style--bold step ${isActive ? "active" : ""} ${isCompleted ? "completed" : ""}`}>
                <span className="step-label">{t(title)}</span>
              </div>
            );
          })}
        </div>
        <div className="admin-analyses__bar">
          <div className="admin-analyses__bar--fill" style={{ width: fillWidth }}></div>
        </div>
      </div>
    </>
  );
};
