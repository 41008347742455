import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconUndo = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M16.9342 9.05095C16.4095 5.22073 12.8792 2.54105 9.04894 3.06573C7.10618 3.33185 5.46008 4.37049 4.37682 5.83062L4.25046 6.00095H7.49985C7.77599 6.00095 7.99985 6.2248 7.99985 6.50095C7.99985 6.77709 7.77599 7.00095 7.49985 7.00095H3.49985C3.22371 7.00095 2.99985 6.77709 2.99985 6.50095V2.50095C2.99985 2.2248 3.22371 2.00095 3.49985 2.00095C3.77599 2.00095 3.99985 2.2248 3.99985 2.50095V4.70826C5.22416 3.31945 6.93463 2.34601 8.91322 2.07498C13.2906 1.47535 17.3253 4.53784 17.9249 8.91524C18.5246 13.2926 15.4621 17.3273 11.0847 17.9269C6.70727 18.5266 2.67259 15.4641 2.07296 11.0867C1.99882 10.5454 1.98067 10.0089 2.01426 9.48306C2.03186 9.20748 2.26953 8.99835 2.54511 9.01595C2.82069 9.03355 3.02982 9.27122 3.01222 9.5468C2.98287 10.0064 2.99867 10.4761 3.06371 10.951C3.58839 14.7812 7.11873 17.4609 10.949 16.9362C14.7792 16.4115 17.4589 12.8812 16.9342 9.05095Z" fill="#242424" />
      </svg>
    </span>
  );
};
