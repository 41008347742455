import { useEffect, useState } from "react";
import { t } from "i18next";
import { getUserProfile } from "services/apiService";
import { IUserProfile } from "interfaces/IUserProfile";
import { UserProfileOverview } from "components/UserProfile/UserProfileOverview";
import { UserProfileDetails } from "components/UserProfile/UserProfileDetails";
import { MyProfileWelcome } from "components/MyProfileWelcome";

import "assets/styles/pages/MyProfile.scss";
import profileBackgroundImage from "assets/images/bg-profile-1.jpg";

export const MyProfile = () => {
  const [userProfile, setUserProfile] = useState<IUserProfile | undefined>();
  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!saving) {
      let showLoadingTimeout = setTimeout(() => setLoading(true), 1500); // Set a delay before showing loading

      (async () => {
        const userProfileData = await getUserProfile();
        setUserProfile(userProfileData);
        clearTimeout(showLoadingTimeout); // Cancel the timeout if the API call finishes early
        setLoading(false);
      })();

      return () => {
        clearTimeout(showLoadingTimeout);
      };
    }
  }, [saving]);

  if (loading) {
    return <div className="deliwi-spinner mt-24 pl-24"></div>;
  }

  return (
    <>
      {userProfile ? (
        <div className="user-profile-page">
          <MyProfileWelcome />
          <div className="user-profile-page--background--container">
            <div className="user-profile-page--background--image" style={{ backgroundImage: `url(${profileBackgroundImage})` }}></div>
          </div>
          <div className="user-profile-page--container">
            <div className="user-profile-page--overview">
              <h1 className="text-style--Large-title">{t("MyProfile")}</h1>
              <UserProfileOverview userProfile={userProfile} displayMode="my-profile" />
            </div>
            <div className="user-profile-page--details">
              <UserProfileDetails userProfile={userProfile} displayMode="my-profile" saving={saving} setSaving={setSaving} />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
