import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconPaperplane = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.8147 10.696L3.28344 11.9512C3.10705 11.9806 2.95979 12.1019 2.89723 12.2695L0.299335 19.2269C0.0506613 19.8663 0.720085 20.4763 1.33375 20.1695L19.3337 11.1695C19.8865 10.8931 19.8865 10.1043 19.3337 9.82787L1.33375 0.827869C0.720085 0.521037 0.0506613 1.13108 0.299335 1.77052L2.89723 8.72791C2.95979 8.89544 3.10705 9.01681 3.28344 9.04621L10.8147 10.3014C10.9236 10.3196 10.9972 10.4226 10.9791 10.5316C10.965 10.6159 10.899 10.6819 10.8147 10.696Z" fill="#C2C2C2" />
      </svg>
    </span>
  );
};
